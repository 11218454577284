import { useTheme } from '@emotion/react';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Link,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { ethers } from 'ethers';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import basescanLight from '../../../images/logos/basescan-light.png';
import { AccountsContext } from '../../shared/context/AccountsProvider';
import { matchesTraderId } from '../../shared/cryptoUtil';
import {
  displayDefaultTableCell,
  StyledHeaderTableCellWithLine,
  StyledTableCell,
} from '../../shared/orderTable/util';
import { insertEllipsis } from './insertEllipsis';
import { TraderIdAutocomplete } from './TraderIdAutocomplete';

const columns = [
  { id: 'transactionHash', label: 'Transaction ID', width: 260, align: 'left' },
  { id: 'traderId', label: 'Trader ID', width: 60, align: 'left' },
  { id: 'type', label: 'Type', width: 50, align: 'left' },
  { id: 'attester', label: 'Attester', width: 50, align: 'left' },
  { id: 'blockNumber', label: 'Block', width: 60, align: 'left' },
  { id: 'merkleRoot', label: 'Hash', width: 80, align: 'left' },
];

const abi = [
  {
    type: 'event',
    name: 'AttestedToData',
    inputs: [
      {
        name: 'traderId',
        type: 'bytes32',
        indexed: true,
        internalType: 'bytes32',
      },
      {
        name: 'epoch',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256',
      },
      {
        name: 'attester',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'record',
        type: 'tuple',
        indexed: false,
        internalType: 'struct Attestations_Types.DataRecord',
        components: [
          {
            name: 'merkleRoot',
            type: 'bytes32',
            internalType: 'bytes32',
          },
          {
            name: 'cid',
            type: 'string',
            internalType: 'string',
          },
        ],
      },
    ],
  },
];

async function fetchAttestedToDataEvents(config, setHasError, setErrorContent) {
  const { rpcUrl, contractAddress, numberOfBlocks, retry, paginationNumber } =
    config;

  try {
    const provider = new ethers.JsonRpcProvider(rpcUrl);
    const currentBlock = await provider.getBlockNumber();
    const fromBlock =
      currentBlock - paginationNumber * numberOfBlocks - numberOfBlocks;

    const contract = new ethers.Contract(contractAddress, abi, provider);
    const events = [];

    const batchSize = 5000;
    // const endBlock = Math.min(fromBlock + batchSize, currentBlock) - (paginationNumber * numberOfBlocks);

    for (
      let batch = fromBlock;
      batch < currentBlock - paginationNumber * numberOfBlocks;
      batch += batchSize
    ) {
      const endBlock = Math.min(batch + batchSize, currentBlock);
      let retryCount = 0;
      while (retryCount < retry) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const batchEvents = await contract.queryFilter(
            'AttestedToData',
            batch,
            endBlock
          );

          batchEvents.forEach((event) => {
            events.push({
              transactionHash: event.transactionHash,
              blockNumber: event.blockNumber,
              traderId: event.args.traderId,
              epoch: event.args.epoch,
              attester: event.args.attester,
              merkleRoot: event.args.record.merkleRoot,
              cid: event.args.record.cid,
              type: 'Trade',
            });
          });

          break;
        } catch (error) {
          retryCount += 1;
          setHasError(true);
          setErrorContent(
            `Attempt ${retryCount} failed for batch ${batch}-${endBlock}:`,
            error.message
          );

          if (retryCount === retry) {
            setHasError(true);
            setErrorContent(
              `Failed to fetch batch ${batch}-${endBlock} after ${retry} attempts: ${error.message}`
            );
          }

          Promise((resolve) => setTimeout(resolve, 2000));
        }
      }
    }

    return events;
  } catch (error) {
    setHasError(true);
    setErrorContent('Error fetching AttestedToData events:', error);
  }
  return [];
}

export default function ExplorerProofsTable({
  setHasError,
  setErrorContent,
  pageView,
}) {
  const [proofs, setProofs] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [paginationTracker, setPaginationTracker] = useState(0);
  const [traderIdFilter, setTraderIdFilter] = useState('');

  const navigate = useNavigate();
  const theme = useTheme();
  const { accounts } = useContext(AccountsContext);

  const rowsPerPage = pageView ? 10 : 25;

  const getBaseScan = async (paginationNumber = 0) => {
    setLoading(true);
    try {
      const lastNBlocks = 50000;
      const config = {
        contractAddress: '0xC31c7663873d36bC63bA28df4D40D0102F73D1B5',
        numberOfBlocks: lastNBlocks,
        rpcUrl: 'https://mainnet.base.org',
        retry: 3,
        paginationNumber,
      };

      const events = await fetchAttestedToDataEvents(
        config,
        setHasError,
        setErrorContent
      );
      setLoading(false);
      return events;
    } catch (error) {
      setHasError(true);
      setErrorContent('Error fetching BaseScan events:', error);
    }
    setLoading(false);
    return [];
  };

  const handleChangePage = (event, newPage) => {
    if (newPage * rowsPerPage >= proofs.length) {
      getBaseScan(paginationTracker + 1).then((result) => {
        setProofs((prev) => [...result, ...prev]);
      });
      setPaginationTracker((prev) => prev + 1);
    }

    setPage(newPage);
  };

  const handleTraderIdFilterChange = (event) => {
    setTraderIdFilter(event.target.value);
  };

  useEffect(() => {
    getBaseScan().then((result) => {
      setProofs((prev) => [...result, ...prev]);
    });

    const transactioninterval = setInterval(() => {
      getBaseScan(paginationTracker).then((result) => {
        setProofs(result);
        setPage(0);
      });
    }, 600000);

    return () => {
      clearInterval(transactioninterval);
    };
  }, []);

  return (
    <Card sx={{ height: '95%', display: 'flex', flexDirection: 'column' }}>
      <Stack
        alignItems='center'
        direction='row'
        spacing={2}
        style={{ paddingLeft: '20px', paddingTop: '20px', flexShrink: 0 }}
      >
        <img alt='Base Scan' src={basescanLight} style={{ height: '30px' }} />
        <Typography variant='h4'>Oracle</Typography>
      </Stack>
      <CardContent
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden', // Prevent content overflow
        }}
      >
        <Box sx={{ mb: 2, flexShrink: 0 }}>
          <TraderIdAutocomplete
            accounts={accounts}
            value={traderIdFilter}
            onChange={(event, newValue) => {
              setTraderIdFilter(newValue || '');
            }}
            onInputChange={(event, newInputValue) => {
              setTraderIdFilter(newInputValue);
            }}
          />
        </Box>

        {loading ? (
          <Box
            alignItems='center'
            display='flex'
            flex={1}
            justifyContent='center'
          >
            <ScaleLoader color={theme.palette.text.offWhite} />
          </Box>
        ) : (
          <TableContainer sx={{ flex: 1, overflow: 'auto' }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <StyledHeaderTableCellWithLine
                      align={column.align}
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        width: column.width || undefined,
                      }}
                    >
                      {column.label}
                    </StyledHeaderTableCellWithLine>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ overflow: 'auto' }}>
                {proofs
                  .filter(
                    (proof) =>
                      !traderIdFilter ||
                      matchesTraderId(traderIdFilter, proof.traderId)
                  )
                  .sort((a, b) => b.blockNumber - a.blockNumber)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((tx) => {
                    return (
                      <TableRow
                        hover
                        key={tx.transactionHash}
                        sx={{
                          height: '55.38px',
                        }}
                      >
                        {columns.map((column) => {
                          const value = tx[column.id];
                          switch (column.id) {
                            case 'traderId':
                              return displayDefaultTableCell(
                                column,
                                insertEllipsis(value),
                                {},
                                StyledTableCell
                              );
                            case 'merkleRoot':
                              return displayDefaultTableCell(
                                column,
                                insertEllipsis(value, 8, 6),
                                {},
                                StyledTableCell
                              );
                            case 'attester':
                              return displayDefaultTableCell(
                                column,
                                insertEllipsis(value),
                                {},
                                StyledTableCell
                              );
                            case 'transactionHash':
                              return (
                                <StyledTableCell
                                  align={column.align}
                                  key={column.id}
                                >
                                  <Link
                                    href={`https://basescan.org/tx/${value}`}
                                  >
                                    {insertEllipsis(value, 8, 6)}
                                  </Link>
                                </StyledTableCell>
                              );
                            default:
                              return displayDefaultTableCell(
                                column,
                                value,
                                {},
                                StyledTableCell
                              );
                          }
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Box sx={{ flexShrink: 0, mt: 2 }}>
          {' '}
          {/* flexShrink: 0 prevents shrinking */}
          {pageView ? (
            <Button
              sx={{
                width: '100%',
                height: '50px',
                border: 0,
                color: theme.palette.text.offWhite,
                borderColor: theme.palette.text.offWhite,
              }}
              variant='outlined'
              onClick={() => navigate('/explorer/proofs')}
            >
              View all proofs
            </Button>
          ) : (
            <TablePagination
              component='div'
              count={-1}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10]}
              sx={{ height: '60px' }}
              onPageChange={handleChangePage}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
