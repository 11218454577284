import React from 'react';
import { useAtom } from 'jotai';
import { InputAdornment } from '@mui/material';
import AlgoNumberField from './AlgoNumberField';

function MaxClipSizeField({ maxClipSize, setMaxClipSize, ...props }) {
  return (
    <AlgoNumberField
      InputProps={{
        step: 'any',
        startAdornment: <InputAdornment position='start'> $ </InputAdornment>,
      }}
      label='Max Clip Size'
      size='small'
      value={maxClipSize}
      onChange={(e) => setMaxClipSize(e.target.value)}
      {...props}
    />
  );
}

export default MaxClipSizeField;
