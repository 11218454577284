import { useTheme } from '@emotion/react';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { fetchPlacements } from '@/apiServices';
import { Loader } from '@/shared/Loader';
import { StyledTableCell } from '../orderTable/util';
import { smartRound } from '../../util';

function PlacementsTable({
  orderId,
  showAlert,
  isSimpleOrderView = false,
  pageSize = 10,
}) {
  const theme = useTheme();

  const [paginatedPlacements, setPaginatedPlacements] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const headerStyles = {
    backgroundColor: theme.palette.background.paper,
    padding: '10px',
    border: `1px solid ${theme.palette.divider}`,
  };

  const cellStyles = {
    padding: '10px',
    border: `1px solid ${theme.palette.divider}`,
  };

  const loadPlacements = async () => {
    let response;
    setIsLoading(true);
    try {
      response = await fetchPlacements({
        orderId,
        pageSize,
        pageNumber: pageNumber + 1,
      });
    } catch (e) {
      showAlert({
        severity: 'error',
        message: `Failed to fetch order details: ${e.message}`,
      });
      return;
    } finally {
      setIsLoading(false);
    }

    setPaginatedPlacements(response);
    setPageNumber(response.page_number - 1);
  };

  useEffect(() => {
    loadPlacements();
  }, [pageNumber]);

  const placementStatusColor = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'success.main';
      case 'FILLED':
        return 'success.main';
      case 'FAILED':
        return 'error.main';
      case 'MISSING':
        return 'error.main';
      case 'CANCELLED':
        return 'primary.main';
      default:
        return 'text.offWhite';
    }
  };

  const placementTypeColor = (type) => {
    switch (type) {
      case 'MAKE':
        return 'success.main';
      case 'TAKE':
        return 'error.main';
      default:
        return 'text.offWhite';
    }
  };

  const renderHeaderRow = () => {
    if (isSimpleOrderView) {
      return (
        <TableRow>
          <StyledTableCell style={headerStyles}>Time</StyledTableCell>
          <StyledTableCell style={headerStyles}>Exchange</StyledTableCell>
          <StyledTableCell style={headerStyles}>External ID</StyledTableCell>
          <StyledTableCell style={headerStyles}>Quantity</StyledTableCell>
          <StyledTableCell style={headerStyles}>Price</StyledTableCell>
          <StyledTableCell style={headerStyles}>Status</StyledTableCell>
        </TableRow>
      );
    }

    return (
      <TableRow>
        <StyledTableCell style={headerStyles}>ID</StyledTableCell>
        <StyledTableCell style={headerStyles}>Time</StyledTableCell>
        <StyledTableCell style={headerStyles}>Type</StyledTableCell>
        <StyledTableCell style={headerStyles}>Status</StyledTableCell>
        <StyledTableCell style={headerStyles}>Qty</StyledTableCell>
        <StyledTableCell style={headerStyles}>Ex Qty</StyledTableCell>
        <StyledTableCell style={headerStyles}>Price</StyledTableCell>
        <StyledTableCell style={headerStyles}>Base Qty</StyledTableCell>
        <StyledTableCell style={headerStyles}>Base Ex Qty</StyledTableCell>
        <StyledTableCell style={headerStyles}>Passiveness</StyledTableCell>
      </TableRow>
    );
  };

  const renderDetailRow = (p) => {
    return (
      <TableRow key={p.id}>
        <StyledTableCell style={cellStyles}>{p.id}</StyledTableCell>
        <StyledTableCell style={cellStyles}>
          {new Date(p.created_at).toLocaleString('en-US', {
            timeZone: 'America/New_York',
          })}
        </StyledTableCell>
        <StyledTableCell style={cellStyles}>
          <Typography color={placementTypeColor(p.placement_type)}>
            {p.placement_type}
          </Typography>
        </StyledTableCell>
        <StyledTableCell style={cellStyles}>
          <Typography color={placementStatusColor(p.status)}>
            {p.status}
          </Typography>
        </StyledTableCell>
        <StyledTableCell style={cellStyles}>
          {smartRound(Number(p.qty))}
        </StyledTableCell>
        <StyledTableCell style={cellStyles}>
          {smartRound(Number(p.executed_qty))}
        </StyledTableCell>
        <StyledTableCell style={cellStyles}>
          {smartRound(Number(p.price))}
        </StyledTableCell>
        <StyledTableCell style={cellStyles}>
          {smartRound(Number(p.base_qty))}
        </StyledTableCell>
        <StyledTableCell style={cellStyles}>
          {smartRound(Number(p.executed_base_qty))}
        </StyledTableCell>
        <StyledTableCell style={cellStyles}>{p.passiveness}</StyledTableCell>
      </TableRow>
    );
  };

  const renderPlacementRow = (p) => {
    if (!isSimpleOrderView) {
      return renderDetailRow(p);
    }

    return (
      <TableRow key={p.id}>
        <StyledTableCell style={cellStyles}>
          {new Date(p.created_at).toLocaleString('en-US', {
            timeZone: 'America/New_York',
          })}
        </StyledTableCell>
        <StyledTableCell style={cellStyles}>{p.exchange}</StyledTableCell>
        <StyledTableCell style={cellStyles}>
          {p.external_id || 'N/A'}
        </StyledTableCell>
        <StyledTableCell style={cellStyles}>
          {smartRound(Number(p.qty))}
        </StyledTableCell>
        <StyledTableCell style={cellStyles}>
          {smartRound(Number(p.price))}
        </StyledTableCell>
        <StyledTableCell style={cellStyles}>
          <Typography color={placementStatusColor(p.status)}>
            {p.status}
          </Typography>
        </StyledTableCell>
      </TableRow>
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  const { placements, count, page_size } = paginatedPlacements;

  return (
    <Stack direction='column' spacing={2} sx={{ margin: '16px' }}>
      <Typography color='text.offWhite' variant='h4'>
        Placements
      </Typography>
      <Table stickyHeader>
        <TableHead>{renderHeaderRow()}</TableHead>
        <TableBody>{placements.map((p) => renderPlacementRow(p))}</TableBody>
      </Table>
      {!isSimpleOrderView && (
        <TablePagination
          component='div'
          count={count}
          page={pageNumber}
          rowsPerPage={page_size}
          rowsPerPageOptions={[]}
          onPageChange={(e, newPage) => setPageNumber(newPage)}
        />
      )}
    </Stack>
  );
}

export default PlacementsTable;
