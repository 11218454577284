import { useTheme } from '@emotion/react';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { useState } from 'react';

export default function AccountDropdown({
  accounts,
  selectedAccounts,
  handleSelectedAccountsChange,
  handleSelectedAccountsDelete,
  multiple = false,
  extraStyling = {},
  setCommonExchangeName,
}) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleChange = (event) => {
    setOpen(false);
    handleSelectedAccountsChange(event);
  };

  const chipSxProps = (value) => {
    let color = theme.palette.exchangeColors.OKXTransparent;

    const chipExchangeName = accounts ? accounts[value].exchangeName : null;
    if (Object.keys(theme.palette.exchangeColors).includes(chipExchangeName)) {
      color = theme.palette.exchangeColors[`${chipExchangeName}Transparent`];
    }

    return {
      fontSize: '0.65rem',
      fontFamily: 'IBM PLEX MONO',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      backgroundColor: color,
      color: theme.palette.text.offBlack,
    };
  };

  const createChip = (value) => {
    return (
      <Chip
        clickable={false}
        key={value}
        label={value}
        sx={{ ...chipSxProps(value) }}
        onClick={(event) => {
          setOpen(true);
        }}
        onDelete={() => {
          handleSelectedAccountsDelete(
            selectedAccounts.filter((e) => e !== value, setCommonExchangeName)
          );
        }}
        onMouseDown={(event) => {
          event.stopPropagation();
        }}
      />
    );
  };

  return (
    <FormControl fullWidth sx={{ fontSize: '0.75rem' }}>
      <InputLabel id='accounts-label'>Accounts</InputLabel>
      <Select
        id='accounts'
        input={
          <OutlinedInput
            label='Accounts'
            sx={{
              '& .MuiInputBase-input': {
                padding: '12px 8px',
              },
            }}
          />
        }
        labelId='accounts-label'
        multiple={multiple}
        open={open}
        renderValue={(selected) => {
          return (
            <Box
              sx={{
                display: 'flex',
                gap: 0.5,
                flexWrap: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {multiple
                ? selected.map((value) => createChip(value))
                : createChip(selected)}
            </Box>
          );
        }}
        sx={{
          ...extraStyling,
        }}
        value={selectedAccounts}
        onChange={handleChange}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        {Object.values(accounts).length > 0 &&
          Object.values(accounts).map((acc) => (
            <MenuItem key={acc.id} value={acc.name}>
              {acc.displayName}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
