export function insertEllipsis(str, startSlice = 4, endSlice = 3) {
  if (!str) {
    return '';
  }

  if (str.length <= 7) {
    return str; // No need to shorten
  }

  const start = str.slice(0, startSlice);
  const end = str.slice(-1 * endSlice);

  return `${start}...${end}`;
}
