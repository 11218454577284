import React from 'react';
import AlgoNumberField from './AlgoNumberField';

function LeverageField({ updatePairLeverage, setUpdatePairLeverage }) {
  return (
    <AlgoNumberField
      InputProps={{ step: 'any' }}
      label='Update Pair Leverage'
      size='small'
      value={updatePairLeverage}
      onChange={(e) => setUpdatePairLeverage(e.target.value)}
    />
  );
}

export default LeverageField;
