import React from 'react';
import Slider from '@mui/material/Slider';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { Stack, styled } from '@mui/material';

const newTabLinkProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

const infoIconStyle = {
  color: 'rgba(255,255,255,0.6)',
};

const boxInfoHoverStyles = {
  '&:hover': {
    '.icon-hover': {
      color: 'rgba(255, 255, 255, 0.8)',
    },
    '.text-hover': {
      textDecoration: 'underline',
    },
  },
};

const percentageMarks = [
  {
    value: 0,
  },
  {
    value: 25,
  },
  {
    value: 50,
  },
  {
    value: 75,
  },
  {
    value: 100,
  },
];

const DisabledPercentageStyledSlider = styled(Slider)({
  color: '#FFF',
  width: '95%',
  paddingTop: 0,
  marginLeft: '3px',
  marginTop: '-16px',
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-mark': {
    height: 6,
    width: 6,
    borderRadius: '50%',
    backgroundColor: '#000',
    border: '2px solid rgba(86, 86, 86, 0.5)',
  },
  '& .MuiSlider-thumb': {
    height: 10,
    width: 10,
    backgroundColor: '#000',
    border: '2px solid #FFF',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&.Mui-disabled': {
      border: '2px solid rgba(86, 86, 86, 0.5) !important',
    },
    '&::before': {
      display: 'none',
    },
  },
});

const PercentageStyledSlider = styled(Slider)({
  color: '#FFF',
  width: '95%',
  paddingTop: 0,
  marginLeft: '3px',
  marginTop: '-16px',
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-mark': {
    height: 6,
    width: 6,
    borderRadius: '50%',
    backgroundColor: '#000',
    border: '2px solid #FFF',
  },
  '& .MuiSlider-thumb': {
    height: 10,
    width: 10,
    backgroundColor: '#000',
    border: '2px solid #FFF',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
});

const strategyParamHeaderStyle = {
  width: '6rem',
  whitespace: 'nowrap',
};

function PassivenessSlider({ passiveness, setPassiveness, sx }) {
  return (
    <Stack
      alignItems='center'
      direction='row'
      justifyContent='center'
      spacing={1}
      sx={sx}
    >
      <Box>
        <Typography
          id='input-passiveness'
          sx={strategyParamHeaderStyle}
          variant='body1'
        >
          Passiveness
        </Typography>
      </Box>
      <Box alignItems='center' display='flex' gap={1} sx={boxInfoHoverStyles}>
        <Link
          href='https://tread-labs.gitbook.io/api-docs/submitting-orders/passiveness'
          {...newTabLinkProps}
        >
          <HelpOutline
            className='icon-hover'
            fontSize='small'
            sx={infoIconStyle}
          />
        </Link>
      </Box>

      <Slider
        marks
        aria-label='Passiveness'
        max={1}
        min={0.0}
        size='medium'
        step={0.02}
        value={passiveness}
        valueLabelDisplay='auto'
        onChange={(e) => setPassiveness(e.target.value)}
      />
    </Stack>
  );
}

function DiscretionSlider({ discretion, setDiscretion, sx }) {
  return (
    <Stack
      alignItems='center'
      direction='row'
      justifyContent='center'
      spacing={1}
      sx={sx}
    >
      <Box>
        <Typography
          id='input-discretion'
          sx={strategyParamHeaderStyle}
          variant='body1'
        >
          Discretion
        </Typography>
      </Box>

      <Box
        alignItems='center'
        display='flex'
        gap={1}
        justifyContent='center'
        sx={boxInfoHoverStyles}
      >
        <Link
          href='https://tread-labs.gitbook.io/api-docs/submitting-orders/discretion'
          {...newTabLinkProps}
        >
          <HelpOutline
            className='icon-hover'
            fontSize='small'
            sx={infoIconStyle}
          />
        </Link>
      </Box>

      <Slider
        marks
        aria-label='Discretion'
        max={0.5}
        min={0.02}
        size='medium'
        step={0.01}
        value={discretion}
        valueLabelDisplay='auto'
        onChange={(e) => {
          return setDiscretion(e.target.value);
        }}
      />
    </Stack>
  );
}
function ExposureToleranceSlider({
  exposureTolerance,
  setExposureTolerance,
  sx,
}) {
  return (
    <Stack
      alignItems='center'
      direction='row'
      justifyContent='center'
      spacing={1}
      sx={sx}
    >
      <Box>
        <Typography
          id='input-exposure'
          sx={strategyParamHeaderStyle}
          variant='body1'
        >
          Exposure Tolerance
        </Typography>
      </Box>
      <Box alignItems='center' display='flex' gap={1} sx={boxInfoHoverStyles}>
        <Link
          href='https://tread-labs.gitbook.io/api-docs/multi-spread-orders/exposure-tolerance'
          {...newTabLinkProps}
        >
          <HelpOutline
            className='icon-hover'
            fontSize='small'
            sx={infoIconStyle}
          />
        </Link>
      </Box>

      <Slider
        marks
        aria-label='Exposure Tolerance'
        max={1.0}
        min={0.01}
        size='medium'
        step={0.01}
        value={exposureTolerance}
        valueLabelDisplay='auto'
        onChange={(e) => {
          return setExposureTolerance(e.target.value);
        }}
      />
    </Stack>
  );
}

function PercentageSlider({
  percentage,
  setPercentage,
  disabled,
  ariaLabel,
  onChangeCommitted,
}) {
  const RenderSlider = disabled
    ? DisabledPercentageStyledSlider
    : PercentageStyledSlider;
  return (
    <RenderSlider
      aria-label={ariaLabel}
      disabled={disabled}
      marks={percentageMarks}
      max={100}
      min={0}
      size='small'
      step={1}
      style={{}}
      value={percentage}
      valueLabelDisplay='auto'
      valueLabelFormat={(value) => {
        return `${value}%`;
      }}
      onChange={(e) => {
        return setPercentage(e.target.value);
      }}
      onChangeCommitted={(e, newValue) => onChangeCommitted(e, newValue)}
    />
  );
}

function AlphaTiltSlider({ alphaTilt, setAlphaTilt, sx }) {
  return (
    <Stack
      alignItems='center'
      direction='row'
      justifyContent='center'
      spacing={1}
      sx={sx}
    >
      <Box>
        <Typography
          id='input-exposure'
          sx={strategyParamHeaderStyle}
          variant='body1'
        >
          Alpha Tilt
        </Typography>
      </Box>
      <Box alignItems='center' display='flex' gap={1} sx={boxInfoHoverStyles}>
        <Link
          href='https://tread-labs.gitbook.io/api-docs/strategy-configuration/alpha-tilt'
          {...newTabLinkProps}
        >
          <HelpOutline
            className='icon-hover'
            fontSize='small'
            sx={infoIconStyle}
          />
        </Link>
      </Box>
      <Slider
        marks
        aria-label='Passiveness'
        max={1}
        min={-1}
        size='medium'
        step={0.1}
        value={alphaTilt}
        valueLabelDisplay='auto'
        onChange={(e) => setAlphaTilt(e.target.value)}
      />
    </Stack>
  );
}

function MaxOtcPercentageSlider({ maxOtcPercentage, setMaxOtcPercentage, sx }) {
  return (
    <Stack
      alignItems='center'
      direction='row'
      justifyContent='center'
      spacing={1}
      sx={sx}
    >
      <Box>
        <Typography
          id='input-exposure'
          sx={strategyParamHeaderStyle}
          variant='body1'
        >
          Max OTC Percentage
        </Typography>
      </Box>
      <Box alignItems='center' display='flex' gap={1} sx={boxInfoHoverStyles}>
        <Link
          href='https://tread-labs.gitbook.io/api-docs/strategy-configuration/max-otc-percentage'
          {...newTabLinkProps}
        >
          <HelpOutline
            className='icon-hover'
            fontSize='small'
            sx={infoIconStyle}
          />
        </Link>
      </Box>
      <Slider
        marks
        aria-label='Max OTC Percentage'
        max={100}
        min={0}
        size='medium'
        step={10}
        value={maxOtcPercentage}
        valueLabelDisplay='auto'
        onChange={(e) => setMaxOtcPercentage(e.target.value)}
      />
    </Stack>
  );
}

export {
  PassivenessSlider,
  DiscretionSlider,
  ExposureToleranceSlider,
  AlphaTiltSlider,
  PercentageSlider,
  MaxOtcPercentageSlider,
};
