import { useTheme } from '@emotion/react'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import { Link, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import React from 'react'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { BASEURL, smartRound, calculateDurationDisplay } from '../../util'
import ProgressBar from '../fields/ProgressBar/ProgressBar'
import { StrategyParamsParsed, StyledIBMTypography, StyledSummaryTableCell } from '../orderTable/util'
import { handleHelpClick } from './OrderActionUtils'
import { FillExchangeChart, FillRoleChart } from './charts'

function OrderSummary({ OrderSummaryData, showAlert, analytics, isSimple = false}) {
  const theme = useTheme()

  if (
    OrderSummaryData === undefined ||
    Object.keys(OrderSummaryData).length === 0
  ) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight="100%">
        <ScaleLoader color="#FFFFFF" />
      </Box>
    )
  }

  const {
    id,
    account_names,
    pair,
    side,
    parent_order = undefined,
    chained_orders = undefined,
    buy_token_amount,
    buy_token,
    sell_token,
    sell_token_amount,
    limit_price,
    executed_token,
    base_asset,
    executed_notional,
    pct_filled,
    executed_price,
    time_start,
    duration,
    strategy_params,
    trajectory_name,
    super_strategy_name,
    notes,
    order_condition_normal,
    order_variable_normal,
    order_condition_vars,
    executed_buy_qty,
    executed_qty,
    schedule_discretion,
    engine_passiveness,
    alpha_tilt,
    exposure_tolerance,
    pov_limit,
    pov_target,
    status,
    initial_order_params,
    market_type,
    max_otc,
    pos_side,
    resume_condition_normal,

    target_executed_qty,
    is_target_buy
  } = OrderSummaryData


  const formatInitialValue = (val) => {
    if (!val) {
      return '';
    }

    return `(${smartRound(Number(val))}) `;
  }

  const displayToken = is_target_buy ? buy_token : sell_token;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledSummaryTableCell align='left' colSpan={2} style={{ position: 'relative' }}>
            <Link
              color={theme.palette.primary.main}
              href={`${BASEURL}/ems/order/${id}`}
              variant="h6"
            >
              {id}
            </Link>
            <ContactSupportIcon
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                color: 'darkgrey',
                transition: 'color 0.3s ease',
              }}
              onClick={() => handleHelpClick(id, time_start, duration, account_names, status, pct_filled, showAlert)}
              onMouseOut={(e) => {
                e.currentTarget.style.color = 'darkgrey'
              } }
              onMouseOver={(e) => {
                e.currentTarget.style.color = 'white'
              } } />
          </StyledSummaryTableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{ overflow: 'auto' }}>
        <TableRow
          style={{
            display: 'table-row',
            verticalAlign: 'inherit',
            unicodeBidi: 'isolate',
            borderColor: 'inherit'
          }}
        >
          <StyledSummaryTableCell><Typography>Accounts</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Typography>
              {account_names &&
                account_names.map((names, index) => {
                  if (index === account_names.length - 1) {
                    return `${names}`
                  }
                  return `${names} | `
                })}
            </Typography>
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Pair | Side</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Typography>{pair} | {side}</Typography>
          </StyledSummaryTableCell>
        </TableRow>
        {parent_order !== undefined && parent_order !== null ? (
          <TableRow style={{ padding: '10px 10px 10px 10px' }}>
            <StyledSummaryTableCell><Typography>Parent Order</Typography></StyledSummaryTableCell>
            <StyledSummaryTableCell>
              <Link color={theme.palette.primary.main} href={`${BASEURL}/multi_order/${parent_order}`} variant='body1'>
                {parent_order}
              </Link>
            </StyledSummaryTableCell>
          </TableRow>
        ) : null}
        {chained_orders !== undefined && chained_orders !== null ? (
          <TableRow style={{ padding: '10px 10px 10px 10px' }}>
            <StyledSummaryTableCell><Typography>Chained Order</Typography></StyledSummaryTableCell>
            <StyledSummaryTableCell>
              <Link
                color={theme.palette.primary.main}
                href={`${BASEURL}/chained_order/${chained_orders}`}
                variant='body1'
              >
                {chained_orders}
              </Link>
            </StyledSummaryTableCell>
          </TableRow>
        ) : null}
        {super_strategy_name !== undefined && super_strategy_name !== null ? (
          <TableRow>
            <StyledSummaryTableCell><Typography>Strategy</Typography></StyledSummaryTableCell>
            <StyledSummaryTableCell>
              <Typography>{super_strategy_name}</Typography>
            </StyledSummaryTableCell>
          </TableRow>
        ) : null}
        <TableRow>
          <StyledSummaryTableCell><Typography>Trajectory</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Typography>{trajectory_name}</Typography>
          </StyledSummaryTableCell>
        </TableRow>
        {limit_price && limit_price.length > 0 &&
          <TableRow>
            <StyledSummaryTableCell><Typography>Limit Price</Typography></StyledSummaryTableCell>
            <StyledSummaryTableCell>
              <StyledIBMTypography>
                {formatInitialValue(initial_order_params.limit_price)
                }{parseFloat(limit_price) <= 0 ? 'N/A' : limit_price}
              </StyledIBMTypography>
            </StyledSummaryTableCell>
          </TableRow>}
        <TableRow>
          <StyledSummaryTableCell><Typography>Target Quantity</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            {buy_token_amount ? (
              <div>
                <StyledIBMTypography>
                  {formatInitialValue(initial_order_params.buy_token_amount)
                  }{smartRound(buy_token_amount)} {market_type !== 'option' ? displayToken : 'Contracts'}
                </StyledIBMTypography>
              </div>
            ) : (
              <div>
                <StyledIBMTypography>
                  {formatInitialValue(initial_order_params.sell_token_amount)
                  }{smartRound(sell_token_amount)} {market_type !== 'option' ? displayToken : 'Contracts'}
                </StyledIBMTypography>
              </div>
            )}
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Executed Quantity</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <StyledIBMTypography>{smartRound(Number(target_executed_qty))
            } {market_type !== 'option' ? displayToken : 'Contracts'}</StyledIBMTypography>
          </StyledSummaryTableCell>
        </TableRow>
        {market_type !== 'option' && <TableRow>
          <StyledSummaryTableCell><Typography>Executed Notional</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <StyledIBMTypography>${smartRound(Number(executed_notional))}</StyledIBMTypography>
          </StyledSummaryTableCell>
        </TableRow>}
        <TableRow>
          <StyledSummaryTableCell><Typography>Status</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Typography>{status}</Typography>
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Fill Percentage</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Box alignItems="left"
              display="flex"
              justifyContent="left"
              minHeight="100%">
              <ProgressBar
                fullWidth
                isPov={pov_limit || pov_target}
                orderStatus={status}
                progress={Math.round(Number(pct_filled))} />
            </Box>
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Avg Exec Price</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <StyledIBMTypography>${smartRound(Number(executed_price))}</StyledIBMTypography>
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Time Start</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell><StyledIBMTypography>{time_start}</StyledIBMTypography></StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Duration</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell><StyledIBMTypography>
            {initial_order_params.duration &&
             `(${calculateDurationDisplay(initial_order_params.duration)})`}
            {calculateDurationDisplay(duration)}
          </StyledIBMTypography></StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Strategy Configurations</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <StrategyParamsParsed
              useIBM
              alpha_tilt={alpha_tilt}
              engine_passiveness={engine_passiveness}
              exposure_tolerance={exposure_tolerance}
              initial_pov_target={formatInitialValue(Number(initial_order_params.pov_target) * 100)}
              pov_limit={pov_limit * 100}
              pov_target={pov_target * 100}
              schedule_discretion={schedule_discretion}
              strategy_params={strategy_params} />
          </StyledSummaryTableCell>
        </TableRow>
        {notes && (
          <TableRow>
            <StyledSummaryTableCell><Typography>Notes</Typography></StyledSummaryTableCell>
            <StyledSummaryTableCell><Typography>{notes}</Typography></StyledSummaryTableCell>
          </TableRow>
        )}
        {order_condition_normal && (
          <TableRow>
            <StyledSummaryTableCell><Typography>Order Condition</Typography></StyledSummaryTableCell>
            <StyledSummaryTableCell>
              <Typography>{order_condition_normal}</Typography>
              { Object.entries(order_variable_normal).map(([k, v]) => {
                return (
                  <li key={`clause ${k}`}>{k}: {v} = {order_condition_vars[k][1]} </li>
                );
              })}
            </StyledSummaryTableCell>
          </TableRow>
        )}
        {resume_condition_normal && (
          <TableRow>
            <StyledSummaryTableCell><Typography>Resume Condition</Typography></StyledSummaryTableCell>
            <StyledSummaryTableCell><Typography>{resume_condition_normal}</Typography></StyledSummaryTableCell>
          </TableRow>
        )}
        {max_otc && max_otc !== 0 ? (
          <TableRow>
            <StyledSummaryTableCell><Typography>Max OTC Percentage</Typography></StyledSummaryTableCell>
            <StyledSummaryTableCell>
              <Typography>
                {(Number(max_otc) * 100).toFixed(0)}%
              </Typography>
            </StyledSummaryTableCell>
          </TableRow>
        ) : null}
        {pos_side && (
          <TableRow>
            <StyledSummaryTableCell><Typography>Position Side</Typography></StyledSummaryTableCell>
            <StyledSummaryTableCell>
              <Typography>{pos_side}</Typography>
            </StyledSummaryTableCell>
          </TableRow>
        )}
        {!isSimple &&
    <TableRow>
      <StyledSummaryTableCell><Typography>Fill Type</Typography></StyledSummaryTableCell>

      <StyledSummaryTableCell style={{height: '40px', width: '60%', overflow: 'visible',
        position: 'relative'}}>
        <div style={{ width: '100%' }}>
          <FillRoleChart data={analytics} />
        </div>
      </StyledSummaryTableCell>
    </TableRow>
        }

        {!isSimple &&
    <TableRow>
      <StyledSummaryTableCell><Typography>Exchange(s)</Typography></StyledSummaryTableCell>

      <StyledSummaryTableCell style={{height: '40px', width: '60%', overflow: 'visible',
        position: 'relative'}}>
        <div style={{ width: '100%' }}>

          <FillExchangeChart data={analytics} />
        </div>
      </StyledSummaryTableCell>
    </TableRow>
        }

      </TableBody>
    </Table>


  )
}

export { OrderSummary }
