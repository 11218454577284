import { useTheme } from '@emotion/react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useAtom } from 'jotai';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  getAccountExchangeSettings,
  getContractInfo,
  getOrderTemplates
} from '../../../apiServices';
import { Loader } from '../../../shared/Loader';
import PreTradeAnalyticsComponent from '../../../shared/PreTradeAnalyticsComponent';
import { ErrorContext } from '../../../shared/context/ErrorProvider';
import AccountDropdown from '../../../shared/fields/AccountDropdown';
import LimitPriceField from '../../../shared/fields/LimitPriceField';
import StrategyDropdown from '../../../shared/fields/StrategyDropdown';
import { AccountBalanceProvider } from './AccountBalanceContext';
import AlgoOrderFields from './AlgoOrderFields';
import { OrderConfirmationModal } from './OrderConfirmationModal';
import { QtyInputField } from './QtyInputField';
import SimpleOrderFields from './SimpleOrderFields';
import { useBaseForm } from './hooks/useBaseForm';
import { useScrollableSticky } from './hooks/useScrollableSticky';
import { useSubmitForm } from './hooks/useSubmitForm';
import { DashboardAccordianComponent } from './util';
import { BuySellButtons } from './BuySellButtons';
import { PositionSideButtons } from './PositionSideButtons';

const SIMPLE_STRATEGY_WITH_LIMIT_PRICE = ["Limit", "Iceberg", "IOC"];

function OrderEntryForm({ FormAtoms, isAuthenticated}) {
  const [selectedStrategy, setSelectedStrategy] = useAtom(FormAtoms.selectedStrategyAtom);
  const [trajectory, setTrajectory] = useAtom(FormAtoms.trajectoryAtom);
  const [selectedStrategyParams, setSelectedStrategyParams] = useAtom(FormAtoms.selectedStrategyParamsAtom);
  const [selectedDuration, setSelectedDuration] = useAtom(FormAtoms.selectedDurationAtom);
  const [updatePairLeverage, setUpdatePairLeverage] = useAtom(FormAtoms.updatePairLeverageAtom);
  const [limitPrice, setLimitPrice] = useAtom(FormAtoms.limitPriceAtom);
  const [stopPrice, setStopPrice] = useAtom(FormAtoms.stopPriceAtom);
  const [loading, setLoading] = useAtom(FormAtoms.loadingAtom);
  const [passiveness, setPassiveness] = useAtom(FormAtoms.passivenessAtom);
  const [discretion, setDiscretion] = useAtom(FormAtoms.discretionAtom);
  const [alphaTilt, setAlphaTilt] = useAtom(FormAtoms.alphaTiltAtom);
  const [notes, setNotes] = useAtom(FormAtoms.notesAtom);
  const [orderCondition, setOrderCondition] = useAtom(FormAtoms.orderConditionAtom);
  const [isOrderConditionValidated, setIsOrderConditionValidated] = useAtom(FormAtoms.isOrderConditionValidatedAtom);
  const [preTradeEstimationData] = useAtom(FormAtoms.preTradeEstimationDataAtom);
  const [preTradeDataLoading] = useAtom(FormAtoms.preTradeDataLoadingAtom);
  const [preTradeDataError] = useAtom(FormAtoms.preTradeDataErrorAtom);
  const [povTarget, setPovTarget] = useAtom(FormAtoms.povTargetAtom);
  const [povLimit, setPovLimit] = useAtom(FormAtoms.povLimitAtom);
  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useAtom(FormAtoms.isAdvancedSettingsOpenAtom);
  const [
    selectedLimitPriceQuickSetting
  ] = useAtom(FormAtoms.selectedLimitPriceQuickSettingAtom);
  const [orderTemplates, setOrderTemplates] = useAtom(FormAtoms.orderTemplatesAtom);
  const [orderTemplateAction, setOrderTemplateAction] = useAtom(FormAtoms.orderTemplateActionAtom);
  const [isTemplateOpen, setIsTemplateOpen] = useAtom(FormAtoms.isTemplateOpenAtom);
  const [initialLoadValue] = useAtom(FormAtoms.initialLoadValueAtom);
  const [targetTime, setTargetTime] = useAtom(FormAtoms.targetTimeAtom);
  const [exchangeSettingsByAccount, setExchangeSettingsByAccount] = useState(null);
  const [posSide, setPosSide] = useAtom(FormAtoms.posSideAtom);
  const [contractInfoByAccountId, setContractInfoByAccountId] = useState({});
  const [orderSlices, setOrderSlices] = useAtom(FormAtoms.orderSlicesAtom);

  const {
    accounts,
    exchanges,
    strategies,
    trajectories,
    strategyParams,
    tokenPairs,
  } = initialLoadValue;

  const isDataLoaded = Object.keys(initialLoadValue).length > 0;

  const { setHasError, setErrorContent } = useContext(ErrorContext);

  const theme = useTheme()
  const cardRef = useRef(null);
  const scrollableRef = useRef(null);
  const stickyRef = useRef(null);

  const sliderProps = {
    passiveness, discretion, alphaTilt,
    setPassiveness, setDiscretion, setAlphaTilt
  }

  const showAlert = ({ severity, message }) => {
    setErrorContent({ severity, message });
    setHasError(true);
  }

  const {
    maxHeight
  } = useScrollableSticky(isDataLoaded, isAdvancedSettingsOpen, cardRef, scrollableRef, stickyRef)


  const {
    handleCoreFields, quoteBaseStates, handleBaseQuoteFields, percentageSliderInfo, fetchPairPrice
  } = useBaseForm({
    povTarget,
    showAlert,
    FormAtoms,
  })

  const { handleSelectedAccountsChange, handleSelectedSide, handleSelectedPair } = handleCoreFields

  const { baseQty, quoteQty, baseQtyPlaceholder, quoteQtyPlaceholder, baseContractQty,
    basePercentage, quotePercentage, convertedQtyLoading,
    selectedAccounts, selectedPair, selectedSide, relevantExchangePairs, convertedQty,
    // Temporary adding setSelectedAccounts, setSelectedPair, setSelectedSide for deadline puroposes
    // Def need to use React Redux now
    setBasePercentage, setQuotePercentage, setSelectedAccounts, setSelectedSide
  } = quoteBaseStates

  const {
    handleBaseQtyOnChange, handleQuoteQtyOnChange,
    onBasePercentageChangeCommit, onQuotePercentageChangeCommit,
    fetchTradePrediction
  } = handleBaseQuoteFields

  const isBuySide = selectedSide === 'buy';

  const {
    totalQuoteAsset,
    totalBaseAsset
  } = percentageSliderInfo

  const {
    ConfirmationModalProps,
    submitCheck,
    isSubmitted,
    OrderTemplateModal,
  } = useSubmitForm({
    setHasError,
    showAlert,
    FormAtoms,
  })

  const selectedAccountExchangeNames = selectedAccounts.length > 0 ?
    selectedAccounts.map(acc => accounts[acc].exchangeName) : [];

  useEffect(() => {
    if (!initialLoadValue) {
      return;
    }
    const trajectoriesList = Object.values(initialLoadValue.trajectories);
    const strategiesList = Object.values(initialLoadValue.strategies);
    const vwapTrajectory = trajectoriesList.find(element => element.name === 'VWAP')
    const defaultStrategy = strategiesList.find(element => element.name.includes('Impact Minimization'))

    setTrajectory(vwapTrajectory.id)
    setSelectedStrategy(defaultStrategy ? defaultStrategy.id : strategiesList[0].id )
  }, [initialLoadValue]);

  const posModeEnabledExchanges = ["OKX", "Bybit"]
  useEffect(() => {
    const loadAccountExchangeSettings = async (accountIds) => {
      try {
        const exchangeSettings = await getAccountExchangeSettings(accountIds);
        setExchangeSettingsByAccount(exchangeSettings);
      } catch (e) {
        showAlert({ severity: 'error', message: `Could not load account exchange settings: ${e.message}` });
      }
    }

    const loadContractInfo = async (pair, accountIds) => {
      try {
        const result = await getContractInfo(pair, accountIds);
        setContractInfoByAccountId(result);
      } catch (e) {
        showAlert({ severity: 'error', message: `Could not load contract info: ${e.message}` });
      }
    }

    const selectedExchanges = selectedAccounts.map(acc => accounts[acc].exchangeName);
    const isPosModeEnabledExchange = selectedExchanges.length > 0 &&
      selectedExchanges.every(exchange => posModeEnabledExchanges.includes(exchange));

    const isPosModeEnabledMarketType = selectedPair && selectedPair.market_type !== 'spot';

    if (isPosModeEnabledExchange && isPosModeEnabledMarketType) {
      const accountIds = selectedAccounts.map(acc => accounts[acc].id)
      loadAccountExchangeSettings(accountIds);
      loadContractInfo(selectedPair.id, accountIds)
    }

  }, [selectedAccounts, selectedPair])

  const { AdvancedSettingsRender, RenderStrategyContainer, applyPresets } =  AlgoOrderFields({
    baseAssetQty: baseQty || convertedQty,
    exchanges,
    fetchTradePrediction,
    isBuySide,
    isOrderConditionValidated,
    isPovLoading: preTradeDataLoading,
    limitPrice,
    notes,
    orderCondition,
    povLimit,
    povTarget,
    preTradeDataLoading,
    selectedAccountExchangeNames,
    selectedDuration,
    selectedPairName: selectedPair ? selectedPair.id : null,
    selectedStrategy,
    selectedStrategyParams,
    setIsOrderConditionValidated,
    setLimitPrice,
    setNotes,
    setOrderCondition,
    setPovLimit,
    setPovTarget,
    setSelectedDuration,
    setSelectedStrategyParams,
    setTrajectory,
    setUpdatePairLeverage,
    showAlert,
    sliderProps,
    strategies,
    strategyParams,
    trajectory,
    trajectories,
    tokenPairs,
    updatePairLeverage,
    targetTime,
    setTargetTime,
    FormAtoms
  })

  const isReadyToPickQty = selectedAccounts.length > 0 && (selectedPair && Object.keys(selectedPair).length > 0);

  const isReadyToSubmit = selectedAccounts.length > 0 && selectedPair &&
    (!!baseQty || !!quoteQty) && (!orderCondition || isOrderConditionValidated);

  const isAlgoStrategy = (strategies && selectedStrategy && strategies[selectedStrategy] ) &&
    (!!strategies[selectedStrategy].schedule || strategies[selectedStrategy].is_super_strategy);

  const pairLevelPosModeExchanges = ["Bybit"];

  const isHedgeMode = exchangeSettingsByAccount && selectedAccounts.some(accName => {
    const account = accounts[accName];
    const exchangeSettings = exchangeSettingsByAccount[account.id];

    let posMode = null;
    if (pairLevelPosModeExchanges.includes(account.exchangeName)) {
      const contractInfo = contractInfoByAccountId && contractInfoByAccountId[account.id];
      posMode = contractInfo && contractInfo.pos_mode;
    } else {
      posMode = exchangeSettings && exchangeSettings.pos_mode;
    }
    return posMode === 'long_short_mode';
  });

  const isFutureOrPerp = selectedPair && (selectedPair.market_type === 'perp' || selectedPair.market_type === 'future');

  useEffect(() => {
    if (isHedgeMode) {
      setPosSide('long');
    } else {
      setPosSide(null);
    }
  }, [isHedgeMode])

  if (loading) {
    return <Loader/>
  }

  const renderBuySellButtons = () => {
    if (!isHedgeMode || !isFutureOrPerp) {
      return <BuySellButtons
        handleSelectedSide={handleSelectedSide}
        isBuySide={isBuySide}
        isHedgeMode={isHedgeMode}
        selectedPair={selectedPair}
        selectedSide={selectedSide}
      />
    }

    return (
      <Stack direction="column" gap={0} height='100%'>
        <BuySellButtons
          isCompact
          handleSelectedSide={handleSelectedSide}
          isBuySide={isBuySide}
          isHedgeMode={isHedgeMode}
          selectedPair={selectedPair}
          selectedSide={selectedSide}
        />
        <PositionSideButtons
          isCompact
          posSide={posSide}
          setPosSide={setPosSide} />
      </Stack>
    )
  }

  const onFormSubmit = async (e) => {
    fetchPairPrice(); // refresh pair price before submitting
    submitCheck(e, relevantExchangePairs);
  }

  return (
    <Box ref={cardRef} sx={{
      height: '100%',
      filter: isAuthenticated ? 'none' : 'blur(2px)',
      pointerEvents: isAuthenticated ? 'auto' : 'none',
      opacity: isAuthenticated ? 1 : 0.8,
    }}>
      <form style={{ height: '100%', position: 'relative'}} onSubmit={onFormSubmit}>
        <div style={{height: '100%', position: 'relative' }}>
          <Grid container
            ref={scrollableRef}
            spacing={2}
            sx={{
              maxHeight,
              overflow: 'auto',
              marginBottom: '1rem',
              scrollbarGutter: 'stable',
              marginRight: '-1rem',
            }}>
            <Grid xs={6}>
              <AccountDropdown
                multiple
                accounts={accounts}
                extraStyling={{ height: '50.25px' }}
                handleSelectedAccountsChange={(e) => handleSelectedAccountsChange(e.target.value)}
                handleSelectedAccountsDelete={(value) => handleSelectedAccountsChange(value)}
                selectedAccounts={selectedAccounts}
              />
            </Grid>

            <Grid xs={6}>
              <Box height='50.25px'>
                {renderBuySellButtons()}
              </Box>
            </Grid>

            <AccountBalanceProvider showAlert={showAlert}>
              <Grid xs={6}>
                <QtyInputField
                  isBase
                  contractQty={baseContractQty}
                  convertedQtyLoading={convertedQtyLoading}
                  handleQtyOnChange={handleBaseQtyOnChange}
                  isBuySide={isBuySide}
                  isReadyToPickQty={isReadyToPickQty}
                  oppositeQtyExists={!!quoteQty}
                  percentage={basePercentage}
                  qty={baseQty}
                  qtyPlaceholder={baseQtyPlaceholder}
                  selectedPair={selectedPair}
                  setPercentage={setBasePercentage}
                  totalBaseAsset={totalBaseAsset}
                  totalQuoteAsset={totalQuoteAsset}
                  onPercentageChangeCommit={onBasePercentageChangeCommit}
                />
              </Grid>
              <Grid xs={6}>
                <QtyInputField
                  convertedQtyLoading={convertedQtyLoading}
                  handleQtyOnChange={handleQuoteQtyOnChange}
                  isBase={false}
                  isBuySide={isBuySide}
                  isReadyToPickQty={isReadyToPickQty}
                  oppositeQtyExists={!!baseQty}
                  percentage={quotePercentage}
                  qty={quoteQty}
                  qtyPlaceholder={quoteQtyPlaceholder}
                  selectedPair={selectedPair}
                  setPercentage={setQuotePercentage}
                  totalBaseAsset={totalBaseAsset}
                  totalQuoteAsset={totalQuoteAsset}
                  onPercentageChangeCommit={onQuotePercentageChangeCommit}
                />
              </Grid>
            </AccountBalanceProvider>
            <Grid xs={12}>
              <StrategyDropdown
                includeSimple
                applyPresets={applyPresets}
                setTrajectory={setTrajectory}
                setValue={setSelectedStrategy}
                strategies={strategies}
                trajectories={trajectories}
                value={selectedStrategy}
              />
            </Grid>
            { (isAlgoStrategy || trajectories && trajectories[selectedStrategy] && SIMPLE_STRATEGY_WITH_LIMIT_PRICE.includes(trajectories[selectedStrategy].name)) && (
              <Grid item="true" xs={12}>
                <LimitPriceField
                  exchanges={exchanges}
                  FormAtoms={FormAtoms}
                  isBuySide={isBuySide}
                  limitPrice={limitPrice}
                  selectedAccountExchangeNames={selectedAccountExchangeNames}
                  selectedPairName={selectedPair ? selectedPair.id : null}
                  setLimitPrice={setLimitPrice}
                  showAlert={showAlert}
                  simple={trajectories[selectedStrategy]?.name === "IOC"}
                  tokenPairs={tokenPairs}
                />
              </Grid>
            )}
            {!isAlgoStrategy && (
              <Grid item="true" xs={12}>
                <SimpleOrderFields
                  orderSlices={orderSlices}
                  selectedDuration={selectedDuration}
                  setOrderSlices={setOrderSlices}
                  setSelectedDuration={setSelectedDuration}
                  setStopPrice={setStopPrice}
                  setUpdatePairLeverage={setUpdatePairLeverage}
                  showSlices={trajectories && trajectories[selectedStrategy]?.name === "Iceberg"}
                  stopPrice={stopPrice}
                  updatePairLeverage={updatePairLeverage}
                />
              </Grid>
            )}
            <Grid xs={12}>
              {RenderStrategyContainer}
            </Grid>
            {isAlgoStrategy && (
              <Grid item="true" xs={12}>
                <DashboardAccordianComponent
                  isAlgo={isAlgoStrategy}
                  isOpen={isAdvancedSettingsOpen}
                  setIsOpen={setIsAdvancedSettingsOpen}
                  title='Advanced Settings'
                >
                  {AdvancedSettingsRender}
                </DashboardAccordianComponent>
              </Grid>
            )}
          </Grid>
          {/* IMPORTANT CODE, IF REMOVED APP WILL MALFUNCTION */}
          <div style={{height: '100%', position: 'relative'}}/>
          <Stack
            minHeight='160px' paddingY='8px'
            ref={stickyRef}
            spacing={2}
            sx={{
              position: 'sticky', bottom: 0, zIndex: 2,
              backgroundColor: theme.components.MuiCard.styleOverrides.root.backgroundColor
            }}
          >
            <Divider />
            <PreTradeAnalyticsComponent
              data={preTradeEstimationData}
              dataError={preTradeDataError}
              loading={preTradeDataLoading}
            />
            <Divider />
            <Stack direction='row' spacing={2}>
              <Button
                fullWidth
                color='secondary'
                variant="contained"
                onClick={async () =>{
                  const data = await getOrderTemplates()
                    .catch(e => {
                      showAlert({ severity: 'error', message: `Unable to load templates: ${e.message}` });

                    });
                  setOrderTemplates(data);
                  setOrderTemplateAction('manage')
                  setIsTemplateOpen(true)
                }}>
              Load Templates
              </Button>
              <Button
                fullWidth
                color='secondary'
                variant="contained"
                onClick={() =>{
                  setOrderTemplateAction('save')
                  setIsTemplateOpen(true)
                }}
              >
              Save Templates
              </Button>
            </Stack>
            {!isSubmitted ?
              <Button
                fullWidth
                color={isBuySide ? 'success' : 'error'}
                disabled={!isReadyToSubmit}
                size="large"
                type="submit"
                variant="contained"
              >
              Submit {isBuySide ? 'Buy' : 'Sell'} Order
              </Button> :
              <Button
                disabled
                fullWidth
                size="large"
                variant="contained"
              >
                <CircularProgress size={20} />
              </Button>
            }
          </Stack>
        </div>
      </form>
      <OrderConfirmationModal
        {...ConfirmationModalProps(isBuySide)}
        FormAtoms={FormAtoms}
      />
      <OrderTemplateModal
        handleBaseChange={(value) => handleBaseQtyOnChange(value)}
        handleQuoteChange={(value) => handleQuoteQtyOnChange(value)}
        open={isTemplateOpen}
        orderTemplates={orderTemplates}
        selectedLimitPriceQuickSetting={selectedLimitPriceQuickSetting}
        setAlphaTilt={setAlphaTilt}
        setDiscretion={setDiscretion}
        setLimitPrice={setLimitPrice}
        setLoading={setLoading}
        setNotes={setNotes}
        setOpen={setIsTemplateOpen}
        setOrderCondition={setOrderCondition}
        setOrderTemplates={setOrderTemplates}
        setPassiveness={setPassiveness}
        setPovLimit={setPovLimit}
        setPovTarget={setPovTarget}
        setSelectedAccounts={setSelectedAccounts}
        setSelectedDuration={setSelectedDuration}
        setSelectedPair={handleSelectedPair}
        setSelectedSide={setSelectedSide}
        setSelectedStrategy={setSelectedStrategy}
        setSelectedStrategyParams={setSelectedStrategyParams}
        setStopPrice={setStopPrice}
        setTargetTime={setTargetTime}
        setTrajectory={setTrajectory}
        setUpdatePairLeverage={setUpdatePairLeverage}
        type={orderTemplateAction}
      />
    </Box>
  )
}

export default OrderEntryForm;
