import React, { useState } from 'react';
import {
  Button,
  Stack,
  Typography,
  Card,
  CardContent,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Link from '@mui/material/Link';
import { Loader } from '@/shared/Loader';
import DateRangePicker, { DateRange } from '@/pages/points/DateRangePicker';
import useGetPointsData from '@/pages/points/hooks/useGetPointsData';
import { smartRound, numberWithCommas } from '@/util';
import DataComponent from '@/shared/DataComponent';
import PointsTable from './PointsTable';
import PointsChart from './PointsChart';
import LOGOS from '../../../images/logos';

function NoPointsActivity() {
  return (
    <Stack
      alignItems='center'
      direction='column'
      justifyContent='center'
      spacing={2}
      sx={{ height: '100%' }}
    >
      <img
        alt='Tread Logo'
        src={LOGOS.treadRoundedSvg}
        style={{ height: '64px' }}
      />
      <Typography variant='h6'>
        You don’t have any points rewards right now
      </Typography>
      <Button href='/' size='large' variant='contained'>
        Go to trade
      </Button>
    </Stack>
  );
}

function NoPointsChart() {
  return (
    <Stack
      alignItems='center'
      direction='column'
      justifyContent='center'
      spacing={2}
      sx={{ height: '100%' }}
    >
      <img
        alt='Tread Logo'
        src={LOGOS.treadRoundedSvg}
        style={{ height: '64px' }}
      />
      <Typography variant='h6'>No points</Typography>
    </Stack>
  );
}

function PointsPageComponent() {
  const [dateRange, setDateRange] = useState(DateRange.MONTH);
  const [activityPage, setActivityPage] = useState(0);
  const { pointsData, pointsDataLoading } = useGetPointsData(
    dateRange,
    activityPage
  );

  const {
    total_points = 0,
    total_volume = 0,
    points_chart_data = [],
    points_activity = [],
    points_activity_count = 0,
  } = pointsData;

  return (
    <Grid container spacing={2} style={{ height: '100%' }}>
      <Grid style={{ minHeight: '25%' }} xs={4}>
        <Card style={{ height: '100%' }}>
          <CardContent>
            <DataComponent
              isLoading={pointsDataLoading}
              loadingComponent={<Loader />}
            >
              <Stack direction='column' spacing={5}>
                <Stack direction='column' spacing={1}>
                  <Typography variant='detailsSecondary'>
                    Total Points
                  </Typography>
                  <Typography variant='detailsPrimary'>
                    {numberWithCommas(smartRound(total_points, 2))}
                  </Typography>
                </Stack>
                <Stack direction='column' spacing={1}>
                  <Typography variant='detailsSecondary'>
                    Total Volume
                  </Typography>
                  <Typography variant='detailsPrimary'>
                    ${numberWithCommas(smartRound(total_volume, 2))}
                  </Typography>
                </Stack>
              </Stack>
            </DataComponent>
          </CardContent>
        </Card>
      </Grid>
      <Grid style={{ minHeight: '25%' }} xs={8}>
        <Card style={{ height: '100%' }}>
          <CardContent>
            <Stack direction='column' spacing={2} sx={{ height: '100%' }}>
              <Stack direction='row' justifyContent='space-between'>
                <Typography variant='h5'>Points Chart</Typography>
                <DateRangePicker
                  dateRange={dateRange}
                  onSelectDateRange={setDateRange}
                />
              </Stack>
              <DataComponent
                emptyComponent={<NoPointsChart />}
                isEmpty={points_chart_data.length === 0}
                isLoading={pointsDataLoading}
                loadingComponent={<Loader />}
              >
                <Box style={{ height: '100%' }}>
                  <PointsChart
                    dateRange={dateRange}
                    pointsData={points_chart_data}
                  />
                </Box>
              </DataComponent>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid style={{ height: '75%' }} xs={12}>
        <Card style={{ height: 'calc(100% - 80px)' }}>
          <CardContent>
            <Stack direction='column' spacing={2} sx={{ height: '95%' }}>
              <Typography variant='h5'>All Point Activity</Typography>
              <DataComponent
                emptyComponent={<NoPointsActivity />}
                isEmpty={points_activity.length === 0}
                isLoading={pointsDataLoading}
                loadingComponent={<Loader />}
              >
                <Box style={{ height: 'calc(100% - 40px)' }}>
                  <PointsTable
                    activityPage={activityPage}
                    pointsActivity={points_activity}
                    pointsActivityCount={points_activity_count}
                    onPageChange={setActivityPage}
                  />
                </Box>
              </DataComponent>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

function PointsPage() {
  return (
    <Stack
      spacing={2}
      sx={{
        height: '100%',
        width: '70%',
        margin: '0 auto', // centers the grid horizontally
      }}
    >
      <Box>
        <Typography sx={{ fontFamily: 'Jost' }} variant='pageHeader'>
          Points
        </Typography>
        <Typography variant='h6'>
          Accumulate points by trading on tread.fi.{' '}
          <Link
            href='https://foul-wavelength-9af.notion.site/Tread-fi-Points-Program-13f60eedf44c80f98812fdb7a3fbb5c0'
            underline='none'
          >
            Learn more
          </Link>
        </Typography>
      </Box>
      <PointsPageComponent />
    </Stack>
  );
}

export default PointsPage;
