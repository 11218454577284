import React from 'react';
import { useTheme } from '@emotion/react';
import { Stack, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import { StyledTableCell } from '../../../shared/orderTable/util';
import { smartRound } from '../../../util';

function FillOrderTable({
  fills
}) {
  const theme = useTheme();

  const FILL_ROLE_STYLES = {
    "MAKE": {color: theme.palette.charts.green},
    "TAKE": {color: theme.palette.charts.red},
  }

  const headerStyles = {
    backgroundColor: theme.palette.background.paper,
    padding: '10px',
    border: `1px solid ${theme.palette.divider}`,
  };

  const cellStyles = {
    padding: '10px',
    border: `1px solid ${theme.palette.divider}`,
  };

  return (
    <Stack direction='column' spacing={2} sx={{padding: '16px'}}>
      <Typography color='text.offWhite' variant='h4'>
        Fills
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell style={headerStyles}>Time</StyledTableCell>
            <StyledTableCell style={headerStyles}>External ID</StyledTableCell>
            <StyledTableCell style={headerStyles}>Quantity</StyledTableCell>
            <StyledTableCell style={headerStyles}>Price</StyledTableCell>
            <StyledTableCell style={headerStyles}>Role</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fills.map((fill, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={index}>
              <StyledTableCell style={cellStyles}>{fill.time}</StyledTableCell>
              <StyledTableCell style={cellStyles}>{fill.external_id}</StyledTableCell>
              <StyledTableCell style={cellStyles}>{smartRound(Number(fill.qty))}</StyledTableCell>
              <StyledTableCell style={cellStyles}>{smartRound(Number(fill.price))}</StyledTableCell>
              <StyledTableCell style={{...cellStyles, ...FILL_ROLE_STYLES[fill.role]}}>{fill.role}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
}

export { FillOrderTable };
