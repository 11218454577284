import { Button, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export function LinkMenu({ menuButtonText, menuButtonIcon, menuItems }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const renderMenuButton = () => {
    if (menuButtonText) {
      return (
        <Button color='inherit' onClick={handleClick}>
          <Typography>{menuButtonText}</Typography>
        </Button>
      );
    }

    if (menuButtonIcon) {
      return (
        <Button color='inherit' onClick={handleClick}>
          {menuButtonIcon}
        </Button>
      );
    }

    return null;
  };
  return (
    <div>
      {renderMenuButton()}
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems.map((item) => (
          <MenuItem
            component={Link}
            disabled={item.disabled}
            key={item.label}
            to={item.link}
            onClick={handleClose}
          >
            <Typography>{item.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
