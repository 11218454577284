import { useTheme } from '@emotion/react';
import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { ThinLoader } from '../../Loader';

function FillRoleChart({ data }) {
  const theme = useTheme();

  const { breakdown_fills_role_data } = data || {};

  const FILL_ROLE_COLORS = {
    MAKE: theme.palette.charts.greenTransparent,
    TAKE: theme.palette.charts.redTransparent,
    CROSS: theme.palette.charts.orangeTransparent,
    OTC: theme.palette.charts.OTCTransparent,
  };

  const crossExists =
    breakdown_fills_role_data && breakdown_fills_role_data.CROSS !== undefined;
  const otcExists =
    breakdown_fills_role_data && breakdown_fills_role_data.OTC !== undefined;

  const labels = ['Fill Type']; // Generic label for the stack

  // Avoid re-creating the dataset properties unless necessary
  const createDataset = (role, datap) => ({
    label: role,
    data: [datap ? datap[0] : 0],
    backgroundColor: FILL_ROLE_COLORS[role],
  });

  // Use useMemo to avoid recomputing datasets unnecessarily
  const datasets = useMemo(() => {
    if (breakdown_fills_role_data !== undefined) {
      return ['MAKE', 'TAKE', 'CROSS', 'OTC']
        .filter(
          (role) =>
            (breakdown_fills_role_data &&
              breakdown_fills_role_data[role] !== undefined) ||
            (role === 'CROSS' && crossExists) ||
            (role === 'OTC' && otcExists)
        )
        .map((role) => createDataset(role, breakdown_fills_role_data[role]));
    }
    return [];
  }, [breakdown_fills_role_data, crossExists, otcExists]);

  if (
    breakdown_fills_role_data === undefined ||
    Object.keys(breakdown_fills_role_data).length === 0
  ) {
    return <ThinLoader />;
  }

  const fill_role_data = {
    labels,
    datasets,
  };

  const options = {
    indexAxis: 'y',
    scales: {
      x: {
        stacked: true,
        ticks: {
          display: false, // Hide x-axis labels
        },
      },
      y: {
        stacked: true,
        ticks: {
          display: false, // Hide y-axis labels
        },
      },
    },
    layout: {
      padding: {
        right: 12,
        left: -10,
        bottom: -10,
      },
    },
    plugins: {
      legend: {
        display: false, // Optionally hide the legend if desired
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title() {
            return ''; // Remove the title/header from the tooltip
          },
          label(tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw.toFixed(2)}%`; // Customize the tooltip label
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  return <Bar data={fill_role_data} height='32px' options={options} />;
}

export { FillRoleChart };
