import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
  Star,
  StarBorder,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Popper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import debounce from 'lodash.debounce';
import { matchSorter } from 'match-sorter';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import { useTheme } from '@emotion/react';
import {
  addUserFavouritePairs,
  deleteUserFavouritePairs,
} from '../../../apiServices';
import { numberWithSpaces, smartRound } from '../../../util';
import { useUserMetadata } from '../../../shared/context/UserMetadataProvider';
import { ExchangeIcons } from '../../../shared/iconUtil';

const pairColumnWidth = '45%';
const favouriteColumnWidth = '5%';
const exchangeColumnWidth = '15%';
const balanceColumnWidth = '35%';

const Cell = styled(Box)(() => ({
  fontSize: '0.85rem',
  padding: '4px 12px 4px 12px',
  justifySelf: 'center',
  alignSelf: 'center',
}));

function Row({ index, style, data }) {
  const {
    pairs,
    favourites,
    balances,
    calculateAssetBalance,
    setSelectedPair,
    setAnchorEl,
    setSearchQuery,
    setConfirmedSearchQuery,
    toggleFavorite,
    handlePairSelect,
    balanceEnabled,
  } = data;
  const pair = pairs[index];

  const onClick = () => {
    if (handlePairSelect) {
      handlePairSelect(pair);
    } else {
      setSelectedPair(pair);
    }
    setSearchQuery('');
    setConfirmedSearchQuery('');
    setAnchorEl(null);
  };

  const renderBalance = () => {
    if (Object.keys(balances).length === 0) {
      return '-';
    }

    const baseAssetBalance = calculateAssetBalance(
      pair.is_contract ? pair.id : pair.base,
      pair.exchanges
    );

    return (
      <Stack direction='row' gap={1}>
        <Typography fontFamily={['monospace', 'IBM Plex Mono']} variant='body1'>
          {baseAssetBalance < 0.0001 && baseAssetBalance > 0
            ? `~0.0001 ${pair.base}`
            : numberWithSpaces(smartRound(baseAssetBalance))}
        </Typography>
        <Typography
          color='grey.light'
          fontFamily={['monospace', 'IBM Plex Mono']}
          variant='body1'
        >
          {pair.base}
        </Typography>
      </Stack>
    );
  };

  const favouriteOnClick = (event) => {
    event.stopPropagation();
    toggleFavorite(pair.id);
  };

  const isFavourited = favourites[pair.id];

  return (
    <Stack
      direction='row'
      style={{ ...style, width: '100%' }}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'grey.dark',
        },
        p: 0,
      }}
      onClick={onClick}
    >
      <Cell width={favouriteColumnWidth}>
        <IconButton
          sx={{ color: isFavourited ? 'primary.main' : 'grey.main' }}
          onClick={favouriteOnClick}
        >
          {isFavourited ? (
            <Star sx={{ fontSize: '1rem' }} />
          ) : (
            <StarBorder sx={{ fontSize: '1rem' }} />
          )}
        </IconButton>
      </Cell>
      <Cell width={pairColumnWidth}>
        <Typography fontFamily={['monospace', 'IBM Plex Mono']} variant='body1'>
          {pair.id}
        </Typography>
      </Cell>
      <Cell width={balanceColumnWidth}>
        {balanceEnabled && renderBalance()}
      </Cell>
      <Cell width={exchangeColumnWidth}>
        <ExchangeIcons exchanges={pair.exchanges} pairId={pair.id} />
      </Cell>
    </Stack>
  );
}

function PairSelector({
  accounts,
  balances,
  favourites,
  multiOrder = false,
  ChainedOrders = false,
  pairs,
  selectedAccounts,
  selectedPairName,
  setFavourites,
  setSelectedPair,
  showAlert,
  handlePairSelect = undefined,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [confirmedSearchQuery, setConfirmedSearchQuery] = useState('');
  const [selectedMarketType, setSelectedMarketType] = useState('perp');
  const popperRef = useRef(null);
  const pairDisplayRef = useRef(null);
  const theme = useTheme();

  const { user } = useUserMetadata();

  const saveToBackend = (pair, isFavorited) => {
    // Replace with your actual backend call
    if (isFavorited) {
      try {
        addUserFavouritePairs([pair]);
      } catch (error) {
        showAlert({
          severity: 'error',
          message: `Failed to add pair to favorites: ${error.message}`,
        });
        return false;
      }
    } else {
      try {
        deleteUserFavouritePairs([pair]);
      } catch (error) {
        showAlert({
          severity: 'error',
          message: `Failed to remove pair from favorites: ${error.message}`,
        });
        return false;
      }
    }

    return true;
  };

  const toggleFavorite = (pair) => {
    setFavourites((prevFavorites) => {
      const newFavorites = { ...prevFavorites, [pair]: !prevFavorites[pair] };
      const saveSuccess = saveToBackend(pair, newFavorites[pair]);

      if (!saveSuccess) {
        return prevFavorites;
      }

      return newFavorites;
    });
  };

  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
      return;
    }

    setAnchorEl(event.currentTarget);
    if (pairDisplayRef.current) {
      pairDisplayRef.current.focus();
    }
  };

  const handleMarketTypeButtonClick = (buttonType) => {
    setSelectedMarketType(buttonType);
  };

  const debouncedHandleSearchChange = useCallback(
    debounce((event) => {
      setConfirmedSearchQuery(event.target.value);

      if (event.target.value !== '') {
        setAnchorEl(pairDisplayRef.current);
      }
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    debouncedHandleSearchChange(event);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target) &&
        !pairDisplayRef.current.contains(event.target)
      ) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popperRef]);

  let qualifiedAccounts = [];

  if (accounts !== undefined) {
    qualifiedAccounts =
      selectedAccounts && selectedAccounts.length === 0
        ? Object.values(accounts)
        : selectedAccounts.map((accountId) => accounts[accountId]);
  }

  const calculateAssetBalance = useCallback(
    (symbol, exchanges) => {
      let totalAmount = 0;

      qualifiedAccounts
        .filter((account) => exchanges.includes(account.exchangeName))
        .forEach((account) => {
          if (!balances[account.id]) {
            return;
          }

          balances[account.id].assets.forEach((asset) => {
            if (asset.symbol === symbol) {
              totalAmount += asset.amount;
            }
          });
        });

      return totalAmount;
    },
    [balances, qualifiedAccounts]
  );

  const filteredPairs = useMemo(() => {
    let tradingPairs = pairs;

    if (selectedMarketType) {
      if (selectedMarketType === 'favorites') {
        tradingPairs = tradingPairs.filter((pair) => favourites[pair.id]);
      } else {
        tradingPairs = tradingPairs.filter(
          (pair) => pair.market_type === selectedMarketType
        );
      }
    }

    if (selectedAccounts && selectedAccounts.length > 0) {
      tradingPairs = tradingPairs.filter((pair) => {
        const accountExchanges = selectedAccounts.map(
          (accountId) => accounts[accountId].exchangeName
        );
        return accountExchanges.every((exchange) =>
          pair.exchanges.includes(exchange)
        );
      });
    }

    if (confirmedSearchQuery) {
      tradingPairs = matchSorter(tradingPairs, confirmedSearchQuery, {
        keys: ['id'],
      });
    }
    return tradingPairs;
  }, [pairs, selectedMarketType, confirmedSearchQuery, selectedAccounts]);

  const getItemSize = () => 40; // Row height

  const renderMarketTypeButton = (marketType, marketLabel) => {
    return (
      <Button
        fullWidth
        sx={{
          border:
            selectedMarketType === marketType
              ? '2px solid rgb(230, 230, 230)'
              : '2px solid transparent',
          width: '30%',
          borderStyle: 'none none solid none',
          color: 'secondary',
          marginBottom: '0.2rem',
          '&:hover': {
            backgroundColor: 'info.dark3',
          },
        }}
        onMouseDown={() => handleMarketTypeButtonClick(marketType)}
      >
        <Typography fontFamily='Jost' fontSize='0.95rem' fontWeight='200'>
          {marketLabel}
        </Typography>
      </Button>
    );
  };

  const renderTableHeader = (text, width) => {
    return (
      <Cell align='left' width={width}>
        <Typography fontWeight='500'>{text}</Typography>
      </Cell>
    );
  };

  const renderPairDisplay = () => {
    if (multiOrder || ChainedOrders) {
      return (
        <Stack
          alignItems='center'
          direction='row'
          height='100%'
          justifyContent='space-between'
          marginX='8px'
          ref={pairDisplayRef}
          onClick={handleClick}
        >
          <Typography variant='body1'>
            {selectedPairName || 'Select Pair'}
          </Typography>
          <Box alignItems='center' display='flex' flexDirection='row'>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Box>
        </Stack>
      );
    }
    return (
      <Stack
        alignItems='center'
        direction='row'
        gap={1}
        height='100%'
        justifyContent='center'
        ref={pairDisplayRef}
        onClick={handleClick}
      >
        <Typography variant='h4'>{selectedPairName}</Typography>
        <Box alignItems='center' display='flex' flexDirection='row'>
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Box>
      </Stack>
    );
  };

  return (
    <Box alignItems='center' direction='row' height='100%' width='100%'>
      {renderPairDisplay()}
      <Popper
        anchorEl={anchorEl}
        open={open}
        placement='bottom-start'
        ref={popperRef}
        sx={{
          zIndex: 9999,
          overflow: 'auto',
          backgroundColor: 'grey.dark2',
          border: '1px solid rgb(51, 51, 51)',
        }}
      >
        <Stack direction='column' sx={{ width: 675, height: 750 }}>
          <Box padding='8px 8px' width='calc(100% - 16px)'>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position='start'
                    sx={{ paddingRight: '14px' }}
                  >
                    <Search size={20} sx={{ color: theme.palette.info.main }} />
                  </InputAdornment>
                ),
              }}
              placeholder='Search for a trading pair'
              value={searchQuery}
              variant='outlined'
              onChange={handleSearchChange}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            {renderMarketTypeButton('favorites', 'Favorites')}
            {renderMarketTypeButton('spot', 'Spot')}
            {renderMarketTypeButton('perp', 'Perpetual')}
            {renderMarketTypeButton('future', 'Futures')}
          </Box>
          <Divider />
          <Stack direction='column' flexGrow={1}>
            <Stack
              direction='row'
              height='1.5rem'
              paddingRight='10px'
              py='0.4rem'
            >
              {renderTableHeader('', favouriteColumnWidth)}
              {renderTableHeader('Pair', pairColumnWidth)}
              {renderTableHeader('Balance', balanceColumnWidth)}
              {renderTableHeader('Exchange', exchangeColumnWidth)}
            </Stack>
            <Divider />
            <Stack direction='column' height='600px' width='100%'>
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    height={height}
                    itemCount={filteredPairs.length}
                    itemData={{
                      pairs: filteredPairs,
                      favourites,
                      balances,
                      calculateAssetBalance,
                      setAnchorEl,
                      setSelectedPair,
                      setSearchQuery,
                      setConfirmedSearchQuery,
                      toggleFavorite,
                      handlePairSelect,
                      balanceEnabled: user && user.is_authenticated,
                    }}
                    itemSize={getItemSize}
                    style={{ scrollbarGutter: 'stable' }}
                    width={width}
                  >
                    {Row}
                  </List>
                )}
              </AutoSizer>
            </Stack>
          </Stack>
        </Stack>
      </Popper>
    </Box>
  );
}

export default PairSelector;
