import React from 'react';
import { Box } from '@mui/material';
import ExplorerTradesTable from './ExplorerTradesTable';

export default function ExplorerTradesPage() {

  return (
    <Box
      spacing={2}
      sx={{
        height: '850px',
        width: '80%',
        margin: '0 auto',
      }}
    >
      <ExplorerTradesTable />
    </Box>
  )
}