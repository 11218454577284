import { Button, Card, CardContent, CardHeader, Icon, Link, Table, TableBody,
  TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React,{ useState, useEffect, useContext } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { displayDefaultTableCell, StyledHeaderTableCellWithLine,
  StyledPaddingTableCell, StyledTableCell } from "../../shared/orderTable/util";
import { getArweaveData } from "../../apiServices";
import { ErrorContext } from "../../shared/context/ErrorProvider";
import arweaveLight from '../../../images/logos/arweave-light.png'
import ICONS from "../../../images/exchange_icons";

const columns = [
  { id: 'id', label: 'Transaction ID', width: 260,  align: 'left' },
  { id: 'exchange_name', label: 'Exchange', width: 50,  align: 'left' },
  { id: 'trader_id', label: 'User', width: 60,  align: 'left' },
  { id: 'epoch', label: 'Epoch', width: 30, align: 'left' },
  { id: 'merkle_root', label: 'Hash', width: 80, align: 'left' },
  // { id: 'usd_volume', label: 'Volume', width: 30, align: 'right' },
]

function insertEllipsis(str, startSlice = 4, endSlice = 3) {
  if (!str) {
    return '';
  }

  if (str.length <= 7) {
    return str; // No need to shorten
  }

  const start = str.slice(0, startSlice);
  const end = str.slice((-1 * endSlice));

  return `${start}...${end}`;
}

export default function ExplorerTradesTable({
  setHasError,
  setErrorContent,
  pageView,
}) {
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cursors, setCursors] = useState([]);

  const rowsPerPage = pageView? 10 : 25;

  const navigate = useNavigate()
  const theme = useTheme()

  const fetchTransactions = async (rows, cursor = null) => {
    setLoading(true)

    try {

      const result = await getArweaveData(rows, cursor)
      const resultTransactions = result.edges.map((edge ,idx) => {
        return edge.node
      });

      const nextPageCursor = result.edges.length > 0 ? result.edges[result.edges.length - 1].cursor : null;


      const resultTransactionsWithTags = resultTransactions.map((tx) => {
        const tags = tx.tags.reduce((acc, tag) => {
          acc[tag.name] = tag.value;
          return acc;
        }, {});
        return {
          ...tx,
          ...tags,
        };
      })
      setTransactions(resultTransactionsWithTags);
      setCursors((prevCursors) => {
        if (!cursor) {
          return [nextPageCursor];
        }
        return [...prevCursors, nextPageCursor];
      });
    } catch (error) {
      setHasError(true);
      setErrorContent(`Error fetching transactions: ${  error.message}`);
    }
    setLoading(false)
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      fetchTransactions(rowsPerPage, cursors[page]);
    } else if (newPage < page) {
      fetchTransactions(rowsPerPage, cursors[newPage - 1] || null);
    }
    setPage(newPage);
  };


  useEffect(() => {

    fetchTransactions(rowsPerPage, null);

    const transactioninterval = setInterval(() => {
      fetchTransactions(rowsPerPage, cursors[page]);
    } , 600000);

    return () => {
      clearInterval(transactioninterval)
    }
  }, []);

  return (
    <Card sx={{ height: '95%', width: '100%' }}>
      <Stack alignItems="center" direction="row" spacing={2} style={{paddingLeft: '20px', paddingTop: '20px'}}>
        <img alt="Arweave Logo Light" src={arweaveLight} style={{ height: '30px'}} />
        <Typography variant='h4'>Tokenized Trades</Typography>
      </Stack>
      <CardContent>
        <Box sx={{ height: '100%'}}>
          {loading ? (
            <Box
              alignItems="center"
              display="flex"
              height= 'calc(100% - 125px)'
              justifyContent="center"
            >
              <ScaleLoader color={theme.palette.text.offWhite} />
            </Box>) : (
            <TableContainer sx={{maxHeight: 'calc(100% - 125px)'}}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledHeaderTableCellWithLine
                        align={column.align}
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          width : column.width || undefined,
                        }}
                      >
                        {column.label}
                      </StyledHeaderTableCellWithLine>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{overflow: 'auto'}}>
                  {transactions
                    .map((tx) => {
                      return (
                        <TableRow
                          hover
                          key={tx.id}
                        >
                          {columns.map((column) => {
                            const value = tx[column.id];
                            switch(column.id){
                            case 'exchange_name':
                              return (  <StyledTableCell align={column.align} key={column.id}>
                                <Stack alignItems="center" direction='row' spacing={1}>
                                  <Icon
                                    sx={{
                                      borderRadius: '50%',
                                      width: '1.4rem',
                                      height: '1.4rem',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      '& svg': {
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                      },
                                      mx: '-0.3rem',
                                    }}
                                  >
                                    <img alt='exchange icon' src={ICONS[value.toLowerCase()] || ICONS.default} />
                                  </Icon>
                                  <Typography variant='body1'>
                                    {value.charAt(0).toUpperCase() + value.slice(1)}
                                  </Typography>
                                </Stack>
                              </StyledTableCell>
                              )
                            case 'merkle_root':
                              return displayDefaultTableCell(column, insertEllipsis(value,8,6), {}, StyledTableCell)
                            case 'trader_id':
                              return displayDefaultTableCell(column, insertEllipsis(value), {}, StyledTableCell)
                            case 'id':
                              return  (<StyledTableCell align={column.align} key={column.id}>
                                <Link href={`//arweave.app/tx/${value}`} >
                                  {insertEllipsis(value,8,6)}
                                </Link>
                              </StyledTableCell>)
                            default:
                              return displayDefaultTableCell(column, value, {}, StyledTableCell)
                            }
                          })}
                        </TableRow>
                      )})}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {pageView ?
            <Button
              sx={{width: '100%', height: '50px', border: 0,
                color: theme.palette.text.offWhite, borderColor: theme.palette.text.offWhite}}
              variant="outlined"
              onClick={() => navigate('/explorer/trades')}
            >
              View all trades
            </Button>
            :<TablePagination
              component="div"
              count={-1}
              page={page}
              rowsPerPage={pageView ? rowsPerPage : 25}
              rowsPerPageOptions={[10]}
              sx={{height: '60px'}}
              onPageChange={handleChangePage}
            />
          }
        </Box>
      </CardContent>
    </Card>
  )

}
