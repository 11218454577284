import { useTheme } from '@emotion/react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import React, { useLayoutEffect, useRef } from 'react';
import { buildPausePlotBands } from '../../../../util';
import chartWatermark from '../../../../shared/chartWatermark';
import { Loader } from '../../../../shared/Loader';

const calculateTimeInterval = (origTimeEnd, timeStart) => {
  const timeDelta = Date.parse(origTimeEnd) - Date.parse(timeStart)
  const rawInterval = timeDelta / 5
  const roundedInterval = Math.ceil(rawInterval / 60000) * 60000
  return roundedInterval
}

const timestampSplicer = (splicee, timestamps) => {
  return timestamps.map((ts, index) => {
    if (index >= splicee.length) {
      return [ts, null]
    }
    return [ts, splicee[index]]
  })
}

function PriceDifferenceChart({data, timeStart, origTimeEnd}){
  const theme = useTheme();
  const chartComponent = useRef(null);

  useLayoutEffect(() => {
    function updateSize() {
      if(chartComponent.current) {
        chartComponent.current.chart.reflow();
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  if (Object.keys(data).length === 0) {
    return <div />
  }

  const isPaused = data.order.status === 'PAUSED';
  const pausePlotBands = buildPausePlotBands(data.multi_order_pause_windows, isPaused, data.order.paused_at, theme);

  // Excludes first bucket to have graph be close to y axis...
  // first bucket data is usually small, which displays as a hidden bucket
  const slicedBuyFills = data.fills.breakdowns.buy ? {
    takes: timestampSplicer(data.fills.breakdowns.buy.takes, data.fills.timestamps).slice(1),
    makes: timestampSplicer(data.fills.breakdowns.buy.makes, data.fills.timestamps).slice(1),
    crosses: timestampSplicer(data.fills.breakdowns.buy.crosses, data.fills.timestamps).slice(1),
  } : {
    takes: [],
    makes: [],
    crosses: [],
  }

  const slicedSellFills = data.fills.breakdowns.sell ? {
    takes: timestampSplicer(data.fills.breakdowns.sell.takes, data.fills.timestamps).slice(1),
    makes: timestampSplicer(data.fills.breakdowns.sell.makes, data.fills.timestamps).slice(1),
    crosses: timestampSplicer(data.fills.breakdowns.sell.crosses, data.fills.timestamps).slice(1),
  } : {
    takes: [],
    makes: [],
    crosses: [],
  }

  const options = {
    chart: {
      alignThresholds: true,
      animation: false,
      backgroundColor: 'transparent',
      zooming: {
        mouseWheel: false
      },
      zoomType: null,
      marginLeft: 80,
    },
    series: [{
      type: 'column',
      name: 'Take',
      yAxis: 1,
      data: slicedBuyFills.takes,
      color: theme.palette.charts.redTransparent,
      tooltip: {
        valueDecimals: 2,
      },
      enableMouseTracking: false
    },{
      type: 'column',
      name: 'Make',
      yAxis: 1,
      data: slicedBuyFills.makes,
      color: theme.palette.charts.greenTransparent,
      tooltip: {
        valueDecimals: 2,
      },
      enableMouseTracking: false
    },{
      type: 'column',
      name: 'Cross',
      yAxis: 1,
      data: slicedBuyFills.crosses,
      color: theme.palette.charts.orangeTransparent,
      tooltip: {
        valueDecimals: 2,
      },
      enableMouseTracking: false
    },{
      type: 'column',
      name: 'Take',
      yAxis: 1,
      data: slicedSellFills.takes,
      color: theme.palette.charts.redTransparent,
      tooltip: {
        valueDecimals: 2,
      },
      showInLegend: false,
      enableMouseTracking: false,
    },{
      type: 'column',
      name: 'Make',
      yAxis: 1,
      data: slicedSellFills.makes,
      color: theme.palette.charts.greenTransparent,
      tooltip: {
        valueDecimals: 2,
      },
      showInLegend: false,
      enableMouseTracking: false
    },{
      type: 'column',
      name: 'Cross',
      yAxis: 1,
      data: slicedSellFills.crosses,
      color: theme.palette.charts.orangeTransparent,
      tooltip: {
        valueDecimals: 2,
      },
      showInLegend: false,
      enableMouseTracking: false
    },{
      type: 'line',
      name: 'Net Exposure',
      data: [...data.net_exposure],
      yAxis: 0,
      xAxis: 1,
      color: theme.palette.charts.offWhite,
      tooltip: {
        valueDecimals: 2,
      },
      step: 'left',
    },
    ],
    yAxis: [{
      threshold: 0,
      title: {
        text: "Imbalance ($)",
        margin: 15,
        style: {
          color: theme.palette.text.offWhite,
        },
      },
      opposite: false,
      // softMin: data.positive_exposure_tolerance_band[0][1],
      // softMax: data.negative_exposure_tolerance_band[0][1],
      gridLineColor: theme.palette.charts.gridLines,
      labels: {
        style: {
          color: theme.palette.text.offWhite
        },
      },
    },{
      threshold: 0,
      visible: false,
      title: {
        text: "Fill Quantity",
        rotation: 270,
        margin: 15,
        style: {
          color: theme.palette.text.offWhite
        },
      },
      opposite: true,
      gridLineColor: theme.palette.charts.gridLines,
      labels: {
        style: {
          color: theme.palette.text.offWhite
        },
      },
    }],
    xAxis: [{
      dateTimeLabelFormats: {
        minute: '%H:%M'
      },
      ordinal: false,
      softMax: Date.parse(origTimeEnd),
      endOnTick: false,
      plotBands: pausePlotBands,
      tickInterval: calculateTimeInterval(origTimeEnd, timeStart), // 4 tick intervals
      type: 'datetime',
      labels: {
        useHTML: true,
        style: {
          color: theme.palette.text.offWhite
        },
      },
    },{
      visible: false,
      dateTimeLabelFormats: {
        minute: '%H:%M'
      },
      ordinal: false,
      softMax: Date.parse(origTimeEnd),
      tickInterval: calculateTimeInterval(origTimeEnd, timeStart), // 4 tick intervals
      type: 'datetime',
      labels: {
        useHTML: true,
        style: {
          color: theme.palette.text.offWhite
        },
      },
    }],
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false
        },
        pointPadding: 0, // Minimizes the space between points within the same category
        groupPadding: 0.05, // Further reduces space between categories to make bars thicker
        borderWidth: 0,
        borderRadius: 0 // Ensures the tops of the columns are flat
      },
      series:{
        threshold: 0,
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            opacity: 1
          }
        },
        events: {
          legendItemClick (e) {
            e.preventDefault();
            return false
          }
        }
      },
      allowPointSelect: false,
    },
    legend: {
      enabled: true,
      itemStyle: {
        color: theme.palette.text.offWhite,
      }
    },
    tooltip: {
      outside: true,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    credits: {
      enabled: false,
    }
  };

  // needs to happen conditionally, only transforms the chart options, should be fine
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const watermarkedOptions = chartWatermark({options});

  // eslint-disable-next-line consistent-return
  return (
    <HighchartsReact
      constructorType='stockChart'
      containerProps={{ style: { height: "100%" } }}
      highcharts={Highcharts}
      options={watermarkedOptions}
      ref={chartComponent}
    />
  );
}

export { PriceDifferenceChart };