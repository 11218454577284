import { useTheme } from '@emotion/react';
import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';  // Import Bar instead of Doughnut
import { ThinLoader } from '../../Loader';



function FillExchangeChart({ data }) {
  const theme = useTheme();

  const exchangeColors = {
    'OKX': theme.palette.exchangeColors.OKXTransparent,
    'Binance': theme.palette.exchangeColors.BinanceTransparent,
    'Bybit': theme.palette.exchangeColors.BybitTransparent,
    'Deribit': theme.palette.exchangeColors.DeribitTransparent,
    'Coinbase': theme.palette.exchangeColors.CoinbaseTransparent,
    'MockExchange': theme.palette.exchangeColors.MockExchangeTransparent
    // Add more exchanges as necessary
  };
  const { breakdown_fill_exchange_data, breakdown_fill_exchange_labels } = data || {};

  const datasets = useMemo(() => {
    if(breakdown_fill_exchange_data !== undefined){
      return Object.keys(breakdown_fill_exchange_data).map((key, index) => ({
        label: key,
        data: [breakdown_fill_exchange_data[key]],
        backgroundColor: exchangeColors[key] || theme.palette.scheme1[index],
        borderColor: exchangeColors[key] || theme.palette.scheme1[index]
      }));
    }
    return []
  }, [breakdown_fill_exchange_data]);

  if (breakdown_fill_exchange_data === undefined || Object.keys(breakdown_fill_exchange_data).length === 0) {
    return <ThinLoader />
  }

  const fill_exchange_data = {
    labels: breakdown_fill_exchange_labels,
    datasets
  };

  const options = {
    indexAxis: 'y',  // Horizontal bar chart
    scales: {
      x: {
        stacked: true,  // Enable stacking
        ticks: {
          display: false  // Hide x-axis labels
        }
      },
      y: {
        stacked: true,  // Enable stacking
        ticks: {
          display: false  // Hide y-axis labels
        }
      }
    },
    layout: {
      padding: {
        right: 12,
        left: -10,
        bottom: -10
      },
    },
    plugins: {
      legend: {
        display: false  // Optionally hide the legend
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title() {
            return '';  // Remove the title/header from the tooltip
          },
          label(tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw[0].toFixed(2)}%`;  // Customize the tooltip label
          }
        }
      }
    },
    maintainAspectRatio: false
  };

  return <Bar data={fill_exchange_data} height="32px" options={options} />;
}

export { FillExchangeChart };
