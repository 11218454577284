/* eslint-disable no-param-reassign */
import { useTheme } from '@emotion/react';
import { Button, CardHeader, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import heatmap from 'highcharts/modules/heatmap';
import treemap from 'highcharts/modules/treemap';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { BASEURL } from '../../util';
import { OrderSearch } from '../orderView/OrderSearch';
import { TransactionBenchmark } from './TransactionBenchmark';
import { TransactionSummaryRender } from './TransactionSummary';
import { getTcaData } from '../../apiServices';
import { ErrorContext } from '../../shared/context/ErrorProvider';

heatmap(Highcharts);
treemap(Highcharts);

function TransactionCostsPage() {
  const [orders, setOrders] = useState([]);
  const [summary, setSummary] = useState({});
  const [breakdown, setBreakdown] = useState({
    side: { labels: [] },
    strategy: { labels: [] },
    fill_role: { labels: [] },
    fill_exchange: { labels: [] },
  });
  const [timeSeries, setTimeSeries] = useState({
    notional: {
      labels: [],
      data: [],
    },
  });
  const [distributions, setDistributions] = useState({});
  const [loading, setLoading] = useState(true);
  // state for toggling to display long 'n short or participation rate chart
  const [showLongShortCard, setShowLongShortCard] = useState(true);
  const { setHasError, setErrorContent } = useContext(ErrorContext);
  const showAlert = ({ severity, message }) => {
    setErrorContent({ severity, message });
    setHasError(true);
  };

  const chartComponent = useRef(null);
  const theme = useTheme();
  const setSearchResult = (messageData) => {
    setSummary(messageData.summary);
    setBreakdown(messageData.breakdowns);
    setTimeSeries(messageData.timeseries);
    setDistributions(messageData.distributions);
  };

  const toggleLongShortCard = () => {
    setShowLongShortCard(!showLongShortCard);
  };

  const COLORS = [
    '#FFA345',
    '#624185',
    '#3B2747',
    '#FF6172',
    '#8F7EB8',
    '#39A9DB',
    '#FFC857',
    '#8AC6D0',
    '#FF926B',
    '#93329E',
  ];

  const FILL_ROLE_COLORS = {
    MAKE: '#4B8673',
    TAKE: '#E64848',
  };
  const exchangeColors = {
    OKX: theme.palette.exchangeColors.OKXTransparent,
    Binance: theme.palette.exchangeColors.BinanceTransparent,
    Bybit: theme.palette.exchangeColors.BybitTransparent,
    Deribit: theme.palette.exchangeColors.DeribitTransparent,
    Coinbase: theme.palette.exchangeColors.CoinbaseTransparent,
    MockExchange: theme.palette.exchangeColors.MockExchangeTransparent,
    // Add more exchanges as necessary
  };
  const STRATEGY_COLORS = {
    VWAP: '#495C83',
    TWAP: '#C8B6E2',
    Market: '#FF926B',
    Limit: '#93329E',
  };

  const horizontalBarOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
        stacked: true,
        offset: true,
        ticks: {
          max: 100,
        },
      },
      y: {
        afterFit(scale) {
          scale.width = 70;
        },
        stacked: true,
      },
    },
    animation: false,
  };

  const breakdown_strategy = () => {
    return breakdown.strategy === undefined ? {} : breakdown.strategy;
  };
  const breakdown_role = () => {
    return breakdown.fill_role !== undefined && breakdown.fill_role !== null
      ? breakdown.fill_role
      : { labels: [] };
  };
  const breakdown_exchange = () => {
    return breakdown.fill_exchange === undefined ? [] : breakdown.fill_exchange;
  };

  // const strategy_data = {
  //   labels: ['strategy_name'],
  //   datasets: Object.keys(breakdown).length > 0 ?
  //     breakdown_strategy().labels.map((label) => {
  //       return({
  //         data: breakdown_strategy().data[label],
  //         label,
  //         borderColor: STRATEGY_COLORS[label],
  //         backgroundColor: STRATEGY_COLORS[label],
  //         borderWidth: 2,
  //         radius: '40%'
  //       })
  //     }) : []
  // };

  // winsorize data, lower n upper percentiles can be adjusted
  const winsorize = (data, lowerPercentile = 10, upperPercentile = 90) => {
    if (!data) return data;

    const sortedData = [...data].sort((a, b) => a - b);
    const lowerBound =
      sortedData[Math.floor((lowerPercentile / 100) * sortedData.length)];
    const upperBound =
      sortedData[Math.ceil((upperPercentile / 100) * sortedData.length - 1)];
    return data.map((value) =>
      Math.min(Math.max(value, lowerBound), upperBound)
    );
  };

  const strategy_data = {
    labels: breakdown_strategy().labels,
    datasets: [
      {
        label: 'Strategy',
        data:
          breakdown_strategy().data &&
          Object.values(breakdown_strategy().data).map((data) => data[0]),
        borderColor: Object.values(STRATEGY_COLORS),
        backgroundColor: Object.values(STRATEGY_COLORS),
      },
    ],
    radius: '90%',
  };

  const strategy_data_series = {
    data: breakdown_strategy().data
      ? Object.keys(breakdown_strategy().data).map((data) => {
          return { value: breakdown_strategy().data[data][0], label: data };
        })
      : [],
  };

  const fill_role_data = {
    labels: breakdown_role() ? breakdown_role().labels : [],
    datasets: [
      {
        label: 'Role',
        data:
          breakdown_role().data &&
          Object.values(breakdown_role().data).map((data) => data[0]),
        borderColor: Object.values(FILL_ROLE_COLORS),
        backgroundColor: Object.values(FILL_ROLE_COLORS),
        radius: '90%',
      },
    ],
  };

  const fill_exchange_data = {
    labels: breakdown_exchange() ? breakdown_exchange().labels : [],
    datasets: [
      {
        label: 'Exchange',
        data: winsorize(
          breakdown_exchange() &&
            breakdown_exchange().data &&
            Object.values(breakdown_exchange().data).map((data) => data[0])
        ),
        borderColor: Object.values(exchangeColors),
        backgroundColor: Object.values(exchangeColors),
        radius: '90%',
      },
    ],
  };

  const timeseries_data = {
    labels: timeSeries.notional.labels,
    datasets: [
      {
        label: 'Traded Notional',
        borderColor: '#4297A0',
        data: timeSeries.notional.data,
        fill: false,
        stepped: true,
      },
    ],
  };

  // new participation rate stuff

  const numberOfBins = 11;

  // Helper function to calculate bin counts based on pov data
  const calculateBinCounts = (povData, binInterval, maxParticipationRate) => {
    const binCounts = new Array(numberOfBins).fill(0);

    povData.forEach((value) => {
      let binIndex = Math.floor(value / binInterval);
      // Ensure the highest value falls into the last bin
      if (binIndex === numberOfBins) {
        binIndex = numberOfBins - 1;
      }
      binCounts[binIndex] += 1;
    });

    return binCounts;
  };

  // Calculate data for participation rate chart
  const calculateParticipationRateData = () => {
    if (!distributions.buy_pov_data || !distributions.sell_pov_data) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const winsorizedBuyData = winsorize(distributions.buy_pov_data);
    const winsorizedSellData = winsorize(distributions.sell_pov_data);

    const maxParticipationRate = Math.max(
      ...winsorizedBuyData,
      ...winsorizedSellData
    );

    const binInterval = maxParticipationRate / numberOfBins;

    const buyData = calculateBinCounts(
      winsorizedBuyData,
      binInterval,
      maxParticipationRate
    );
    const sellData = calculateBinCounts(
      winsorizedSellData,
      binInterval,
      maxParticipationRate
    );

    const labels = Array.from(
      { length: numberOfBins },
      (_, index) =>
        `${(index * binInterval).toFixed(2)}% - ${(index === numberOfBins - 1
          ? maxParticipationRate
          : (index + 1) * binInterval
        ).toFixed(2)}%`
    );

    return {
      labels,
      datasets: [
        {
          label: 'Buy',
          data: buyData,
          backgroundColor: '#ffa344',
          barThickness: 10,
          categoryPercentage: 1.0,
          barPercentage: 0.4,
        },
        {
          label: 'Sell',
          data: sellData,
          backgroundColor: '#3B2747',
          barThickness: 10,
          categoryPercentage: 1.0,
          barPercentage: 0.4,
        },
      ],
    };
  };

  const participationRateOptions = {
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 20,
        },
        drawOnChartArea: true,
        border: {
          display: false,
        },
        title: {
          display: true,
          text: 'Participation Rate',
        },
      },
      y: {
        border: {
          display: false,
        },
        afterFit(scale) {
          scale.width = 60;
        },
        title: {
          display: true,
          text: 'Number of Orders',
        },
      },
    },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
  };

  const order_distro_data = {
    labels: distributions.order_distro_labels || [],
    datasets: [
      {
        label: 'Buy', // previously long
        data: distributions.buy_order_distro_data || [],
        backgroundColor: '#ffa344',
        barThickness: 10, // Make bars thinner
        categoryPercentage: 1.0, // Ensure bars take up the full category width
        barPercentage: 0.4, // Set the bar width
      },
      {
        label: 'Sell', // previously short
        data: (distributions.sell_order_distro_data || []).map((data) =>
          Math.abs(data)
        ), // Ensure positive values
        backgroundColor: '#62526c',
        barThickness: 10, // Make bars thinner
        categoryPercentage: 1.0, // Ensure bars take up the full category width
        barPercentage: 0.4, // Set the bar width
      },
    ],
  };

  const vwap_cost_distro_data = {
    labels: distributions.vwap_slippage_labels,
    datasets: [
      {
        label: 'VWAP',
        radius: 1,
        tension: 0.6,
        data: distributions.vwap_slippage_data,
        backgroundColor: 'rgba(143,126,184,0.5)',
        fill: true,
      },
    ],
  };

  const arrival_cost_distro_data = {
    labels: distributions.arrival_slippage_labels,
    datasets: [
      {
        label: 'Arrival',
        radius: 1,
        tension: 0.6,
        data: distributions.arrival_slippage_data,
        backgroundColor: 'rgba(255,163,69,0.5)',
        fill: true,
      },
    ],
  };
  const notionalOptions = {
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 20,
        },
        drawOnChartArea: true,
        border: {
          display: false,
        },
        grid: {
          color: '#363636',
        },
      },
      y: {
        border: {
          display: false,
        },
        afterFit(scale) {
          scale.width = 60;
        },
        grid: {
          color: '#363636',
        },
      },
    },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
  };

  const orderDistroConfig = {
    scales: {
      x: {
        stacked: false,
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Order Notional',
        },
        title: {
          display: true,
          text: 'Order Notional',
        },
      },
      y: {
        stacked: false,
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Number of Orders',
        },
        title: {
          display: true,
          text: 'Number of Orders',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
    maintainAspectRatio: false,
  };
  const costDistroConfig = {
    scales: {
      x: {
        scaleLabel: {
          display: true,
          labelString: 'Cost (z-score)',
        },
      },
      y: {
        scaleLabel: {
          display: false,
        },
      },
    },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
  };
  const StrategyChartConfig = {
    data: strategy_data,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'Strategies',
        align: 'start',
      },
      legend: {
        position: 'right',
      },
    },
    animation: false,
  };
  // const timeseriesChart = new Chart(
  //     document.getElementById('timseries-chart'),
  //     timseriesConfig
  // );
  const fillRoleConfig = {
    data: fill_role_data,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'Role',
        align: 'start',
      },
      legend: {
        position: 'right',
      },
    },
    animation: false,
  };
  const fillExchangeConfig = {
    data: fill_exchange_data,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'Exchange',
        align: 'start',
      },
      legend: {
        position: 'right',
      },
    },
    animation: false,
  };
  const a = breakdown_strategy().data
    ? [
        ...Object.keys(breakdown_strategy().data).map((e) => {
          return {
            id: e,
            name: e,
            color: '#FFFFFF',
          };
        }),
        ...Object.keys(breakdown_strategy().data).flatMap((e) => {
          return Object.keys(breakdown_strategy().data[e]).flatMap((symbol) => {
            const value = breakdown_strategy().data[e][symbol][0];
            if (value === 0) {
              return [];
            }
            return {
              parent: e,
              name: symbol,
              value,
              colorValue: breakdown_strategy().data[e][symbol][1]
                ? breakdown_strategy().data[e][symbol][1]
                : null,
            };
          });
        }),
      ]
    : [];
  const strategyTreeMapOptions = {
    chart: {
      animation: false,
      backgroundColor: 'transparent',
      zooming: {
        mouseWheel: false,
      },
      zoomType: null,
      marginBottom: 80,
    },
    series: [
      {
        type: 'treemap',
        layoutAlgorithm: 'stripes',
        alternateStartingDirection: true,
        borderColor: theme.palette.text.offBlack,
        borderRadius: 6,
        borderWidth: 2,
        dataLabels: {
          style: {
            fontSize: '15px',
            fontFamily: 'IBM Plex Mono',
            color: theme.palette.text.offBlack,
          },
        },
        levels: [
          {
            level: 1,
            layoutAlgorithm: 'squarified',
            dataLabels: {
              enabled: true,
              align: 'left',
              verticalAlign: 'top',
              style: {
                fontSize: '15px',
                fontFamily: 'IBM Plex Mono',
              },
            },
          },
        ],
        data: a,
      },
    ],
    colorAxis: {
      min: -20,
      max: 20,
      labels: {
        enabled: true,
        step: 20,
        style: {
          color: theme.palette.text.offWhite,
        },
      },
      stops: theme.palette.tcaScheme,
      layout: 'vertical',
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter() {
        const point = this;
        if (!point.point.parent) {
          return false;
        }
        return `Strategy: <b>${point.point.parent}</b><br/>
          Notional: <b>${point.point.value}</b><br/>
          Slippage: <b>${point.point.colorValue ? point.point.colorValue : 0}</b>`;
      },
    },
    legend: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
  return (
    <Stack direction='column' height='100%' spacing={0}>
      <Card style={{ height: '80px', marginBottom: '8px' }}>
        <CardContent>
          <OrderSearch
            setLoading={setLoading}
            setOrders={setOrders}
            url='/analytics/tca_stats/'
            urlHandler={setSearchResult}
          />
        </CardContent>
      </Card>
      <Grid
        container
        alignItems='stretch'
        columns={24}
        spacing={1}
        sx={{ overflowY: 'auto', height: 'calc(100% - 80px)' }}
      >
        <Grid style={{ height: '90%' }} xs={6}>
          <Stack direction='column' height='100%' spacing={1}>
            <Card>
              <CardContent>
                <TransactionSummaryRender TransactionSummaryData={summary} />
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <TransactionBenchmark
                  benchmarkData={summary}
                  loading={loading}
                />
              </CardContent>
            </Card>
          </Stack>
        </Grid>
        <Grid style={{ height: '90%' }} xs={18}>
          <Stack direction='column' height='100%' spacing={1}>
            <Card>
              <Typography
                sx={{ marginTop: '10px', marginLeft: '10px' }}
                variant='h6'
              >
                Order Breakdown
              </Typography>
              <CardContent>
                <Box display='flex' sx={{ height: '100%' }}>
                  <HighchartsReact
                    constructorType='stockChart'
                    containerProps={{
                      style: { height: '100%', width: '100%' },
                    }}
                    highcharts={Highcharts}
                    options={strategyTreeMapOptions}
                    ref={chartComponent}
                  />
                </Box>
              </CardContent>
            </Card>
            <Stack direction='row' height='50%' spacing={1}>
              <Card sx={{ width: '50%' }}>
                <Typography
                  sx={{ marginTop: '10px', marginLeft: '10px' }}
                  variant='h6'
                >
                  Fill Breakdown
                </Typography>
                <CardContent>
                  <Stack
                    direction='row'
                    style={{ height: 'calc(100% - 60px)' }}
                  >
                    <Box display='flex' sx={{ height: '100%', width: '50%' }}>
                      <Doughnut
                        data={fill_role_data}
                        options={fillRoleConfig}
                      />
                    </Box>
                    <Box display='flex' sx={{ height: '100%', width: '50%' }}>
                      <Doughnut
                        data={fill_exchange_data}
                        options={fillExchangeConfig}
                      />
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
              <Card sx={{ width: '50%' }}>
                <CardContent
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    display='flex'
                    sx={{ height: '90%', width: '90%', position: 'relative' }}
                  >
                    <Line
                      data={timeseries_data}
                      options={notionalOptions}
                      width='90%'
                    />
                  </Box>
                </CardContent>
              </Card>
            </Stack>
          </Stack>
        </Grid>
        <Grid style={{ height: '40%' }} xs={8}>
          <Card>
            <CardContent sx={{ height: '80%' }}>
              <CardHeader
                action={
                  <Button onClick={toggleLongShortCard}>Switch Graph</Button>
                }
              />
              <Stack height='85%'>
                <Typography sx={{ marginTop: '-40px' }} variant='h6'>
                  Order Size Distribution
                </Typography>
                <Typography sx={{ marginBottom: '16px' }} variant='body2'>
                  * Winsorized (5%/95%)
                </Typography>
                {showLongShortCard ? (
                  <Bar data={order_distro_data} options={orderDistroConfig} />
                ) : (
                  <Bar
                    data={calculateParticipationRateData()}
                    options={participationRateOptions}
                  />
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid style={{ height: '40%' }} xs={8}>
          <Card>
            <CardContent>
              <Line data={vwap_cost_distro_data} options={costDistroConfig} />
            </CardContent>
          </Card>
        </Grid>
        <Grid style={{ height: '40%' }} xs={8}>
          <Card>
            <CardContent>
              <Line
                data={arrival_cost_distro_data}
                options={costDistroConfig}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default TransactionCostsPage;
