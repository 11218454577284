import FormControl from '@mui/material/FormControl/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import React from 'react';

export default function TrajectoryDropdown({
  trajectory,
  setTrajectory,
  trajectories,
}) {
  return (
    <FormControl fullWidth>
      <InputLabel id='select-trajectory-dropdown-label'>
        <Typography>Trajectory</Typography>
      </InputLabel>
      <Select
        id='select-trajectory-dropdown'
        label='Trajectory'
        labelId='select-trajectory-dropdown-label'
        value={trajectory}
        onChange={(e) => setTrajectory(e.target.value)}
      >
        {Object.keys(trajectories)
          .filter((id) => trajectories[id].schedule)
          .map((id) => (
            <MenuItem key={id} sx={{ paddingLeft: '2em' }} value={id}>
              {trajectories[id].name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
