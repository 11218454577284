import React from 'react';

function DataComponent({
  loadingComponent,
  isLoading,
  emptyComponent,
  isEmpty,
  children,
}) {
  if (isLoading) {
    return loadingComponent;
  }

  if (isEmpty) {
    return emptyComponent;
  }

  return children;
}

export default DataComponent;
