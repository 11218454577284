import React from 'react';
import { Box } from '@mui/material';
import ExplorerProofsTable from './ExplorerProofsTable';

export default function ExplorerProofsPage({children}) {

  return (
    <Box
      spacing={2}
      sx={{
        height: '850px',
        width: '80%',
        margin: '0 auto',
      }}
    >
      <ExplorerProofsTable />
    </Box>
  )
}