/* eslint-disable no-await-in-loop */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, CardContent, Stack, Switch } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import 'chartjs-adapter-moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from '@/shared/context/TitleProvider';
import {
  fetchOrderDetailData,
  fetchPovOrderChartData,
  getMarkoutData,
} from '../../../apiServices';
import { ErrorContext } from '../../../shared/context/ErrorProvider';
import {
  OrderBenchmarks,
  OrderSummary,
  OrderMessages,
} from '../../../shared/orderDetail';
import { BidAskChart, FillOrderChart } from './charts';

import MarkoutGraph from '../../../shared/orderDetail/MarkoutGraph'; // Correct import
import { MarketVolumeChart } from './charts/pov/MarketVolumeChart';
import { PovMarketChart } from './charts/pov/PovMarketChart';
import { OrderActions } from './OrderActions/OrderActions';

function OrderDetailsPage() {
  const { uuid } = useParams();

  const navigate = useNavigate();

  const [benchmarkState, setBenchmarkState] = useState({});
  const [orderSummaryState, setOrderSummaryState] = useState({});
  const [bidAskState, setBidAskState] = useState([]);
  const [passiveFillState, setPassiveFillState] = useState([]);
  const [aggroFillState, setAggroFillState] = useState([]);
  const [crossFillState, setCrossFillState] = useState([]);
  const [analytics, setAnalytics] = useState({});
  const [isPov, setIsPov] = useState(false);
  const [limitHistory, setLimitHistory] = useState([]);
  const [povChartData, setPovChartData] = useState({
    cumulativePov: [],
    fills: { take: [], make: [] },
    timestamps: [],
    volume: [],
  });
  const [chartType, setChartType] = useState('default');
  const [markoutData, setMarkoutData] = useState([]); // state for markout data
  const [activePlacements, setActivePlacements] = useState([]);
  const [orderMessages, setOrderMessages] = useState(null);

  const [chartTrajectoryToggle, setChartTrajectoryToggle] = useState(false);

  const { setHasError, setErrorContent } = useContext(ErrorContext);
  const { status, pct_filled, side, pair } = orderSummaryState;
  const { setTitle } = useTitle();
  useEffect(() => {
    if (status && pct_filled >= 0) {
      setTitle(
        `${status} (${Math.round(Number(pct_filled))}%) - ${side} ${pair}`
      );
    }
  }, [status, pct_filled, side, pair]);

  const showAlert = ({ severity, message }) => {
    setErrorContent({ severity, message });
    setHasError(true);
  };

  const parseOrderData = (messageData) => {
    const {
      passive_fills,
      aggro_fills,
      cross_fills,
      benchmark,
      order_summary,
      bid_ask_prices,
      order_analytics,
      limit_price_history,
      active_placements,
      failure_reason,
      messages,
    } = messageData;

    if (failure_reason && failure_reason !== '') {
      showAlert({
        severity: 'warning',
        message: `Failure Reason: ${failure_reason}`,
      });
    }

    if (Object.keys(messageData).length > 0) {
      if (Object.keys(benchmark).length > 0) {
        const { interval_volume, base_asset, pov } = benchmark;
        setBenchmarkState((prevState) => ({
          ...benchmark,
          interval_volume:
            interval_volume !== undefined
              ? interval_volume
              : prevState.interval_volume,
          base_asset:
            base_asset !== undefined ? base_asset : prevState.base_asset,
          pov: pov !== undefined ? pov : prevState.pov,
          points_earned: order_summary?.points_earned,
        }));
      }
      if (Object.keys(order_summary).length > 0) {
        setOrderSummaryState(order_summary);
        const { pov_target } = order_summary;
        if (pov_target !== null) {
          setIsPov(true);
        }
      }
      if (bid_ask_prices && bid_ask_prices.length > 0) {
        setBidAskState(bid_ask_prices);
      }
      if (passive_fills && passive_fills.length > 0) {
        const passiveFillsPrice = passive_fills.map((e) => [e.x, e.price]);
        setPassiveFillState(passiveFillsPrice);
      }
      if (aggro_fills && aggro_fills.length > 0) {
        const aggroFillsPrice = aggro_fills.map((e) => [e.x, e.price]);
        setAggroFillState(aggroFillsPrice);
      }
      if (cross_fills && cross_fills.length > 0) {
        const crossFillsPrice = cross_fills.map((e) => [e.x, e.price]);
        setCrossFillState(crossFillsPrice);
      }

      if (Object.keys(order_analytics).length > 0) {
        setAnalytics(order_analytics);
      }
      if (limit_price_history && limit_price_history.length > 0) {
        const parsed_limit_history = limit_price_history.map((e) => [
          Number(e[0]),
          Number(e[1]),
        ]);
        setLimitHistory(parsed_limit_history);
      }
      if (active_placements && active_placements.length > 0) {
        const activePlacementsData = active_placements.map((e) => [
          e.time,
          e.price,
          e.side,
        ]);
        setActivePlacements(activePlacementsData);
      } else {
        setActivePlacements([]);
      }
      setOrderMessages(messages && messages.length > 0 ? messages : []);
    }
  };

  const povSplicer = (splicee, timestamps, initialPovTruncate) => {
    const povSplicee = splicee.map((e) => e * 100);
    return timestamps.map((ts, index) => {
      if (index >= povSplicee.length) {
        return [ts, null];
      }
      // remove POV line based on discretion
      if (ts < initialPovTruncate[0] + Date.parse(initialPovTruncate[1])) {
        return [ts, null];
      }
      return [ts, povSplicee[index]];
    });
  };

  const timestampSplicer = (splicee, timestamps) => {
    return timestamps.map((ts, index) => {
      if (index >= splicee.length) {
        return [ts, null];
      }
      return [ts, splicee[index]];
    });
  };

  const targetSplicer = (target, timestamps) => {
    return timestamps.map((data, index) => {
      return [data, target];
    });
  };

  const parsePovData = (
    messageData,
    orderState,
    initialPovTruncate = undefined
  ) => {
    const { cumulative_pov, fills, timestamps, volume } = messageData;

    let slicedPov = [];
    let slicedFills = [];
    let slicedVolume = [];

    if (
      Object.keys(messageData).length === 0 ||
      !timestamps ||
      timestamps.length === 0
    ) {
      return;
    }

    if (cumulative_pov && cumulative_pov.length > 0) {
      slicedPov = povSplicer(cumulative_pov, timestamps, initialPovTruncate);
    } else if (!orderState.is_active) {
      showAlert({
        severity: 'warning',
        message: 'Participation rate and volume data not found.',
      });
    }

    if (fills.take && fills.make) {
      slicedFills = {
        take: timestampSplicer(fills.take, timestamps),
        make: timestampSplicer(fills.make, timestamps),
      };
    }

    if (fills.cross) {
      slicedFills.cross = timestampSplicer(fills.cross, timestamps);
    }

    if (volume) {
      slicedVolume = timestampSplicer(volume, timestamps);
    }

    setPovChartData({
      cumulativePov: slicedPov,
      fills: slicedFills,
      timestamps: messageData.timestamps,
      volume: slicedVolume,
    });
  };

  const calculatePovTruncate = (order_summary) => {
    const { schedule_discretion, time_start, orig_time_end } = order_summary;
    const timeDelta = Date.parse(orig_time_end) - Date.parse(time_start);
    return [schedule_discretion * timeDelta, time_start];
  };

  const loadOrderData = async (order_id) => {
    let orderData;
    try {
      orderData = await fetchOrderDetailData(order_id);
    } catch (e) {
      showAlert({
        severity: 'error',
        message: `Failed to fetch order details: ${e.message}`,
      });
      return null;
    }

    parseOrderData(orderData);
    return orderData;
  };

  useEffect(() => {
    let intervalId;

    const loadData = async () => {
      window.setChartType = (type) => {
        setChartType(type);
      };

      const order_id = uuid;
      const orderData = await loadOrderData(order_id);

      if (!orderData) {
        navigate('/');
        return false;
      }

      if (orderData.order_summary.is_simple) {
        navigate(`/simple_order/${order_id}`);
        return false;
      }

      try {
        const data = await getMarkoutData(order_id);
        if (data.error) {
          showAlert({ severity: 'error', message: data.error });
        } else {
          setMarkoutData(data.message);
        }
      } catch (e) {
        showAlert({
          severity: 'error',
          message: `Failed to fetch markout data: ${e.message}`,
        });
      }

      let povOrderChartData;
      try {
        povOrderChartData = await fetchPovOrderChartData(order_id);
      } catch (e) {
        showAlert({
          severity: 'error',
          message: `Failed to fetch participation rate chart data: ${e.message}`,
        });
        return false;
      }

      const povTruncate = calculatePovTruncate(orderData.order_summary);
      parsePovData(povOrderChartData, orderData.order_summary, povTruncate);

      if (orderData.order_ended) {
        return false;
      }

      return true;
    };

    let isMounted = true;
    let success = true;

    const pollData = async () => {
      while (isMounted && success) {
        success = await loadData();
        await new Promise((resolve) => {
          setTimeout(resolve, 2000);
        });
      }
    };

    pollData();

    return () => {
      // Stop polling when the component unmounts or success changes
      isMounted = false;
    };
  }, []);

  const renderDurationCharts = () => {
    if (Object.keys(analytics).length === 0) {
      return <div />;
    }

    return <FillOrderChart data={analytics} orderData={orderSummaryState} />;
  };

  const renderPovCharts = () => {
    // What the target volume should be
    const targetVolume = povChartData.volume.map((point) => {
      return [
        point[0],
        point[1] ? point[1] * Number(orderSummaryState.pov_target) : null,
      ];
    });
    // What the (actual) executed volume is
    const executedVolume = povChartData.timestamps.map((timestamp, index) => {
      const take = povChartData.fills.take[index]?.[1] || 0;
      const make = povChartData.fills.make[index]?.[1] || 0;
      const cross = povChartData.fills.cross?.[index]?.[1] || 0;

      const totalVolume = take + make + cross;
      return [timestamp, totalVolume || 0];
    });

    return (
      <Stack direction='column' height='100%' spacing={1}>
        <Box
          style={{
            height: '50%',
            position: 'relative',
            marginX: '8px',
            marginTop: '8px',
            marginBottom: '4px',
          }}
        >
          <PovMarketChart
            analytics={analytics}
            fills={povChartData.fills}
            orderData={orderSummaryState}
            origTimeEnd={orderSummaryState.orig_order_end}
            pov={povChartData.cumulativePov}
            povTarget={orderSummaryState.pov_target}
            povTargetLine={targetSplicer(
              orderSummaryState.pov_target * 100,
              povChartData.timestamps
            )}
            target={benchmarkState.interval_volume}
            timeStart={orderSummaryState.time_start}
          />
        </Box>
        <Box
          style={{
            height: '50%',
            position: 'relative',
            marginX: '8px',
            marginTop: '4px',
            marginBottom: '8px',
          }}
        >
          <MarketVolumeChart
            executedVolume={executedVolume}
            origTimeEnd={orderSummaryState.orig_order_end}
            timeStart={orderSummaryState.time_start}
            volume={povChartData.volume}
          />
        </Box>
      </Stack>
    );
  };

  const bidAskTimestamps = bidAskState.map((e) => e.timestamp);

  const renderCharts = () => {
    if (isPov) {
      return chartTrajectoryToggle ? renderDurationCharts() : renderPovCharts();
    }
    return chartTrajectoryToggle ? renderPovCharts() : renderDurationCharts();
  };

  return (
    <Grid container spacing={1} style={{ height: '100%' }}>
      <Grid style={{ height: '70%' }} xs={4}>
        <Stack
          direction='column'
          spacing={1}
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          <Card style={{ width: '100%', height: '90%' }}>
            <CardContent style={{ overflow: 'auto' }}>
              <OrderSummary
                analytics={analytics}
                chartTrajectoryToggle={chartTrajectoryToggle}
                orderId={uuid}
                OrderSummaryData={orderSummaryState}
                povChartType={isPov}
                setChartTrajectoryToggle={setChartTrajectoryToggle}
                showAlert={showAlert}
              />
            </CardContent>
          </Card>
          <Card style={{ width: '100%', height: '10%' }}>
            <CardContent style={{ height: '100%' }}>
              <OrderActions
                loadOrderData={loadOrderData}
                OrderSummaryData={orderSummaryState}
                showAlert={showAlert}
              />
            </CardContent>
          </Card>
        </Stack>
      </Grid>
      <Grid style={{ height: '70%' }} xs={8}>
        <Stack direction='column' spacing={1} style={{ height: '100%' }}>
          <Card style={{ width: '100%', height: '60%', padding: 0 }}>
            <CardContent style={{ height: '97%' }}>
              {renderCharts()}
            </CardContent>
          </Card>
          <Card style={{ width: '100%', height: '40%' }}>
            <CardContent>
              <Box
                style={{
                  height: '100%',
                  position: 'relative',
                  marginLeft: isPov ? '2%' : null,
                }}
              >
                <BidAskChart
                  activePlacements={activePlacements}
                  aggroFillState={aggroFillState}
                  analytics={analytics}
                  askState={bidAskState.map((e) => [e.timestamp, e.best_ask])}
                  avgPriceLine={targetSplicer(
                    Number(benchmarkState.executed_price),
                    bidAskTimestamps
                  )}
                  bidState={bidAskState.map((e) => [e.timestamp, e.best_bid])}
                  crossFillState={crossFillState}
                  isPov={isPov}
                  limitHistory={
                    orderSummaryState.limit_price ? limitHistory : null
                  }
                  orderData={orderSummaryState}
                  orderStats={{
                    time_start: orderSummaryState.time_start,
                    orig_time_end: orderSummaryState.orig_order_end,
                    time_end: orderSummaryState.time_end,
                    executed_price: orderSummaryState.executed_price,
                    vwap: benchmarkState.vwap,
                  }}
                  passiveFillState={passiveFillState}
                  vwapLine={targetSplicer(
                    Number(benchmarkState.vwap),
                    bidAskTimestamps
                  )}
                />
              </Box>
            </CardContent>
          </Card>
        </Stack>
      </Grid>

      {/* Bottom row, first third */}
      <Grid style={{ height: '30%' }} xs={4}>
        <OrderBenchmarks benchmarkData={benchmarkState} key={benchmarkState} />
      </Grid>

      {/* Bottom row, middle third */}
      <Grid style={{ height: '30%' }} xs={4}>
        <Card style={{ width: '100%', height: '100%' }}>
          <CardContent sx={{ height: '100%' }}>
            <div
              style={{
                height: '100%',
                position: 'relative',
                marginLeft: isPov ? '2%' : null,
              }}
            >
              <MarkoutGraph data={markoutData} /> {/* Render Markout Chart */}
            </div>
          </CardContent>
        </Card>
      </Grid>

      {/* Bottom row, last third */}
      <Grid style={{ height: '30%' }} xs={4}>
        <OrderMessages orderMessages={orderMessages} />
      </Grid>
    </Grid>
  );
}

export default OrderDetailsPage;
