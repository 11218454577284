
import { Stack } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { calculateDurationDisplay, smartRound } from '../../util';
import {
  StrategyParamsParsed,
  StyledBorderTableCell,
  StyledBorderTopTableCell,
  StyledHeaderTableCell
} from './util';

const formatInitialValue = (val) => {
  if (!val) {
    return '';
  }

  return `${smartRound(Number(val))} `;
}

function CollapsedRow({row, open, dashboardView}){
  return (
    <Collapse in={open} >
      <Stack direction='row' style={{ whiteSpace: 'nowrap', paddingTop: '10px', paddingBottom: '10px' }}>
        <Stack direction='column' style={{ whiteSpace: 'nowrap', marginLeft: '40px'}}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderTableCell
                  align='left'
                  key="duration"
                  style={{ width: 190 }}
                >
                  Target Quantity
                </StyledHeaderTableCell>
                <StyledHeaderTableCell
                  align='left'
                  key="target quantity"
                  style={{ width: 190 }}
                >
                  Executed Quantity
                </StyledHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledBorderTableCell>
                  { row.buy_token_amount ?
                    `${formatInitialValue(row.buy_token_amount)} ${
                      row.market_type !== 'option' ? row.buy_token : 'Contracts'
                    }` :
                    `${formatInitialValue(row.sell_token_amount)} ${
                      row.market_type !== 'option' ? row.sell_token : 'Contracts'
                    }`
                  }
                </StyledBorderTableCell>
                <StyledBorderTableCell>
                  {
                    row.buy_token_amount ?
                      `${Number(row.executed_buy_qty).toFixed(3)}` :
                      `${Number(row.executed_qty).toFixed(3)}`
                  } { row.buy_token_amount ? row.buy_token : row.sell_token }
                </StyledBorderTableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table size="small" >
            <TableHead>
              <TableRow>
                <StyledHeaderTableCell
                  align='left'
                  key="avg exec price"
                  style={{ width: 190 }}
                >
                  Executed Price
                </StyledHeaderTableCell>
                <StyledHeaderTableCell
                  align='left'
                  key='executed qty'
                  style={{ width: 190 }}
                >
                  Executed Notional
                </StyledHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledBorderTableCell>
                  ${smartRound(Number(row.executed_price))}
                </StyledBorderTableCell>
                <StyledBorderTableCell>
                  ${smartRound(Number(row.executed_notional))}
                </StyledBorderTableCell>
              </TableRow>
            </TableBody>
          </Table>
          { row.order_condition_normal !== '' ?
            <Table size="small" >
              <TableHead>
                <TableRow>
                  <StyledBorderTopTableCell
                    align='left'
                    key="condition"
                    style={{ minWidth: 200, paddingTop: '5px', paddingBottom: '10px'}}
                  >
                    Condition
                  </StyledBorderTopTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(row.order_variable_normal &&
                Object.keys(row.order_variable_normal).length > 0 && row.order_condition_vars ) ?
                  <>
                    <TableRow>
                      <StyledBorderTableCell>
                        {row.order_condition_normal}
                      </StyledBorderTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledBorderTableCell>
                        <Stack direction={row}>
                          <div>
                            { Object.entries(row.order_variable_normal).map(([k, v]) => {
                              return (
                                <li key={`clause ${k}`}>{k}: {v} = {row.order_condition_vars[k][1]} </li>
                              );
                            })}
                          </div>
                        </Stack>
                      </StyledBorderTableCell>
                    </TableRow>
                  </>
                  :
                  <TableRow>
                    <StyledBorderTableCell>
                      {row.order_condition_normal}
                    </StyledBorderTableCell>
                    <StyledBorderTableCell />
                    <StyledBorderTableCell />
                  </TableRow>
                }
              </TableBody>
            </Table> : null}
        </Stack>
        <Stack direction='column' style={{ whiteSpace: 'nowrap'}}>
          <Table size="small" >
            <TableHead>
              <TableRow>
                <StyledHeaderTableCell
                  align='left'
                  key="arrival_cost"
                  style={{ width: '150px' }}
                >
                  Duration
                </StyledHeaderTableCell>
                <StyledHeaderTableCell
                  align='left'
                  key="vwap_cost"
                  style={{ width: '150px' }}
                >
                  VWAP Cost
                </StyledHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledBorderTableCell>
                  {calculateDurationDisplay(row.duration)}
                </StyledBorderTableCell>
                <StyledBorderTableCell>
                  {row.benchmarks && Object.keys(row.benchmarks).length !== 0 ?
                    <div className={row.benchmarks.vwap_cost > 0 ? 'text-danger' : 'text-success'}>
                      {Number(row.benchmarks.vwap_cost).toFixed(4)} bps
                    </div> : 'N/A'}
                </StyledBorderTableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table size="small" >
            <TableHead>
              <TableRow>
                <StyledHeaderTableCell
                  align='left'
                  key="pov"
                  style={{ width: dashboardView ? '227px' : '250px' }}
                >
                  Participation Rate
                </StyledHeaderTableCell>
                <StyledHeaderTableCell
                  align='left'
                  key="arrival cost"
                  style={{ width: '250px' }}
                >
                  Arrival Cost
                </StyledHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledBorderTableCell>
                  {row.benchmarks && Object.keys(row.benchmarks).length !== 0 ?
                    <div className={row.benchmarks.pov}>
                      {Number(row.benchmarks.pov).toFixed(4)}%
                    </div> : 'N/A'}
                </StyledBorderTableCell>
                <StyledBorderTableCell>
                  { row.benchmarks && Object.keys(row.benchmarks).length !== 0 ?
                    <div className={row.benchmarks.arrival_cost > 0 ? 'text-danger' : 'text-success'}>
                      {Number(row.benchmarks.arrival_cost).toFixed(4)} bps
                    </div> : 'N/A'}
                </StyledBorderTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
        <Stack direction='column' style={{ whiteSpace: 'nowrap'}}>
          <Table size="small" >
            <TableHead>
              <TableRow>
                <StyledHeaderTableCell
                  align='left'
                  key="strategy_params"
                  style={{ width: 400 }}
                >
                Strategy Configurations
                </StyledHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledBorderTableCell>
                  <StrategyParamsParsed
                    useIBM
                    alpha_tilt={row.alpha_tilt}
                    engine_passiveness={row.engine_passiveness}
                    exposure_tolerance={row.exposure_tolerance}
                    pov_limit={row.pov_limit * 100}
                    pov_target={row.pov_target * 100}
                    schedule_discretion={row.schedule_discretion}
                    strategy_params={row.strategy_params}
                  />
                </StyledBorderTableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table size="small" >
            <TableHead>
              <TableRow>
                <StyledHeaderTableCell
                  align='left'
                  key="notes"
                  style={{ width: 400 }}
                >
                Notes
                </StyledHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledBorderTableCell>
                  {row.notes || 'N/A'}
                </StyledBorderTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </Stack>
    </Collapse>
  )
}

export default CollapsedRow