import React from 'react';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@emotion/react';
import DurationDropdown from '../../shared/fields/DurationDropdown';
import {
  AlphaTiltSlider,
  DiscretionSlider,
  ExposureToleranceSlider,
  PassivenessSlider,
} from "../../shared/fields/Sliders";
import {
  ConditionValidateField,
  StrategyParamSelect
} from '../../shared/fields/StrategyParamSelect';
import TrajectoryDropdown from '../../shared/fields/TrajectoryDropdown';

export default function ChainedOrdersSubmitForm({
  strategies, strategyParams,
  selectedStrategy, setSelectedStrategy,
  selectedStrategyParams, setSelectedStrategyParams,
  selectedDuration, setSelectedDuration,
  passiveness, setPassiveness,
  discretion, setDiscretion,
  exposureTolerance, setExposureTolerance,
  orderCondition, setOrderCondition,
  isOrderConditionValidated, setIsOrderConditionValidated,
  handleSubmit,
  showAlert, formState,
  alphaTilt, setAlphaTilt, submitLoading
}) {
  const theme = useTheme();
  const orderItems = formState.orders;

  const areOrderItemsValid = orderItems.every((item) => item.accounts.length > 0 && item.pair && item.qty);
  const enoughItems = orderItems.length >= 2;
  const orderConditionValid = !orderCondition || isOrderConditionValidated;
  const isReadyToSubmit = areOrderItemsValid && enoughItems && orderConditionValid;

  const handleStrategyParamChange = (event) => {
    setSelectedStrategyParams({
      ...selectedStrategyParams,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <form height='100%' onSubmit={handleSubmit}>
      <Stack direction='row' height='100%' spacing={5}>
        <Box style={{ width: '34%' }}>
          <Grid container spacing={1}>
            <Grid xs={12}>
              <TrajectoryDropdown
                setTrajectory={setSelectedStrategy}
                trajectories={strategies}
                trajectory={selectedStrategy}
              />
            </Grid>
            <Grid xs={12}>
              <DurationDropdown
                setValue={setSelectedDuration}
                size="medium"
                value={selectedDuration}
              />
            </Grid>
            <Grid xs={12}>
              <ConditionValidateField
                isOrderConditionValidated={isOrderConditionValidated}
                orderCondition={orderCondition}
                rows={2}
                setIsOrderConditionValidated={setIsOrderConditionValidated}
                setOrderCondition={setOrderCondition}
                showAlert={showAlert}
              />
            </Grid>
            <Grid xs={12}>
              {!submitLoading ? (
                <Button
                  fullWidth
                  disabled={!isReadyToSubmit}
                  sx={{ height: '40px' }}
                  type="submit"
                  variant="contained"
                >
                  <Typography color={!isReadyToSubmit ? 'grey' : 'text.offBlack'} variant='h6'>
                    Submit Chained Orders
                  </Typography>
                </Button>
              ) : (
                <Button
                  disabled
                  fullWidth
                  sx={{ height: '40px' }}
                  type="submit"
                  variant="contained"
                >
                  <CircularProgress size={20} />
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>

        <Box style={{ width: '33%', height: '100%' }}>
          <Stack spacing={0}>
            <ExposureToleranceSlider
              exposureTolerance={exposureTolerance} setExposureTolerance={setExposureTolerance}
              sx={{ marginBottom: '10px' }}
            />
            <PassivenessSlider passiveness={passiveness}
              setPassiveness={setPassiveness} sx={{ marginBottom: '10px' }} />
            <DiscretionSlider discretion={discretion} setDiscretion={setDiscretion} sx={{ marginBottom: '10px' }} />
            <AlphaTiltSlider alphaTilt={alphaTilt} setAlphaTilt={setAlphaTilt} sx={{ marginBottom: '10px' }} />
          </Stack>
        </Box>

        <Box sx={{ width: '33%', height: '100%' }}>
          <StrategyParamSelect
            handleStrategyParamChange={handleStrategyParamChange}
            selectedStrategyParams={selectedStrategyParams}
            strategyParams={strategyParams}
          />
        </Box>
      </Stack>
      <Stack direction="row" sx={{ width: '100%', marginTop: '0px' }}>
        <Typography sx={{ width: '120px', marginRight: '20px' }}>Priority</Typography>
        <Typography sx={{ width: '250px', marginRight: '20px' }}>Account</Typography>
        <Typography sx={{ width: '220px', marginRight: '10px' }}>Pair</Typography>
        <Typography sx={{ width: '210px', marginRight: '3px' }}>Quantity</Typography>
        <Typography sx={{ width: '180px', marginRight: '3px' }}>Limit Price</Typography>
        <Typography sx={{ width: '120px', marginRight: '20px' }}>Side</Typography>
      </Stack>
    </form>
  );
}