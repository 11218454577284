import React, {useEffect, useState, useContext} from 'react';
import { useAtom } from 'jotai';
import { Button, CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { loadOrderTemplate, getValues } from '../formUtil';
import { useUserMetadata } from '../context/UserMetadataProvider';
import { useBaseForm } from '../../pages/dashboard/orderEntry/hooks/useBaseForm';
import { ErrorContext } from "../context/ErrorProvider";
import { buySellToBaseQuote } from './util';

export default function EditTableOrder(
  { dashboardView = true, FormAtoms, loading, setLoading, setOpen, data, isResubmit}) {

  const [, setSelectedStrategy] = useAtom(FormAtoms.selectedStrategyAtom);
  const [, setTrajectory] = useAtom(FormAtoms.trajectoryAtom);
  const [, setSelectedStrategyParams] = useAtom(FormAtoms.selectedStrategyParamsAtom);
  const [, setSelectedDuration] = useAtom(FormAtoms.selectedDurationAtom);
  const [, setPosSide] = useAtom(FormAtoms.posSideAtom);
  const [, setUpdatePairLeverage] = useAtom(FormAtoms.updatePairLeverageAtom);
  const [, setLimitPrice] = useAtom(FormAtoms.limitPriceAtom);
  const [, setStopPrice] = useAtom(FormAtoms.stopPriceAtom);
  const [, setPassiveness] = useAtom(FormAtoms.passivenessAtom);
  const [, setDiscretion] = useAtom(FormAtoms.discretionAtom);
  const [, setAlphaTilt] = useAtom(FormAtoms.alphaTiltAtom);
  const [, setNotes] = useAtom(FormAtoms.notesAtom);
  const [, setOrderCondition] = useAtom(FormAtoms.orderConditionAtom);
  const [, setPovTarget] = useAtom(FormAtoms.povTargetAtom);
  const [, setPovLimit] = useAtom(FormAtoms.povLimitAtom);
  const [, setSelectedPair] = useAtom(FormAtoms.selectedPairAtom);
  const [, setSelectedAccounts] = useAtom(FormAtoms.selectedAccountsAtom);
  const [, setSelectedSide] = useAtom(FormAtoms.selectedSideAtom);
  const [, setTargetTime] = useAtom(FormAtoms.targetTimeAtom);
  const [, setMaxOTCPercentage] = useAtom(FormAtoms.maxOtcPercentageAtom);
  const [, setIsOOLEnabled] = useAtom(FormAtoms.isOOLEnabledAtom);
  const [initialLoadValue] = useAtom(FormAtoms.initialLoadValueAtom);

  const { setHasError, setErrorContent } = useContext(ErrorContext);
  const navigate = useNavigate();

  const {
    accounts,
    strategies,
    trajectories,
    tokenPairs,
  } = initialLoadValue;


  const showAlert = ({ severity, message }) => {
    setErrorContent({ severity, message });
    setHasError(true);
  }
  const { user } = useUserMetadata()

  const { handleBaseQuoteFields } = useBaseForm({showAlert, FormAtoms})
  const { handleBaseQtyOnChange, handleQuoteQtyOnChange } = handleBaseQuoteFields

  const handleEditOrder = async (row) => {

    let rowData = row
    if(rowData.pov_target){
      rowData.pov_target *= 100
    }
    if(!isResubmit){
      rowData = buySellToBaseQuote(row)
    }

    const setters = getValues({
      setSelectedAccounts, setSelectedSide, setSelectedPair, setPosSide,
      handleBaseChange: handleBaseQtyOnChange,
      handleQuoteChange: handleQuoteQtyOnChange,

      setPovLimit, setPovTarget, setOrderCondition, setAlphaTilt, setDiscretion, setPassiveness,
      setSelectedStrategy, setTrajectory, setSelectedStrategyParams, setSelectedDuration, setUpdatePairLeverage,

      setTargetTime, setStopPrice, setLimitPrice, setIsOOLEnabled, setNotes, setLoading, setMaxOTCPercentage })

    await loadOrderTemplate(rowData, () => {}, setLoading, setters, tokenPairs,
      strategies, trajectories, user, accounts).then(() => {

      setLoading(false)
      setOpen(false)

    })

    if (!dashboardView) {
      navigate('/', { state: {from: 'EditTableOrder'}});
    }
  }
  const buttonStyle = {
    marginTop: 1,
    marginBottom: 2,
    marginLeft: 'auto',
    marginRight: 'auto',
  }

  if (loading) {
    return <Button
      disabled
      sx={{ ...buttonStyle, width:'200px' }}
      variant="contained"
    >
      <CircularProgress size={20} />
    </Button>
  }

  return (
    <Button
      color='primary'
      sx={{ ...buttonStyle, width: '200px' }}
      variant='contained'
      onClick={() => handleEditOrder(data)}
    >
         Edit
    </Button>
  )
}