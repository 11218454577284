import { useTheme } from '@emotion/react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { useAtom } from 'jotai';
import React, { useContext, useEffect, useState } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { getOptionData, getPairPrice } from '../../apiServices';
import { ErrorContext } from '../../shared/context/ErrorProvider';
import {
  StyledHeaderTableCell,
  StyledHeaderTableCellWithLine,
  StyledIBMTypography,
  StyledStrikeTableCell,
  StyledTableOptionCell,
} from '../../shared/orderTable/util';
import {
  exchangeColumnMapping,
  exchangeQuoteMapping,
  FocusableDivider,
  displayColorTableCell,
  displayDefaultTableCell,
  convertBinanceDateToTaas,
} from './util';

const getColumns = (exchangeName = 'Binance') => {
  const newExchangeName = exchangeName === null ? 'Binance' : exchangeName;

  const exchangeColumns = exchangeColumnMapping[newExchangeName];
  return {
    columns: [
      // { id: exchangeColumns.bidIV, label: 'Bid IV',
      //   minWidth: 30,  align: 'right', format: (value) => `${(Number(value) * 100).toFixed(2)}%` },
      {
        id: exchangeColumns.bid,
        ivid: exchangeColumns.bidIV,
        label: 'Bid / IV',
        minWidth: 80,
        align: 'right',
      },
      // { id: exchangeColumns.markIV, label: 'Mark IV',
      //   minWidth: 30,  align: 'right',  format: (value) => `${(Number(value) * 100).toFixed(2)}%`},
      {
        id: exchangeColumns.markPrice,
        ivid: exchangeColumns.markIV,
        label: 'Mark / IV',
        minWidth: 80,
        align: 'right',
      },
      {
        id: exchangeColumns.ask,
        ivid: exchangeColumns.askIV,
        label: 'Ask / IV',
        minWidth: 80,
        align: 'right',
      },
      // { id: exchangeColumns.askIV, label: 'Ask IV',
      //   minWidth: 30,  align: 'right',  format: (value) => `${(Number(value) * 100).toFixed(2)}%`},
      {
        id: exchangeColumns.delta,
        label: 'Delta',
        minWidth: 30,
        align: 'right',
      },
      {
        id: exchangeColumns.theta,
        label: 'Theta',
        minWidth: 30,
        align: 'right',
      },
      {
        id: exchangeColumns.gamma,
        label: 'Gamma',
        minWidth: 30,
        align: 'right',
      },
      { id: exchangeColumns.vega, label: 'Vega', minWidth: 30, align: 'right' },
    ],
    strikeColumn: {
      id: 'strike_price',
      label: 'Strike',
      minWidth: 30,
      align: 'center',
      format: (val) =>
        Number(val) < 10 ? Number(val).toFixed(3) : Number(val).toFixed(0),
    },
  };
};

function DisplayRow({ columns, row, rowOnClick, pairPrice, theme }) {
  let backgroundColor = theme.palette.options.default;
  if (row.type === 'P' && row.strike_price > pairPrice) {
    backgroundColor = theme.palette.options.put;
  } else if (row.type === 'C' && row.strike_price < pairPrice) {
    backgroundColor = theme.palette.options.call;
  }

  return (
    <TableRow
      key={`${row.instId || row.id}_row`}
      style={{ backgroundColor }}
      onClick={() => rowOnClick(row)}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = theme.palette.options.highlight;
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = backgroundColor;
      }}
    >
      {columns.map((column) => {
        if (column.id === 'ask') {
          return displayColorTableCell(
            column,
            row[column.id],
            row[column.ivid],
            {
              minWidth: column.minWidth,
              width: column.width || undefined,
              backgroundColor: 'inherit', // Inherit background color from TableRow
            },
            theme.palette.options.ask,
            theme.palette.grey.light
          );
        }
        if (column.id === 'bid') {
          return displayColorTableCell(
            column,
            row[column.id],
            row[column.ivid],
            {
              minWidth: column.minWidth,
              width: column.width || undefined,
              backgroundColor: 'inherit', // Inherit background color from TableRow
            },
            theme.palette.options.bid,
            theme.palette.grey.light
          );
        }
        if (column.id === 'markPrice') {
          return displayColorTableCell(
            column,
            row[column.id],
            row[column.ivid],
            {
              minWidth: column.minWidth,
              width: column.width || undefined,
              backgroundColor: 'inherit', // Inherit background color from TableRow
            },
            theme.palette.text.primary,
            theme.palette.grey.light
          );
        }
        return displayDefaultTableCell(
          column,
          row[column.id] && row[column.id],
          {
            minWidth: column.minWidth,
            width: column.width || undefined,
            backgroundColor: 'inherit', // Inherit background color from TableRow
          }
        );
      })}
    </TableRow>
  );
}

const sortDateOptions = (dateOptionsToSort) => {
  return Object.keys(dateOptionsToSort).sort((a, b) => {
    // Convert the date strings to Date objects
    const dateA = new Date(a.replace(/\./g, '-'));
    const dateB = new Date(b.replace(/\./g, '-'));

    // Compare the Date objects
    return dateA - dateB;
  });
};

function OptionPriceChart({ FormAtoms }) {
  const theme = useTheme();

  const [loading, setLoading] = useState(true);

  const [selectedPair, setSelectedPair] = useAtom(FormAtoms.selectedPairAtom);
  const [selectedAccounts] = useAtom(FormAtoms.selectedAccountsAtom);
  const [initialLoadValue] = useAtom(FormAtoms.initialLoadValueAtom);
  const [optionData, setOptionData] = useAtom(FormAtoms.optionDataAtom);

  const [optionsMap, setOptionsMap] = useState({});
  const [optionBaseValue, setOptionBaseValue] = useState('');
  const [optionDateValue, setOptionDateValue] = useState('');

  const [pairPrice, setPairPrice] = useState(null);
  const [pairPriceLoading, setPairPriceLoading] = useState(false);

  const [dateOptions, setDateOptions] = useState([]);
  const validExchanges = ['Binance', 'OKX'];
  const [exchangeName, setExchangeName] = useState(validExchanges[1]);

  const { setHasError, setErrorContent } = useContext(ErrorContext);

  const showAlert = ({ severity, message }) => {
    setErrorContent({ severity, message });
    setHasError(true);
  };
  const { columns, strikeColumn } = getColumns(exchangeName);

  const { options, accounts } = initialLoadValue;

  const rowOnClick = (row) => {
    if (row.type !== 'C' && row.type !== 'P') {
      return;
    }

    const base = exchangeName === 'Binance' ? row.asset : row.base;

    setSelectedPair(
      optionsMap[base][convertBinanceDateToTaas(row.expiry_date)][
        row.strike_price
      ][row.type === 'C' ? 'CALL' : 'PUT']
    );
  };

  const getUnderlyingPrice = async () => {
    setPairPriceLoading(true);

    const exchangeQuote = exchangeQuoteMapping[exchangeName];
    const baseQuote = `${optionBaseValue}-${exchangeQuote}`;

    try {
      const result = await getPairPrice(baseQuote, exchangeName);
      setPairPrice(result[baseQuote]);
      setPairPriceLoading(false);
      return result[baseQuote];
    } catch (e) {
      showAlert({
        message: `Could not retrieve underyling price: ${e}`,
        severity: 'error',
      });
      setPairPriceLoading(false);
    }
    return null;
  };

  const loadBinanceOptionData = async (exchange, underlying, date) => {
    let data;
    setLoading(true);
    try {
      data = await getOptionData(exchange, underlying, date);
      const flatData = Object.values(data).map((x, i) => {
        if (x[0].type === 'C' && x[1].type === 'P') {
          return [x[0], x[1]];
        }
        return [x[1], x[0]];
      });
      setOptionData(flatData);
      setLoading(false);
    } catch (e) {
      showAlert({
        message: `Could not retrieve binance option data: ${e}`,
        severity: 'error',
      });
      setLoading(false);
    }
  };

  const loadOKXOptionData = async (exchange, underlying, date) => {
    let data;
    setLoading(true);
    try {
      data = await getOptionData(exchange, underlying, date);

      const flatData = Object.values(data).map((x, i) => {
        if (x[0].type === 'C' && x[1].type === 'P') {
          return [x[0], x[1]];
        }
        return [x[1], x[0]];
      });
      setOptionData(flatData);
      setLoading(false);
    } catch (e) {
      showAlert({
        message: `Could not retrieve okx option data: ${e}`,
        severity: 'error',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (optionsMap && Object.keys(optionsMap).length > 0 && optionBaseValue) {
      const sortedDateStrings = sortDateOptions(optionsMap[optionBaseValue]);
      setDateOptions(sortedDateStrings);
      getUnderlyingPrice();
      setOptionData({});
      setOptionDateValue(sortedDateStrings[0]);
    }
  }, [optionBaseValue]);

  useEffect(() => {
    // if ( exchangeName !== selectedAccounts)
    setOptionBaseValue(null);
    setOptionDateValue(null);
    setOptionData({});
  }, [exchangeName]);

  useEffect(() => {
    if (
      optionsMap &&
      Object.keys(optionsMap).length > 0 &&
      optionBaseValue &&
      optionDateValue
    ) {
      if (exchangeName === 'Binance') {
        loadBinanceOptionData(exchangeName, optionBaseValue, optionDateValue);
      }
      if (exchangeName === 'OKX') {
        loadOKXOptionData(
          exchangeName,
          `${optionBaseValue}-USD`,
          optionDateValue
        );
      }
    }
    if (optionDateValue === null || optionDateValue === '') {
      setOptionData({});
    }
  }, [optionDateValue]);

  const baseOptions = Object.keys(optionsMap);

  useEffect(() => {
    if (options && Object.keys(options).length > 0) {
      setOptionsMap(options[exchangeName] || {});
      if (options[exchangeName]) {
        const baseValue = Object.keys(options[exchangeName])[0];
        setOptionBaseValue(baseValue);
      }
    }
  }, [options, exchangeName]);

  return (
    <>
      <Paper
        sx={{
          position: 'relative',
          height: '52px',
          zIndex: 50,
          backgroundColor: theme.palette.card.main,
        }}
        variant='outlined'
      >
        <Stack alignItems='center' direction='row' height='100%' spacing={2}>
          <FormControl
            size='small'
            style={{
              marginLeft: '16px',
            }}
            sx={{
              width: '15%',
            }}
          >
            <InputLabel id='exchange-label'>Exchange</InputLabel>
            <Select
              defaultValue=''
              id='prediction-exchange'
              label='Exchange'
              labelId='exchange-label'
              value={
                exchangeName === undefined ||
                exchangeName === null ||
                validExchanges.length === 0
                  ? ''
                  : exchangeName
              }
              onChange={(e) => {
                setExchangeName(e.target.value);
              }}
            >
              {validExchanges.map((exchange) => (
                <MenuItem key={exchange} value={exchange}>
                  {exchange}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Autocomplete
            disablePortal
            defaultValue=''
            disabled={!(optionsMap && Object.keys(optionsMap).length) > 0}
            options={baseOptions}
            renderInput={(params) => {
              return <TextField {...params} label='Base' size='small' />;
            }}
            style={{
              marginLeft: '16px',
            }}
            sx={{
              width: '15%',
            }}
            value={
              optionBaseValue === undefined ||
              optionBaseValue === null ||
              baseOptions.length === 0
                ? ''
                : optionBaseValue
            }
            onChange={(e, newValue) => {
              setOptionDateValue(null);
              setOptionBaseValue(newValue);
            }}
          />
          <Autocomplete
            disablePortal
            defaultValue=''
            disabled={
              !(optionsMap && Object.keys(optionsMap).length > 0) ||
              pairPriceLoading
            }
            options={dateOptions}
            renderInput={(params) => {
              return <TextField {...params} label='Expiry Date' size='small' />;
            }}
            sx={{
              width: '15%',
            }}
            value={
              optionDateValue === undefined ||
              optionDateValue === null ||
              dateOptions.length === 0
                ? ''
                : optionDateValue
            }
            onChange={(e, newValue) => {
              setOptionDateValue(newValue);
            }}
          />
        </Stack>
      </Paper>
      <CardContent
        style={{
          height: 'calc(100% - 72px)',
        }}
        sx={{
          width: '99%',
          overflowY: 'auto',
          paddingTop: '0px',
        }}
      >
        {loading || pairPriceLoading ? (
          <Box
            alignItems='center'
            display='flex'
            height='100%'
            justifyContent='center'
          >
            <ScaleLoader color='#FFFFFF' />
          </Box>
        ) : (
          <Stack direction='row' spacing='0'>
            <div
              style={{
                height: 'auto',
                flex: 1,
                paddingTop: '0px',
                paddingBottom: '24px',
              }}
            >
              <Table stickyHeader aria-label='sticky table' size='small'>
                <TableHead>
                  <TableRow>
                    <StyledHeaderTableCell
                      align='center'
                      colSpan={columns.length}
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      Calls
                    </StyledHeaderTableCell>
                  </TableRow>
                  <TableRow>
                    {columns.toReversed().map((column) => (
                      <StyledHeaderTableCellWithLine
                        align={column.align}
                        key={`${column.id}calls headers`}
                        style={{
                          top: 26.5,
                          minWidth: column.minWidth,
                          width: column.width || undefined,
                        }}
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {column.label}
                      </StyledHeaderTableCellWithLine>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {optionData &&
                    optionData.length > 0 &&
                    optionData.map((row, index) => {
                      const callRow = row[0];
                      const nextRow =
                        index < optionData.length - 1
                          ? optionData[index + 1][0]
                          : null;
                      const key = callRow.instId || callRow.id || index;

                      if (
                        nextRow &&
                        nextRow.strike_price > pairPrice &&
                        callRow.strike_price < pairPrice
                      ) {
                        return (
                          <>
                            <DisplayRow
                              columns={columns.toReversed()}
                              key={`${key}calls`}
                              pairPrice={pairPrice}
                              row={callRow}
                              rowOnClick={rowOnClick}
                              theme={theme}
                            />
                            <FocusableDivider theme={theme} />
                          </>
                        );
                      }
                      return (
                        <DisplayRow
                          columns={columns.toReversed()}
                          key={`${key}calls`}
                          pairPrice={pairPrice}
                          row={callRow}
                          rowOnClick={rowOnClick}
                          theme={theme}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </div>
            <div
              style={{
                flex: '0 1 auto',
                paddingTop: '0px',
                paddingBottom: '24px',
              }}
            >
              <Box sx={{ maxHeight: '100%' }}>
                <Table stickyHeader aria-label='sticky table' size='small'>
                  <TableHead>
                    <TableRow>
                      <StyledHeaderTableCell
                        align='center'
                        colSpan={columns.length}
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          height: '20.5px',
                          top: 0,
                        }}
                      >
                        {}
                      </StyledHeaderTableCell>
                    </TableRow>

                    <TableRow>
                      <StyledHeaderTableCellWithLine
                        align={strikeColumn.align}
                        key={strikeColumn.id}
                        style={{
                          top: '26.5px',
                          minWidth: strikeColumn.minWidth,
                          width: strikeColumn.width || undefined,
                        }}
                      >
                        {strikeColumn.label}
                      </StyledHeaderTableCellWithLine>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {optionData &&
                      optionData.length > 0 &&
                      optionData.map((row, index) => {
                        const newRow = row[0];
                        const strikeRow = (
                          <TableRow
                            key={`${newRow.instId || newRow.id} strike`}
                            sx={{ height: '49.59px' }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor =
                                'lightgray';
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = '';
                            }}
                          >
                            {displayDefaultTableCell(
                              strikeColumn,
                              newRow[strikeColumn.id],
                              { minWidth: strikeColumn.minWidth },
                              StyledStrikeTableCell
                            )}
                          </TableRow>
                        );

                        const nextRow =
                          index < optionData.length - 1
                            ? optionData[index + 1][0]
                            : null;
                        if (
                          nextRow &&
                          nextRow.strike_price > pairPrice &&
                          newRow.strike_price < pairPrice
                        ) {
                          return (
                            <>
                              {strikeRow}
                              <TableRow>
                                <TableCell
                                  colSpan='100%'
                                  style={{ padding: 0 }}
                                >
                                  <Divider
                                    sx={{
                                      height: '2px',
                                      backgroundColor:
                                        theme.palette.primary.main,
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        }

                        return strikeRow;
                      })}
                  </TableBody>
                </Table>
              </Box>
            </div>
            <div
              style={{
                height: 'auto',
                flex: 1,
                paddingTop: '0px',
                paddingBottom: '24px',
              }}
            >
              <Box sx={{ maxHeight: '100%' }}>
                <Table stickyHeader aria-label='sticky table' size='small'>
                  <TableHead>
                    <TableRow>
                      <StyledHeaderTableCell
                        align='center'
                        colSpan={columns.length}
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        Puts
                      </StyledHeaderTableCell>
                    </TableRow>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledHeaderTableCellWithLine
                          align={column.align}
                          key={`${column.id}puts headers`}
                          style={{
                            top: 26.5,
                            minWidth: column.minWidth,
                            width: column.width || undefined,
                          }}
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {column.label}
                        </StyledHeaderTableCellWithLine>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {optionData &&
                      optionData.length > 0 &&
                      optionData.map((row, index) => {
                        const putRow = row[1];
                        const nextRow =
                          index < optionData.length - 1
                            ? optionData[index + 1][0]
                            : null;
                        const key = putRow.instId || putRow.id || index;

                        if (
                          nextRow &&
                          nextRow.strike_price > pairPrice &&
                          putRow.strike_price < pairPrice
                        ) {
                          return (
                            <>
                              <DisplayRow
                                columns={columns}
                                key={`${key}puts`}
                                pairPrice={pairPrice}
                                row={putRow}
                                rowOnClick={rowOnClick}
                                theme={theme}
                              />
                              <TableRow>
                                <TableCell
                                  colSpan='100%'
                                  style={{ padding: 0 }}
                                >
                                  <Divider
                                    sx={{
                                      height: '2px',
                                      backgroundColor:
                                        theme.palette.primary.main,
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        }
                        return (
                          <DisplayRow
                            columns={columns}
                            key={`${key}puts`}
                            pairPrice={pairPrice}
                            row={putRow}
                            rowOnClick={rowOnClick}
                            theme={theme}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
              </Box>
            </div>
          </Stack>
        )}
      </CardContent>
    </>
  );
}

export default OptionPriceChart;
