import React from 'react';
import { Box } from '@mui/material';
import TradeDetailsTable from './TradeDetailsTable';
import { AccountsProvider } from '../../../shared/context/AccountsProvider';

function TradeDetailsPageContent() {
  return <TradeDetailsTable />;
}

export default function TradeDetailsPage() {
  return (
    <AccountsProvider>
      <TradeDetailsPageContent />
    </AccountsProvider>
  );
}
