import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Link,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Stack,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
} from '@mui/material';
import { ArrowDownward, RemoveCircleOutline } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import {
  BASEURL,
  capitalizeFirstLetter,
  calculateDurationDisplay,
  smartRound,
  chooseKeys,
  isEmpty,
  titleCase,
  getTrajectoryName,
  getStrategyName,
} from '../../../../util';
import ProgressBar from '../../../../shared/fields/ProgressBar/ProgressBar';
import {
  StyledBorderTableCell,
  StyledHeaderTableCell,
} from '../../../../shared/orderTable/util';

function ChainChart({
  orders_in_chain,
  orderView = true,
  handleDeleteOnClick,
  handlePriorityChange,
  strategies,
  trajectories,
  superStrategies,
  orderBenchmark,
}) {
  const maxPriority = !isEmpty(orders_in_chain)
    ? orders_in_chain.reduce((acc, order) => {
        if (order.priority > acc) return order.priority;
        return acc;
      }, 0) + 1
    : 1;

  const theme = useTheme();

  if (!orders_in_chain) {
    return (
      <Box align='center' mt={2}>
        <Typography color='error' variant='body1'>
          Order data is not available.
        </Typography>
      </Box>
    );
  }

  const groupedOrders = orders_in_chain.reduce((acc, orderData) => {
    const { priority } = orderData;
    if (!acc[priority]) {
      acc[priority] = [];
    }
    acc[priority].push(orderData);
    return acc;
  }, {});

  const sortedPriorities = Object.keys(groupedOrders).sort((a, b) => a - b);

  const getStatusBorderColor = (status) => {
    switch (status) {
      case 'SUBMITTED':
      case 'ACTIVE':
        return theme.palette.primary.main;
      case 'PAUSED':
        return theme.palette.grey.main;
      case 'FINISHER':
        return theme.palette.color2.light;
      case 'COMPLETE':
        return theme.palette.color2.main;
      case 'CANCELED':
      case 'PENDING_CANCELED':
        return theme.palette.charts.red;
      case 'SCHEDULED':
        return theme.palette.secondary.main;
      default:
        return theme.palette.grey.main;
    }
  };

  const splitByLastDash = (str) => {
    const lastIndex = str.lastIndexOf('-');
    if (lastIndex === -1) return [str];
    return [str.slice(0, lastIndex), str.slice(lastIndex + 1)];
  };

  const targetQtyDisplay = (orderData) => {
    const qty = orderData?.base_asset_qty
      ? orderData?.base_asset_qty
      : orderData?.quote_asset_qty;

    const splitPair = splitByLastDash(orderData.pair);

    const token = orderData?.base_asset_qty ? splitPair[0] : splitPair[1];

    return `${qty} ${token}`;
  };

  const orderViewPriorityCard = (order, colSize) => {
    const relevantBenchmarks = [
      'notional',
      'vwap_cost',
      'arrival_cost',
      'fee_notional',
      'pov',
    ];
    const specifiedOrderBenchmark = orderBenchmark[order.id] || {};

    const parsedBenchmarkData = relevantBenchmarks.reduce((acc, e) => {
      const value = specifiedOrderBenchmark[e];
      switch (value) {
        case 'pov':
          acc[e] = value ? `${Number(value).toFixed(2)}%` : 'N/A';
          return acc;
        case 'notional':
        case 'fee_notional':
          acc[e] =
            value !== undefined ? `$${smartRound(Number(value))}` : 'N/A';
          return acc;
        default:
          acc[e] = value !== undefined ? `${smartRound(Number(value))}` : 'N/A';
          return acc;
      }
    }, {});

    const { notional, vwap_cost, arrival_cost, fee_notional, pov } =
      parsedBenchmarkData;

    return (
      <Grid item key={order.id} xs={colSize}>
        <Link
          color='inherit'
          href={`${BASEURL}/order/${order.id}`}
          target='_blank'
          underline='none'
        >
          <Card
            sx={{
              mb: 2,
              cursor: 'pointer',
              position: 'relative',
              zIndex: 1,
              borderWidth: 2,
              borderColor: getStatusBorderColor(order.status || order.status),
              borderStyle: 'solid',
              width: '100%',
            }}
            variant='outlined'
          >
            <CardContent>
              <Box display='flex' flexDirection='row' flexWrap='wrap'>
                <Box flex='1' mb={1} minWidth='150px'>
                  <Typography variant='h6'>
                    {order.account_names || 'N/A'}
                  </Typography>
                </Box>
                <Box flex='1' mb={1} minWidth='75px'>
                  <Typography variant='h6'>
                    {(order.side || 'N/A').charAt(0).toUpperCase() +
                      (order.side || 'N/A').slice(1)}
                  </Typography>
                </Box>
                <Box flex='1' mb={1} minWidth='75px'>
                  <Typography variant='h6'>
                    <strong>{order.pair || 'N/A'}</strong>
                  </Typography>
                </Box>
                <Box flex='2' mb={1} minWidth='150px'>
                  <Typography variant='h6'>
                    {order.buy_token_amount
                      ? `${order.buy_token_amount} ${
                          order.market_type !== 'option'
                            ? order.buy_token
                            : 'Contracts'
                        }`
                      : `${order.sell_token_amount} ${
                          order.market_type !== 'option'
                            ? order.sell_token
                            : 'Contracts'
                        }`}
                  </Typography>
                </Box>
                <Box flex='2' mb={1} minWidth='150px'>
                  <Typography variant='h6'>
                    {order.super_strategy_name}
                  </Typography>
                </Box>
                <Box flex='1' mb={1} minWidth='150px'>
                  <Typography variant='h6'>
                    {calculateDurationDisplay(order.duration)}
                  </Typography>
                </Box>
                <Box flex='1' mb={1} minWidth='150px'>
                  <ProgressBar
                    fullWidth
                    isPov
                    progress={Math.round(Number(order.pct_filled?.toFixed(2)))}
                  />
                </Box>
              </Box>
              <Divider />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledHeaderTableCell sx={{ width: '40%' }}>
                        Executed Notional
                      </StyledHeaderTableCell>
                      <StyledHeaderTableCell sx={{ width: '15%' }}>
                        Arrival Cost
                      </StyledHeaderTableCell>
                      <StyledHeaderTableCell sx={{ width: '15%' }}>
                        VWAP Cost
                      </StyledHeaderTableCell>
                      <StyledHeaderTableCell sx={{ width: '15%' }}>
                        Exchange Fee
                      </StyledHeaderTableCell>
                      <StyledHeaderTableCell sx={{ width: '15%' }}>
                        Participation Rate
                      </StyledHeaderTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledHeaderTableCell>
                        <Typography style={{ textAlign: 'left' }}>
                          {notional}
                        </Typography>
                      </StyledHeaderTableCell>
                      <StyledHeaderTableCell>
                        <Typography
                          color={
                            arrival_cost > 0
                              ? theme.palette.charts.red
                              : theme.palette.charts.green
                          }
                          style={{ textAlign: 'left' }}
                        >
                          {arrival_cost}
                        </Typography>
                      </StyledHeaderTableCell>
                      <StyledHeaderTableCell>
                        <Typography
                          color={
                            vwap_cost > 0
                              ? theme.palette.charts.red
                              : theme.palette.charts.green
                          }
                          style={{ textAlign: 'left' }}
                        >
                          {vwap_cost}
                        </Typography>
                      </StyledHeaderTableCell>
                      <StyledHeaderTableCell>
                        <Typography style={{ textAlign: 'left' }}>
                          {fee_notional}
                        </Typography>
                      </StyledHeaderTableCell>
                      <StyledHeaderTableCell>
                        <Typography style={{ textAlign: 'left' }}>
                          {pov}
                        </Typography>
                      </StyledHeaderTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Link>
      </Grid>
    );
  };

  const orderEntryPriorityCard = (orderData, colSize) => {
    const getDisplayNames = {
      accounts: 'Accounts',
      pair: 'Pair',
      side: 'Side',
      base_asset_qty: 'Base Quantity',
      quote_asset_qty: 'Quote Quantity',
      super_strategy: 'Super Strategy',
      strategy: 'Strategy',
      duration: 'Duration',
      engine_passiveness: 'Passiveness',
      alpha_tilt: 'Alpha Tilt',
      schedule_discretion: 'Discretion',
      notes: 'Notes',
      limit_price: 'Limit Price',
      updated_leverage: 'Updated Leverage',
      pov_limit: 'POV Limit',
      pov_target: 'POV Target',
      target_time: 'Target Time',
      stop_price: 'Stop Price',
      strategy_params: 'Strategy Params',
      order_condition: 'Order Condition',
    };

    // Can refactor this with useSubmitForm getDisplayValues
    const getDisplayValues = () => {
      const templateValueMapping = {};

      Object.entries(orderData).forEach(([k, v]) => {
        const displayKey = k;
        switch (k) {
          case 'accounts':
            templateValueMapping[displayKey] = v
              ? v.map((names, index) => {
                  if (index === v.length - 1) {
                    return `${names}`;
                  }
                  return `${names} | `;
                })
              : '';
            break;
          case 'side':
            templateValueMapping[displayKey] = titleCase(v);
            break;
          case 'strategy_params':
            templateValueMapping[displayKey] =
              Object.keys(v).length !== 0
                ? Object.keys(v)
                    .map((key) => {
                      if (!v[key]) {
                        return null;
                      }
                      return (
                        <Typography key={key}>
                          <li>{titleCase(key)}</li>
                        </Typography>
                      );
                    })
                    .filter((value) => value != null)
                : null;
            break;
          case 'notes':
          case 'order_condition':
            templateValueMapping[displayKey] = (
              <Typography sx={{ wordWrap: 'breakWord' }}>{v}</Typography>
            );
            break;
          case 'strategy':
            templateValueMapping[displayKey] = getTrajectoryName({
              trajectory: orderData.strategy,
              trajectories,
            });
            break;
          case 'super_strategy':
            templateValueMapping[displayKey] = getStrategyName({
              selectedStrategy: orderData.super_strategy,
              superStrategies,
              strategies,
            });
            break;
          case 'limit_price_options':
            templateValueMapping.limit_price = v;
            break;
          default:
            templateValueMapping[displayKey] = v;
        }
      });

      const returnObject = {};

      Object.keys(getDisplayNames).forEach((key) => {
        if (Object.keys(orderData).includes(key)) {
          returnObject[key] = [getDisplayNames[key], templateValueMapping[key]];
        }
      });

      return returnObject;
    };

    const displayMapping = getDisplayValues();

    return (
      <Grid item key={orderData.id} xs={colSize}>
        <Card
          key={`${orderData.id}Card`}
          sx={{
            mb: 2,
            cursor: 'pointer',
            position: 'relative',
            zIndex: 1,
            borderWidth: 2,
          }}
          variant='outlined'
        >
          <CardContent
            key={`${orderData.id}CardContent`}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              display='flex'
              flexDirection='row'
              flexWrap='wrap'
              sx={{ width: '100%' }}
            >
              <Stack direction='column' spacing={2}>
                <Box flex='1' mb={1} minWidth='150px'>
                  <Typography variant='h3'>{displayMapping.pair[1]}</Typography>
                </Box>
                <Box flex='1' mb={1} minWidth='150px'>
                  <Stack direction='row' spacing={1}>
                    <Typography
                      color={
                        displayMapping.side[1] === 'Sell'
                          ? theme.palette.charts.red
                          : theme.palette.charts.green
                      }
                      variant='h4'
                    >
                      {`${displayMapping.side[1]} `}
                    </Typography>
                    <Typography variant='h4'>
                      {targetQtyDisplay(orderData)} -{' '}
                      {displayMapping.super_strategy[1]}
                    </Typography>
                  </Stack>
                </Box>

                <Divider />
                <Box
                  display='flex'
                  flexDirection='column'
                  flexWrap='wrap'
                  sx={{ width: '99%', overflowX: 'auto' }}
                >
                  <Stack
                    direction='row'
                    style={{
                      whiteSpace: 'nowrap',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                    }}
                  >
                    <Stack direction='column' style={{ whiteSpace: 'nowrap' }}>
                      <Table size='small'>
                        <TableHead>
                          <TableRow>
                            {Object.keys(displayMapping).map((dataKey) => {
                              const value = orderData[dataKey];
                              const displayValues = displayMapping[dataKey];

                              if (value && !isEmpty(value)) {
                                switch (dataKey) {
                                  case 'side':
                                  case 'pair':
                                  case 'strategy':
                                  case 'super_strategy':
                                  case 'quote_asset_qty':
                                  case 'base_asset_qty':
                                    return null;
                                  default:
                                    if (
                                      displayValues[1] &&
                                      !isEmpty(displayValues[1])
                                    ) {
                                      return (
                                        <StyledHeaderTableCell
                                          align='left'
                                          key={`${dataKey}-HeadCell`}
                                          style={{ width: '150px' }}
                                        >
                                          {displayValues[0]}
                                        </StyledHeaderTableCell>
                                      );
                                    }
                                }
                              }
                              return null;
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            {Object.keys(displayMapping).map((dataKey) => {
                              const value = orderData[dataKey];
                              const displayValues = displayMapping[dataKey];

                              if (value && !isEmpty(value)) {
                                switch (dataKey) {
                                  case 'side':
                                  case 'pair':
                                  case 'strategy':
                                  case 'super_strategy':
                                  case 'quote_asset_qty':
                                  case 'base_asset_qty':
                                    return null;
                                  default:
                                    if (displayValues[1])
                                      return (
                                        <StyledHeaderTableCell
                                          align='left'
                                          key={`${dataKey}-BodyCell`}
                                          style={{ width: '150px' }}
                                        >
                                          {displayValues[1]}
                                        </StyledHeaderTableCell>
                                      );
                                }
                              }
                              return null;
                            })}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                gap: 2,
                position: 'absolute',
                right: 16,
              }}
            >
              <FormControl
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  width: '50px',
                }}
                variant='outlined'
              >
                <Select
                  displayEmpty
                  inputProps={{ 'aria-label': 'Priority' }}
                  value={orderData.priority}
                  onChange={(e) =>
                    handlePriorityChange(e, orderData.id, orderData.priority)
                  }
                >
                  {[...Array(maxPriority + 1).keys()].slice(1).map((num) => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Tooltip title='Remove order item'>
                <IconButton
                  sx={{ height: '75%' }}
                  onClick={(e) => handleDeleteOnClick(e, orderData.index)}
                >
                  <RemoveCircleOutline
                    sx={{ fontSize: 25, color: theme.palette.error.main }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <Box sx={{ maxHeight: '100%', overflowY: 'auto', padding: 2 }}>
      {sortedPriorities.map((priority, index) => {
        const orders = groupedOrders[priority];

        return (
          <Box key={priority} mb={2} position='relative'>
            <Typography gutterBottom fontWeight='bold' variant='subtitle1'>
              Batch {priority}
            </Typography>

            <Box
              sx={{ padding: 2, border: '1px solid grey', borderRadius: '8px' }}
            >
              {orders.map((order) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid
                  container
                  alignItems='center'
                  justifyContent='center'
                  key={`${priority}-row-${order.id}`}
                  spacing={2}
                >
                  {orderView
                    ? orderViewPriorityCard(order, 12)
                    : orderEntryPriorityCard(order, 12)}
                </Grid>
              ))}
            </Box>

            {index < sortedPriorities.length - 1 && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'relative',
                  mb: -7,
                }}
              >
                <Box
                  sx={{
                    width: '2px',
                    height: '30px',
                    backgroundColor: getStatusBorderColor(
                      orders[orders.length - 1].order?.status ||
                        orders[orders.length - 1].status
                    ),
                  }}
                />
                <Box
                  sx={{
                    width: '2px',
                    height: '30px',
                    backgroundColor: getStatusBorderColor(
                      orders[orders.length - 1].order?.status ||
                        orders[orders.length - 1].status
                    ),
                  }}
                />
                <ArrowDownward
                  sx={{
                    fontSize: '2.5rem',
                    color: getStatusBorderColor(
                      orders[orders.length - 1].order?.status ||
                        orders[orders.length - 1].status
                    ),
                  }}
                />
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
}

export default ChainChart;
