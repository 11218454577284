import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import React from 'react';
import { titleCase, numberWithSpaces, smartRound } from '../../util';
import ProgressBar from '../fields/ProgressBar/ProgressBar';
import { displayDefaultTableCell, formatDateTime} from './util';
import { ExchangeIcons } from '../iconUtil';

const getSideColor = ( side, theme ) => {
  if( side === 'buy'){
    return theme.palette.success.main
  } if (side === 'sell'){
    return theme.palette.error.main
  }
  return theme.palette.text.primary
}


const parseStatus = (status) => {

  switch(status) {
  case 'SUBMITTED':
    return <Typography color='primary.main'>
        Submitted
    </Typography>
  case 'CANCELED':
    return <Typography color='error.main'>
        Canceled
    </Typography>
  case 'COMPLETE':
    return <Typography color='success.main'>
        Finished
    </Typography>
  case 'SCHEDULED':
    return <Typography color='secondary.main'>
        Scheduled
    </Typography>
  case 'PAUSED':
    return <Typography color='info.main'>
        Paused
    </Typography>
  default:
    return <Typography color='primary.main'>
        Active
    </Typography>
  }
}

const parseSuperStrategy = (row, value, column ) => {
  let displayText = value

  if( row.side === 'Multi' || row.side === 'Chained'){
    displayText = row.strategy
  }

  if ( row.order_condition_normal ) {
    displayText += '*'
  }

  return displayDefaultTableCell(column, displayText)
}

export default function DisplayRowDetails({row, column, StyledCell, theme}) {
  let value = row[column.id];
  switch(column.id){
  case 'pair':
    if((row.pairs && ( row.side === 'Multi' || row.side === 'Chained')) ){
      return displayDefaultTableCell(column, row.pairs)
    }
    return (
      <StyledCell align={column.align} key={column.id}>
        <b>
          {value  }
        </b>
      </StyledCell>
    );

  case 'executed_notional':
    value = `$${numberWithSpaces(Number(row[column.id]).toFixed(3))}`;
    return displayDefaultTableCell(column, value, {whiteSpace: 'nowrap'})

  case 'status':
    return (
      <StyledCell align={column.align} key={column.id}>
        {parseStatus(row.status)}
      </StyledCell>
    );


  case 'account_names':
    if(row.account_names === undefined || row.account_names.length === 0) {
      return (<StyledCell align={column.align} key={column.id}>
        <i>[Deleted]</i>
      </StyledCell>);
    }
    return displayDefaultTableCell(column, value.join(' | '))

  case 'pct_filled':
    return (
      <StyledCell align={column.align} key={column.id} >
        <Box alignItems="center"
          display="flex"
          height="100%"
          justifyContent="center">
          <ProgressBar
            isPov={row.pov_limit || row.pov_target}
            orderStatus={row.status}
            progress={Math.round(Number(value))}
          />
        </Box>
      </StyledCell>
    );

  case 'super_strategy':
    return parseSuperStrategy(row, value, column)

  case 'side':
    return displayDefaultTableCell(
      column,
      titleCase(value),
      {color: getSideColor(value, theme)}
    )

  case 'time_start':
    if (value) {
      return displayDefaultTableCell(column, formatDateTime(value));
    }
    return displayDefaultTableCell(column, '');


  case 'executed_price':
    return displayDefaultTableCell(column, value ? `$${value.toFixed(6)}` : '')

  case 'target_qty':
    if (row.side === 'Multi') {
      const sellTokens = row.sell_token ? row.sell_token.split(',').map(token => token.trim()) : [];
      const sellAmounts = row.sell_token_amount ?
        row.sell_token_amount.toString().split(',').map(amount => amount.trim()) : [];

      const combinedQuantities = [];
      sellAmounts.forEach((amount, index) => {
        const token = sellTokens[index] || '';
        combinedQuantities.push(`${smartRound(Number(amount))} ${token}`);
      });

      return displayDefaultTableCell(column, combinedQuantities.join(', ') || 'N/A');
    }

    if (row.side === 'Chained') {
      const chainedOrders = row.orders_in_chain || [];
      if (chainedOrders.length === 0) {
        return displayDefaultTableCell(column, 'N/A');
      }
      const targetQuantities = chainedOrders.map((chainedOrder) => {
        const {order} = chainedOrder;
        if (!order) {
          return 'N/A';
        }
        const buyAmount = order?.buy_token_amount
          ? `${smartRound(Number(order.buy_token_amount))} ${order.buy_token || ''}`
          : null;
        const sellAmount = order?.sell_token_amount
          ? `${smartRound(Number(order.sell_token_amount))} ${order.sell_token || ''}`
          : null;
        return buyAmount || sellAmount || 'N/A';
      });
      return displayDefaultTableCell(column, targetQuantities.join(', '));
    }

    return row.buy_token_amount ? (
      displayDefaultTableCell(column, `${smartRound(Number(row.buy_token_amount))} ${row.buy_token || ''}` )
    ) : (
      displayDefaultTableCell(column, `${smartRound(Number(row.sell_token_amount))} ${row.sell_token || ''}` )
    );

  case 'unique_venues':
    return (<StyledCell align={column.align} key={column.id}>
      {row.unique_venues && <ExchangeIcons exchanges={row.unique_venues}/> }
    </StyledCell>);

  default:
    return displayDefaultTableCell(column, value)
  }
}
