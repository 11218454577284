import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Stack,
  Typography,
  Card,
  CardContent,
  Button,
  Box,
  Link,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Tooltip,
  styled,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Grid from '@mui/material/Unstable_Grid2';
import { ErrorContext } from '@/shared/context/ErrorProvider';
import { useUserMetadata } from '@/shared/context/UserMetadataProvider';
import { Loader } from '@/shared/Loader';
import { StyledTableCell, formatDateTime } from '@/shared/orderTable/util';
import DataComponent from '@/shared/DataComponent';
import useGetReferrals from './hooks/useGetReferrals';
import LOGOS from '../../../images/logos';

function generateReferralUrl(code) {
  return `${window.location.origin}/referral/${code}`;
}

const CopyTextField = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: '#141414',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

function CopyField({ textToCopy }) {
  const { showAlert } = useContext(ErrorContext);

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      showAlert({ message: 'Copied to clipboard!' });
    });
  };

  return (
    <CopyTextField
      disabled
      endAdornment={
        <InputAdornment position='end'>
          <Tooltip placement='top' title='Click to copy'>
            <IconButton edge='end' onClick={handleCopy}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </InputAdornment>
      }
      value={textToCopy}
    />
  );
}

function InvitePage() {
  const { code } = useParams();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Card sx={{ width: '800px', height: '320px', padding: '40px' }}>
        <Stack
          alignItems='center'
          direction='column'
          justifyContent='space-between'
          spacing={4}
        >
          <img
            alt='Tread Logo'
            src={LOGOS.treadRoundedSvg}
            style={{ height: '64px' }}
          />
          <Typography
            align='center'
            sx={{ width: '600px' }}
            variant='detailsPrimary'
          >
            You’re invited to join us on tread.fi
          </Typography>
          <Typography variant='subtitle1'>
            Sign up free to earn rewards.
          </Typography>
          <Button
            href={`/account/signup/?code=${code}`}
            size='large'
            sx={{ width: '360px' }}
            variant='contained'
          >
            Sign up
          </Button>
        </Stack>
      </Card>
    </Box>
  );
}

function NoReferrals() {
  return (
    <Stack
      align='center'
      direction='column'
      justifyContent='center'
      spacing={2}
      sx={{ height: '100%' }}
    >
      <img
        alt='Tread Logo'
        src={LOGOS.treadRoundedSvg}
        style={{ height: '64px' }}
      />
      <Typography variant='h6'>You have no referrals</Typography>
    </Stack>
  );
}

function ReferralPage() {
  const { user, referralCode, isMetadataLoading } = useUserMetadata();
  const { userReferrals, isLoading } = useGetReferrals(user.is_authenticated);

  if (isMetadataLoading) {
    return <Loader />;
  }

  if (!user.is_authenticated) {
    return <InvitePage />;
  }

  return (
    <Container fixed maxWidth='lg'>
      <Grid container spacing={2} sx={{ height: '100vh' }}>
        <Grid sx={{ height: '30%' }} xs={6}>
          <Stack
            direction='column'
            justifyContent='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <Typography variant='pageHeader'>Referral</Typography>
            <Box sx={{ width: '70%' }}>
              <Typography variant='h6'>
                Invite your friends and unlock exclusive rewards.{' '}
                <Link
                  href='https://foul-wavelength-9af.notion.site/Tread-fi-Points-Program-13f60eedf44c80f98812fdb7a3fbb5c0'
                  underline='none'
                >
                  Learn more
                </Link>
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid style={{ height: '30%' }} xs={6}>
          <Stack
            direction='column'
            justifyContent='center'
            spacing={4}
            sx={{ height: '100%' }}
          >
            <Stack direction='column' spacing={1}>
              <Typography variant='detailsSecondary'>Referral ID</Typography>
              <CopyField textToCopy={referralCode} />
            </Stack>
            <Stack direction='column' spacing={1}>
              <Typography variant='detailsSecondary'>Referral link</Typography>
              <CopyField textToCopy={generateReferralUrl(referralCode)} />
            </Stack>
          </Stack>
        </Grid>
        <Grid style={{ height: '70%' }} xs={12}>
          <Card style={{ height: 'calc(100% - 80px)' }}>
            <CardContent sx={{ pl: 0, pr: 0 }}>
              <DataComponent
                emptyComponent={<NoReferrals />}
                isEmpty={userReferrals.length === 0}
                isLoading={isLoading}
                loadingComponent={<Loader />}
              >
                <Box sx={{ height: '100%' }}>
                  <TableContainer sx={{ height: '100%' }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>ID</StyledTableCell>
                          <StyledTableCell align='left'>
                            Registration Date
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ overflow: 'auto' }}>
                        {userReferrals.map((row) => (
                          <TableRow
                            hover
                            key={`${row.username}-${row.registration_date}`}
                            sx={{
                              '&:last-child td, &:last-child th': {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell scope='row'>
                              <Stack direction='column'>
                                <Typography
                                  sx={{ fontWeight: '500' }}
                                  variant='body1'
                                >
                                  {row.username}
                                </Typography>
                                <Typography variant='body1'>
                                  {row.email}
                                </Typography>
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                              {formatDateTime(row.registration_date)}
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </DataComponent>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ReferralPage;
