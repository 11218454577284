import React from 'react';
import { useTheme } from '@mui/material/styles';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';

function PointsChart({ pointsData, dateRange }) {
  const theme = useTheme();

  const formattedPointsData = pointsData.map((point) => [
    new Date(point.earned_date).getTime(),
    parseFloat(point.points_earned),
  ]);

  const options = {
    chart: {
      backgroundColor: 'transparent',
      zooming: {
        mouseWheel: false,
      },
    },
    series: [
      {
        type: 'column',
        name: 'Points',
        data: formattedPointsData,
        tooltip: {
          valueDecimals: 2,
        },
        dataGrouping: {
          enabled: true,
          forced: true,
          units: [['day', [dateRange.grouping]]],
        },
      },
    ],
    yAxis: {
      opposite: false,
      type: 'linear',
      gridLineWidth: 0,
      labels: {
        style: {
          color: theme.palette.charts.gray2,
        },
      },
      lineWidth: 2,
      tickWidth: 2,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        format: '{value:%m-%d}',
        style: {
          color: theme.palette.charts.gray2,
        },
      },
      lineWidth: 2,
    },
    plotOptions: {
      series: {
        animation: false,
      },
      column: {
        color: theme.palette.charts.points,
        borderRadius: 8,
      },
    },
    tooltip: {
      outside: true,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <HighchartsReact
      constructorType='stockChart'
      containerProps={{ style: { height: '100%' } }}
      highcharts={Highcharts}
      options={options}
    />
  );
}

export default PointsChart;
