import { useTheme } from '@emotion/react';
import { AddCircleOutline } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
  ApiError,
  fetchAccountBalances,
  fetchOrderEntryFormData,
  getUserFavouritePairs,
  submitChainedOrder,
  submitOrder
} from '../../apiServices';
import { Loader } from '../../shared/Loader';
import { ErrorContext } from '../../shared/context/ErrorProvider';
import ChainedOrdersSubmitForm from "./ChainedOrdersSubmitForm";
import ChainedOrderFormItem from "./ChainedOrdersFormItem";

function orderFormStateReducer(state, action) {
  switch (action.type) {
  case 'ADD_ROW':
    return {
      ...state,
      orders: [...state.orders, action.payload],
    };
  case 'UPDATE_ROW':
    return {
      ...state,
      orders: state.orders.map((item, index) =>
        index === action.payload.rowIndex ? { ...item, ...action.payload } : item
      ),
    };
  case 'REMOVE_ROW': {
    const filteredAndReIndexed = state.orders
      .filter((_, index) => index !== action.payload.rowIndex)
      .map((item, newIndex) => ({ ...item, index: newIndex }));
    return {
      ...state,
      orders: filteredAndReIndexed,
    };
  }
  case 'CHANGE_PRIORITY': {
    const { newPriority, rowIndex } = action.payload;
    const updatedOrders = state.orders.map((order, index) => {
      if (index === rowIndex) {
        return { ...order, priority: newPriority };
      }
      return order;
    });
    return {
      ...state,
      orders: updatedOrders,
    };
  }
  default:
    throw new Error(`Unknown action: ${action.type}`);
  }
}


export default function ChainedOrdersEntryPage() {
  const theme = useTheme();
  const [accounts, setAccounts] = useState([]);
  const [tokenPairs, setTokenPairs] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [strategyParams, setStrategyParams] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState('');
  const [superStrategies, setSuperStrategies] = useState({});
  const [selectedStrategyParams, setSelectedStrategyParams] = useState({});
  const [selectedDuration, setSelectedDuration] = useState(900);
  const [passiveness, setPassiveness] = useState(0.02);
  const [discretion, setDiscretion] = useState(0.08);
  const [exposureTolerance, setExposureTolerance] = useState(0.2);
  const [orderCondition, setOrderCondition] = useState('');
  const [isOrderConditionValidated, setIsOrderConditionValidated] = useState(false);
  const [formState, dispatch] = useReducer(orderFormStateReducer, { orders: [] });
  const [alphaTilt, setAlphaTilt] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [balances, setBalances] = useState({});
  const [favouritePairs, setFavouritePairs] = useState({});

  const { setHasError, setErrorContent } = useContext(ErrorContext);

  const showAlert = ({ severity, message }) => {
    setErrorContent({ severity, message });
    setHasError(true);
  };

  const addOrderRow = (side = 'buy', priority = null) => {
    const lastOrder = formState.orders[formState.orders.length - 1] || {};
    const newPriority = priority || formState.orders.length + 1;

    dispatch({
      type: 'ADD_ROW',
      payload: {
        accounts: lastOrder.accounts || [],
        pair: lastOrder.pair || { base: '', id: '', label: '', quote: '', is_inverse: false, is_contract: false },
        side: side === 'buy' ? 'buy' : 'sell',
        qty: lastOrder.qty || '',
        limit_price: lastOrder.limit_price || '',
        isBaseAsset: lastOrder.isBaseAsset !== undefined ? lastOrder.isBaseAsset : true,
        priority: newPriority,
        index: formState.orders.length,
      },
    });
  };

  const removeOrderRow = (index) => {
    dispatch({
      type: 'REMOVE_ROW',
      payload: { rowIndex: index },
    });
  };

  const createOrder = async (orderData) => {
    try {
      const response = await submitOrder(orderData);
      return response.id;
    } catch (error) {
      showAlert({ message: 'Failed to create order', severity: 'error' });
      return null;
    }
  };

  // const prepareOrdersWithIds = async (orders) => {
  //   const ordersWithIds = [];
  //   for (const order of orders) {
  //     const order_id = await createOrder(order);
  //     if (order_id) {
  //       ordersWithIds.push({ ...order, order_id });
  //     } else {
  //       showAlert({ message: 'Failed to create one of the orders', severity: 'error' });
  //       return [];
  //     }
  //   }
  //   return ordersWithIds;
  // };

  const orderRowToFields = (row) => {
    let fields = {
      accounts: row.accounts.map(acc => acc.name),
      pair: row.pair.id,
      side: row.side,
      limit_price: row.limit_price,
      priority: row.priority,
    };

    if (row.isBaseAsset) {
      fields = { ...fields, base_asset_qty: row.qty };
    } else {
      fields = { ...fields, quote_asset_qty: row.qty };
    }

    return fields;
  };

  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);

      try {
        const [data, balancesData, favouritePairsData] = await Promise.all([
          fetchOrderEntryFormData(),
          fetchAccountBalances(),
          getUserFavouritePairs(),
        ]);

        const entryBalances = {};
        balancesData.balances.forEach(balance => {
          entryBalances[balance.account_id] = balance;
        });
        setBalances(entryBalances);

        const favoritePairs = favouritePairsData.pairs.reduce((acc, pair) => ({ ...acc, [pair]: true }), {});
        setFavouritePairs(favoritePairs);

        const indexedStrategies = data.strategies.reduce((obj, item) => {
          const newObj = obj
          if (item.name !== 'VWAP') {
            newObj[item.id] = item;
          }
          return newObj;
        }, {});
        setStrategies(indexedStrategies);

        const pairs = data.pairs.map(pair => ({
          base: pair.base,
          exchanges: pair.exchanges,
          id: pair.name,
          is_contract: pair.is_contract,
          is_inverse: pair.is_inverse,
          label: pair.name,
          market_type: pair.market_type,
          quote: pair.quote,
        }));
        setTokenPairs(pairs);

        const initialAccounts = {};
        data.accounts.forEach(acc => {
          const scopedAccName = acc.user === data.user_id ? acc.name : `${acc.username}/${acc.name}`;
          const displayName = `${acc.exchange} - ${scopedAccName}`;
          initialAccounts[scopedAccName] = {
            displayName,
            id: acc.id,
            name: scopedAccName,
            exchangeName: acc.exchange,
          };
        });
        setAccounts(initialAccounts);

        const vwapTrajectory = data.strategies.find(element => element.name === 'TWAP');
        setSelectedStrategy(vwapTrajectory?.id || indexedStrategies[Object.keys(indexedStrategies)[0]].id);
        setStrategyParams(data.strategy_params);

        addOrderRow('buy');
      } catch (e) {
        showAlert({ severity: 'error', message: `Error loading initial data: ${e.message}` });
      } finally {
        setIsLoading(false);
      }
    };

    loadInitialData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitLoading(true);

    if (formState.orders.length < 2) {
      showAlert({ severity: 'error', message: 'Chained orders require at least 2 orders.' });
      setSubmitLoading(false);
      return;
    }

    const sortedOrders = formState.orders.sort((a, b) => a.priority - b.priority);
    const orders_in_chain = sortedOrders.map(orderRowToFields);

    const payload = {
      duration: selectedDuration,
      engine_passiveness: passiveness,
      schedule_discretion: discretion,
      strategy: selectedStrategy,
      exposure_tolerance: exposureTolerance,
      orders_in_chain,
    };

    try {
      const response = await submitChainedOrder(payload);

      if (response.id) {
        showAlert({ message: 'Chained order submitted successfully!', severity: 'success' });

        if (response.message === `Chain ${response.id} is Completed.`) {
          showAlert({ severity: 'success', message: `Chain ${response.id} is Completed.` });
        }

        setSubmitLoading(false);
      } else {
        const errorMessage = response.errors?.[0] || 'Failed to submit chained order';
        showAlert({ message: errorMessage, severity: 'error' });
        setSubmitLoading(false);
      }
    } catch (e) {
      const errorMessage = e.response?.data?.errors?.[0] ||
       e.response?.data?.message ||
        e.message ||
         'An unknown error occurred';
      showAlert({ message: `An error occurred while submitting the chained order: ${errorMessage}`,
        severity: 'error' });
      setSubmitLoading(false);
    }
  }

  if (isLoading) {
    return (
      <Box height="100%">
        <Card>
          <CardContent>
            <Loader />
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Stack direction="column" height="100%" spacing={1}>
      <Box height="100%">
        <Card style={{ overflow: 'auto', width: '100%' }}>
          <CardContent style={{ paddingBottom: '16px', height: 'calc(100% - 32px)' }}>
            <Stack direction="column" height="100%" width="100%">
              <ChainedOrdersSubmitForm
                alphaTilt={alphaTilt}
                discretion={discretion}
                exposureTolerance={exposureTolerance}
                formState={formState}
                handleSubmit={handleSubmit}
                isOrderConditionValidated={isOrderConditionValidated}
                orderCondition={orderCondition}
                passiveness={passiveness}
                selectedDuration={selectedDuration}
                selectedStrategy={selectedStrategy}
                selectedStrategyParams={selectedStrategyParams}
                setAlphaTilt={setAlphaTilt}
                setDiscretion={setDiscretion}
                setExposureTolerance={setExposureTolerance}
                setIsOrderConditionValidated={setIsOrderConditionValidated}
                setOrderCondition={setOrderCondition}
                setPassiveness={setPassiveness}
                setSelectedDuration={setSelectedDuration}
                setSelectedStrategy={setSelectedStrategy}
                setSelectedStrategyParams={setSelectedStrategyParams}
                showAlert={showAlert}
                strategies={strategies}
                strategyParams={strategyParams}
                submitLoading={submitLoading}
              />
              <Box sx={{ marginTop: '20px' }}>
                {formState.orders.map((formStateRow, rowIndex) => {
                  return (
                    <ChainedOrderFormItem
                      accounts={accounts}
                      balances={balances}
                      dispatch={dispatch}
                      favouritePairs={favouritePairs}
                      index={rowIndex}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`order${rowIndex}`}
                      maxPriority={formState.orders.length}
                      orderItemState={formStateRow}
                      setFavouritePairs={setFavouritePairs}
                      showAlert={showAlert}
                      tokenPairs={tokenPairs}
                    />
                  )})}
              </Box>
              <Box display="flex" sx={{ marginTop: '20px', justifyContent: 'center' }}>
                <Tooltip title="Add new order item">
                  <IconButton onClick={() => addOrderRow('buy')}>
                    <AddCircleOutline sx={{ fontSize: 25, color: theme.palette.grey.main }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Stack>
  );
}