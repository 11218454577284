import {
  Box,
  Button,
  Divider,
  Icon,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import ICONS from '../../../images/exchange_icons';
import { updateAccount } from '../../apiServices';
import { theme } from '../../theme/theme';

const formatDateString = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};

function AccountItem({
  id,
  accountName,
  exchangeName,
  apiKey,
  createdAt,
  marginMode,
  setOpenConfirmModal,
  setConfirmModalText,
  setDeleteAccountName,
  showAlert,
  loadAccounts,
  exchangeSettings,
  hashedApiKey,
}) {
  const handleDeleteOnClick = async () => {
    setConfirmModalText(
      `Are you sure you want to unlink account ${accountName}?`
    );
    setDeleteAccountName(accountName);
    setOpenConfirmModal(true);
  };

  const handleMarginModeChange = async (newMode) => {
    try {
      await updateAccount({ id, margin_mode: newMode });
    } catch (e) {
      showAlert({
        severity: 'error',
        message: `Could not update margin mode: ${e.message}`,
      });
    }

    loadAccounts();
  };

  const MARGIN_MODES = ['ISOLATED', 'CROSS', 'CASH', 'SPOT_ISOLATED'];

  const IconComponent = ICONS[exchangeName.toLowerCase()] || ICONS.default;

  const rewritePosMode = (value) => {
    if (value === 'net_mode') {
      return 'One-way Mode';
    }
    if (value === 'long_short_mode') {
      return 'Hedge Mode';
    }
    if (value === 'pair_level') {
      return 'Pair Level';
    }

    return 'Unknown';
  };

  const displaySettings = ['OKX', 'Bybit'].includes(exchangeName);

  return (
    <Stack
      flexDirection='row'
      padding={1}
      spacing={2}
      sx={{
        border: `1px solid ${theme.palette.grey.main}`, // Add a border with a custom color
        borderRadius: '16px',
        height: '150px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '30%',
        }}
      >
        <Icon
          sx={{
            // Creates a circle container for the icon
            borderRadius: '50%',
            width: '55px',
            height: '55px',
            // Centers the icon within the circle
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& svg': {
              maxWidth: '100%',
              maxHeight: '100%',
            },
          }}
        >
          <img alt='exchange icon' src={IconComponent} />
        </Icon>
      </Box>
      <Stack
        direction='row'
        spacing={6}
        sx={{
          width: '40%',
          '&.MuiStack-root': {
            marginTop: 0,
          },
        }}
      >
        <Stack
          flexDirection='column'
          sx={{
            justifyContent: 'center',
            width: '60%',
          }}
        >
          <Typography gutterBottom component='div' variant='h4'>
            {accountName}
          </Typography>
          <Typography variant='body1'>{exchangeName}</Typography>
          <Typography sx={{ fontFamily: 'monospace' }} variant='body2'>
            {apiKey ? apiKey.substring(0, 8) + '*'.repeat(12) : '*'.repeat(20)}
          </Typography>
          <Typography
            color={theme.palette.grey.main}
            sx={{ fontFamily: 'monospace' }}
            variant='body2'
          >
            Hashed as:{' '}
            {hashedApiKey
              ? hashedApiKey.substring(0, 8) + '*'.repeat(12)
              : '*'.repeat(20)}
          </Typography>
          <Typography color={theme.palette.grey.main} variant='body2'>
            Added {formatDateString(createdAt)}
          </Typography>
        </Stack>
        <Stack
          alignItems='center'
          direction='column'
          gap={0.5}
          height='100%'
          justifyContent='center'
          sx={{ display: displaySettings ? 'flex' : 'none' }}
          width='40%'
        >
          {exchangeSettings && (
            <Stack alignItems='start' direction='column' width='100%'>
              <Typography variant='body2'>Position Mode</Typography>
              <Typography fontWeight={500} variant='body1'>
                {rewritePosMode(exchangeSettings.pos_mode)}
              </Typography>
            </Stack>
          )}
          {exchangeName === 'OKX' && (
            <Stack alignItems='start' direction='column' width='100%'>
              <Box alignSelf='start'>
                <Typography variant='body2'>Margin Mode</Typography>
              </Box>
              <Select
                size='small'
                sx={{
                  width: '120px',
                }}
                value={marginMode}
                onChange={(e) => handleMarginModeChange(e.target.value)}
              >
                {MARGIN_MODES.map((mode) => (
                  <MenuItem key={mode} value={mode}>
                    {mode}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '30%',
          '&.MuiBox-root': {
            marginTop: 0,
          },
        }}
      >
        <Button color='error' variant='outlined' onClick={handleDeleteOnClick}>
          Unlink
        </Button>
      </Box>
    </Stack>
  );
}

export { AccountItem };
