/* eslint-disable indent */
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more'; // required for arearange
import Highcharts from 'highcharts/highstock';
import React, { useLayoutEffect, useRef } from 'react'; // initializing highcharts-more
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import chartWatermark from '../../../shared/chartWatermark';

HC_more(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);

function PricePredictionChart({
  priceChartData,
  futurePriceVolatility,
  orderOverlayData,
  now,
  groupSize,
  emptyForecastedVolume,
}) {
  const theme = useTheme();
  const chartComponent = useRef(null);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    function updateSize() {
      chartComponent.current.chart.reflow();
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const lastTwoPoints = futurePriceVolatility.slice(-2);
  const stepDown = lastTwoPoints[1][1] - lastTwoPoints[0][1];
  const stepUp = lastTwoPoints[1][2] - lastTwoPoints[0][2];
  const numDecimalPlacesToRound = (n) => {
    return Math.max(2, 6 - Math.max(0, Math.log10(n)));
  };

  // always pick last, and calculate last point for the incomplete last group
  const normalizeAvg = (values1, values2) => {
    if (values1.length < groupSize) {
      if (values1[0] === futurePriceVolatility[0][1]) {
        const midOfFirstPoint =
          (futurePriceVolatility[0][1] + futurePriceVolatility[0][2]) / 2;
        return [midOfFirstPoint, midOfFirstPoint];
      }
      return [values1[0] + 2 * stepDown, values2[0] + 2 * stepUp];
    }
    return [values1[2], values2[2]];
  };

  const filledDataPlotBands = orderOverlayData
    ? orderOverlayData.filled_data.map((o) => ({
        from: new Date(o.time_start).getTime(),
        to: new Date(o.time_end).getTime(),
        color:
          o.side === 'buy'
            ? theme.palette.charts.buyBand
            : theme.palette.charts.sellBand,
        zIndex: 1,
        events: {
          click: () => navigate(`/order/${o.order_id}`),
        },
      }))
    : [];

  const condSeries = orderOverlayData
    ? orderOverlayData.active_condition.map((item) => ({
        name: `${item.condition}`,
        type: 'line',
        color:
          item.side === 'buy'
            ? theme.palette.charts.greenTransparent
            : theme.palette.charts.redTransparent,
        data: [new Date(item.time_start).getTime(), new Date().getTime()].map(
          (xp) => ({
            x: xp,
            y: parseFloat(item.price),
            marker: {
              enabled: true,
              radius: 3,
              symbol: 'circle',
            },
          })
        ),
        point: {
          events: {
            click: () => {
              navigate(`/order/${item.order_id}`);
            },
          },
        },
      }))
    : [];

  const options = {
    chart: {
      backgroundColor: 'transparent',
      zooming: {
        mouseWheel: false,
      },
      marginLeft: 80,
      marginBottom: 35,
    },
    series: [
      {
        type: 'candlestick',
        name: 'Price',
        data: priceChartData,
        color: theme.palette.charts.red,
        lineColor: theme.palette.charts.red,
        upColor: theme.palette.charts.green,
        upLineColor: theme.palette.charts.green,
        tooltip: {
          valueDecimals: numDecimalPlacesToRound(futurePriceVolatility[0][1]),
        },
        dataGrouping: {
          firstAnchor: 'firstPoint',
          lastAnchor: 'firstPoint',
          units: [['minute', [groupSize]]],
          forced: true,
        },
      },
      ...condSeries,
    ],
    yAxis: {
      title: {
        text: 'Price',
      },
      opposite: false,
      gridLineColor: theme.palette.charts.gridLines,
      labels: {
        style: {
          color: theme.palette.text.offWhite,
        },
      },
    },
    xAxis: {
      tickLength: 0,
      type: 'datetime',
      labels: {
        enabled: false,
      },
      plotBands: filledDataPlotBands,
    },
    plotOptions: {
      series: {
        animation: false,
      },
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  const watermarkedOptions = chartWatermark({ options });

  return (
    <HighchartsReact
      constructorType='stockChart'
      containerProps={{ style: { height: '100%', width: '100%' } }}
      highcharts={Highcharts}
      options={watermarkedOptions}
      ref={chartComponent}
    />
  );
}

export { PricePredictionChart };
