import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

function getQueryObject(searchParams) {
  const params = new URLSearchParams(searchParams);
  return Object.fromEntries(params);
}

function useQueryParams() {
  const location = useLocation();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState(getQueryObject(location.search));

  useEffect(() => {
    setQueryParams(getQueryObject(location.search));
  }, [location.search]);

  const setQueryParam = (key, value) => {
    const params = new URLSearchParams(location.search);
    if (!value || value.length === 0) {
      params.delete(key);
    } else {
      params.set(key, value);
    }

    navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return [queryParams, setQueryParam];
}

export default useQueryParams;
