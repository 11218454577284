
import { Box, Button, Divider } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import React, { useContext, useState } from 'react';
import { useTheme } from '@emotion/react';
import { cancelAllOrders } from '../../apiServices';
import { ErrorContext } from '../context/ErrorProvider';
import { BasicModal } from '../Modal';

export default function ChipStatusFilter({
  typeFilter,
  statusHighlight,
  dashboardView = false,
  setStatusHighlight,
  setTypeFilter,
  loadOrders,
  optionsView = false,
  paginationView = false,
  selectedPair,
  setPairFilter,
  pairFilter,
  isDev,
  onClickTypeChipCallback=()=>{},
}){

  const {setHasError, setErrorContent} = useContext(ErrorContext);
  const theme = useTheme();

  const [cancelAllModalOpen, setCancelAllModalOpen] = useState(false);

  const handleCancelAllConfirm = async () => {
    setCancelAllModalOpen(false);
    try{
      await cancelAllOrders().then((response) => {
        setErrorContent({severity: 'success', message: response.message})
        setHasError(true);
        loadOrders()
      })
    } catch(e) {
      setErrorContent({severity: 'error', message: e.message})
      setHasError(true);
    }
    setHasError(true);
  }

  const handleStatusFilter = (statusType) => {

    onClickTypeChipCallback()

    if (statusHighlight.includes(statusType)) {
      setTypeFilter([]);
      setStatusHighlight([]);
    } else {
      if (statusType !== 'CONDITIONAL') {
        setTypeFilter(['SINGLE']);
      }
      setStatusHighlight([statusType]);
    }

  }
  const handleTypeFilter = (type) => {
    onClickTypeChipCallback()

    setStatusHighlight([])

    if ( typeFilter.includes(type) ) {
      if ( paginationView){
        return
      }
      setTypeFilter([])
    } else {
      setTypeFilter([type])
    }
  }

  const chipSxProps = {
    borderRadius: '3px',
    minWidth: '80px',
  }

  const multiChainedToggle = ( type ) => {
    if(type){
      return type === 'MULTI' || type === 'CHAINED'
    }
    return false
  }


  return (
    <Stack
      direction="row"
      justifyContent='space-between'
      spacing={1}
      style={{
        marginBottom: '10px',
        overflowX: 'auto',
      }}>
      <Stack direction="row" spacing={1} style={{overflowX: 'auto'}}>
        {(!optionsView && dashboardView) && <Chip
          disabled={!selectedPair}
          label={selectedPair && selectedPair.id ? selectedPair.id : 'BTC-USDT'}
          sx={{
            borderColor: pairFilter ? 'rgba(255, 181, 106, 0.7) ': theme.palette.text.disabled,
            color: pairFilter ? theme.palette.primary.main : theme.palette.text.disabled,
            ...chipSxProps,
          }}
          variant="outlined"
          onClick={() => setPairFilter(!pairFilter)}
        /> }
        <Divider orientation="vertical" style={{ height: 'auto'}} variant="middle" />
        <Chip
          color={statusHighlight.length > 0 && statusHighlight.includes('ACTIVE') ?
            'primary' : 'info'}
          disabled={multiChainedToggle(typeFilter[0])}
          label='Active'
          sx={chipSxProps}
          variant="outlined"
          onClick={() => handleStatusFilter('ACTIVE')}
        />
        <Chip
          color={statusHighlight.length > 0 && statusHighlight.includes('CANCELED') ?
            'error' : 'info'}

          disabled={multiChainedToggle(typeFilter[0])}
          label='Canceled'
          sx={chipSxProps}
          variant="outlined"
          onClick={() => handleStatusFilter('CANCELED')}
        />
        <Chip
          color={statusHighlight.length > 0 && statusHighlight.includes('COMPLETE') ?
            'success' : 'info'}

          disabled={multiChainedToggle(typeFilter[0])}
          label='Finished'
          sx={chipSxProps}
          variant="outlined"
          onClick={() => handleStatusFilter('COMPLETE')}
        />
        <Chip
          color={statusHighlight.length > 0 && statusHighlight.includes('SCHEDULED') ?
            'secondary' : 'info'}

          disabled={multiChainedToggle(typeFilter[0])}
          label='Scheduled'
          sx={chipSxProps}
          variant="outlined"
          onClick={() => handleStatusFilter('SCHEDULED')}
        />
        <Chip
          color={statusHighlight.length > 0 && statusHighlight.includes('PAUSED') ?
            'secondary' : 'info'}

          disabled={multiChainedToggle(typeFilter[0])}
          label='Paused'
          sx={chipSxProps}
          variant="outlined"
          onClick={() => handleStatusFilter('PAUSED')}
        />
        <Chip
          color={statusHighlight.length > 0 && statusHighlight.includes('CONDITIONAL') ? 'primary' : 'info'}
          label='Conditional'
          sx={chipSxProps}
          variant="outlined"
          onClick={() => handleStatusFilter('CONDITIONAL')}
        />
        <Divider orientation="vertical" style={{ height: 'auto'}} variant="middle" />

        {!optionsView && (
          <Chip
            color={typeFilter.length > 0 && typeFilter.includes('SINGLE') ? 'primary' : 'info'}
            label='Single'
            sx={chipSxProps}
            variant="outlined"
            onClick={() => handleTypeFilter('SINGLE')}
          />
        )}
        {!optionsView && (
          <Chip
            color={typeFilter.length > 0 && typeFilter.includes('MULTI') ? 'primary' : 'info'}
            label='Multi'
            sx={chipSxProps}
            variant="outlined"
            onClick={() => handleTypeFilter('MULTI')}
          />
        )}
        {isDev && !optionsView && (
          <Chip
            color={typeFilter.length > 0 && typeFilter.includes('CHAINED') ? 'primary' : 'info'}
            label='Chained'
            sx={chipSxProps}
            variant="outlined"
            onClick={() => handleTypeFilter('CHAINED')}
          />
        )}

      </Stack>
      { dashboardView &&
        <Box>
          <Button
            color='error'
            sx={{
              minWidth: '124px',
              whiteSpace: 'nowrap',
            }}
            variant='outlined'
            onClick={() => setCancelAllModalOpen(true)}
          >
            Cancel All
          </Button>
          <BasicModal
            confirmButtonText='Yes'
            handleConfirm={handleCancelAllConfirm}
            message='Are you sure you want to cancel all orders?'
            open={cancelAllModalOpen}
            setOpen={setCancelAllModalOpen}
          />
        </Box>
      }
    </Stack>
  )
}