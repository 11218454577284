import { ethers } from 'ethers';

/**
 * Hash data using keccak256 to generate trader ID
 * @param {string} data - Data to hash (typically API key)
 * @returns {string} Hashed data
 */
export const hashData = /** @type {(data: string) => string} */ (data) => {
  return ethers.keccak256(ethers.toUtf8Bytes(data));
};

/**
 * Generate trader ID from API key
 * @param {string} apiKey - API key to generate trader ID from
 * @returns {string} Trader ID
 */
export const generateTraderId = /** @type {(apiKey: string) => string} */ (
  apiKey
) => {
  return hashData(apiKey);
};

/**
 * Format trader ID for display by truncating with ellipsis
 * @param {string} traderId - Full trader ID
 * @param {number} startChars - Number of starting characters to show
 * @param {number} endChars - Number of ending characters to show
 * @returns {string} Formatted trader ID
 */
export const formatTraderId =
  /** @type {(traderId: string, startChars?: number, endChars?: number) => string} */ (
    traderId,
    startChars = 4,
    endChars = 3
  ) => {
    if (!traderId) {
      return '';
    }

    // Remove '0x' prefix if present for display purposes
    const cleanId = traderId.startsWith('0x') ? traderId.slice(2) : traderId;

    if (cleanId.length <= startChars + endChars) {
      return cleanId;
    }

    const start = cleanId.slice(0, startChars);
    const end = cleanId.slice(-1 * endChars);

    return `${start}...${end}`;
  };

/**
 * Checks if a string matches a trader ID, considering both with and without '0x' prefix
 * @param {string} searchString - String to search for
 * @param {string} traderId - Trader ID to match against
 * @returns {boolean} True if matches either format
 */
export const matchesTraderId =
  /** @type {(searchString: string, traderId: string) => boolean} */ (
    searchString,
    traderId
  ) => {
    if (!searchString || !traderId) {
      return false;
    }

    const normalizedSearch = searchString.toLowerCase();
    const normalizedTraderId = traderId.toLowerCase();

    // Check if traderId matches with or without '0x' prefix
    return (
      normalizedTraderId.includes(normalizedSearch) || // matches original format
      (normalizedTraderId.startsWith('0x') && // matches without '0x'
        normalizedTraderId.slice(2).includes(normalizedSearch)) ||
      (!normalizedTraderId.startsWith('0x') && // matches with '0x'
        normalizedTraderId.includes(
          normalizedSearch.startsWith('0x')
            ? normalizedSearch.slice(2)
            : normalizedSearch
        ))
    );
  };
