import React from 'react';
import { Box, Icon } from '@mui/material';
import ICONS from '../../images/exchange_icons';

export function ExchangeIcons({exchanges, pairId}) {
  return <Box display='flex' flexDirection='row' marginLeft='0.3rem'>
    {exchanges
      .filter(exchange => exchange !== 'MockExchange')
      .map((exchange, index) => {
        const url = ICONS[exchange.toLowerCase()] || ICONS.default;

        return (
          <Icon
            // eslint-disable-next-line react/no-array-index-key
            key={`${exchange}${pairId}${index}`}
            sx={{
              borderRadius: '50%',
              width: '1.4rem',
              height: '1.4rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '& svg': {
                maxWidth: '100%',
                maxHeight: '100%',
              },
              zIndex: index + 1,
              mx: '-0.3rem',
            }}
          >
            <img alt='exchange icon' src={url} />
          </Icon>
        );
      })}
  </Box>
}