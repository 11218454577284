import { CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import { numberWithCommas, smartRound } from '../../util';

export default function BalancePreview({
  isBase,
  isBalanceLoading,
  isReadyToPickQty,
  selectedPair,
  totalBaseAsset,
  totalQuoteAsset,
}) {
  const renderBalance = () => {
    if (!isReadyToPickQty) {
      return '';
    }

    const balance = isBase ? totalBaseAsset() : totalQuoteAsset();

    if (!balance) {
      return '';
    }

    const pair = isBase ? selectedPair.base : selectedPair.quote;

    return `${numberWithCommas(smartRound(balance))} ${pair}`;
  };

  return (
    <Stack alignItems='center' direction='row' gap={1}>
      <Typography
        color={
          !isReadyToPickQty || isBalanceLoading ? 'grey.main' : 'grey.disabled'
        }
        variant='body2'
      >
        Balance:
      </Typography>
      <Typography variant='body2'>{renderBalance()}</Typography>
      {isBalanceLoading && isReadyToPickQty && (
        <CircularProgress size={14} sx={{ color: 'info.main' }} />
      )}
    </Stack>
  );
}
