import React, { useState } from 'react';
import { ButtonGroup, Button } from '@mui/material';

export const DateRange = {
  WEEK: { days: 7, display: '7D', key: 'week', grouping: 1 },
  MONTH: { days: 31, display: '1M', key: 'month', grouping: 1 },
  YEAR: { days: 365, display: '1Y', key: 'year', grouping: 30 },
};
export default function DateRangePicker({ dateRange, onSelectDateRange }) {
  return (
    <ButtonGroup align='right' aria-label='Small button group' size='small'>
      {Object.values(DateRange).map((dr) => (
        <Button
          key={dr.key}
          variant={dateRange.key === dr.key ? 'contained' : 'outlined'}
          onClick={() => onSelectDateRange(dr)}
        >
          {dr.display}
        </Button>
      ))}
    </ButtonGroup>
  );
}
