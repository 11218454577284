import { Stack, Tooltip, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import {
  TimezoneAutoComplete,
  timeZoneNoOffset,
} from '../../../../shared/TimezoneUtil';
import { timezoneAtom } from '../../../../shared/hooks/useGlobalFormReducer';
import { useMarketDataContext } from '../MarketDataContext';
import AlgoNumberField from './AlgoNumberField';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

function DurationField({
  disabled,
  FormAtoms,
  isCalculatingDuration,
  selectedDuration,
  setSelectedDuration,
}) {
  const [shouldWarnEvr, setShouldWarnEvr] = useState(false);
  const [durationStartDate, setDurationStartDate] = useAtom(
    FormAtoms.durationStartTimeAtom
  );
  const [durationEndDate, setDurationEndDate] = useAtom(
    FormAtoms.durationEndTimeAtom
  );

  const [startDatePlaceholder, setStartDatePlaceholder] = useState(
    dayjs.utc().local().format('MM/DD/YYYY HH:mm')
  );
  const [endDatePlaceholder, setEndDatePlaceholder] = useState(
    dayjs
      .utc()
      .local()
      .add(selectedDuration, 'seconds')
      .format('MM/DD/YYYY HH:mm')
  );

  const [timeZone] = useAtom(timezoneAtom);
  const [formPageType] = useAtom(FormAtoms.formPageType);

  const timeoutRef = useRef(null);
  const chainedOrderView = formPageType && formPageType === 'ChainedOrderPage';

  const { marketSummaryMetrics, reloading } = useMarketDataContext() || {};
  const checkAndSetDuration = (duration) => {
    if (
      !marketSummaryMetrics ||
      !marketSummaryMetrics?.evr ||
      marketSummaryMetrics?.evr === null
    ) {
      setShouldWarnEvr(true);
    }
    setSelectedDuration(duration);
  };

  const handleStartDateChange = (value) => {
    const currentTime = DateTime.local().setZone(timeZoneNoOffset(timeZone));
    const currentMinute = currentTime.minute;
    const nextFifthMinute = Math.ceil((currentMinute + 1) / 5) * 5;

    let newDate = value;
    if (value && value < currentTime) {
      newDate = currentTime.set({
        minute: nextFifthMinute,
        second: 0,
        millisecond: 0,
      });
      newDate = currentTime.set({
        minute: nextFifthMinute,
        second: 0,
        millisecond: 0,
      });
    }

    setEndDatePlaceholder(
      DateTime.local()
        .setZone(timeZoneNoOffset(timeZone))
        .plus({ seconds: selectedDuration })
        .toFormat('MM/dd/yyyy HH:mm')
    );
    setEndDatePlaceholder(
      DateTime.local()
        .setZone(timeZoneNoOffset(timeZone))
        .plus({ seconds: selectedDuration })
        .toFormat('MM/dd/yyyy HH:mm')
    );
    if (!durationEndDate && selectedDuration) {
      setDurationEndDate(newDate.plus({ seconds: selectedDuration }));
    } else if (value && durationEndDate) {
      const duration = durationEndDate.diff(value, 'seconds').seconds;
      checkAndSetDuration(duration);
    }
    setDurationStartDate(newDate);
  };

  const handleEndDateChange = (value) => {
    setDurationEndDate(value);

    if (durationStartDate && value) {
      const duration = value.diff(durationStartDate, 'seconds').seconds;
      checkAndSetDuration(duration);
    }
  };

  const handleDurationChange = (value) => {
    const durationSeconds = Number(value) * 60;
    checkAndSetDuration(value === '' ? null : durationSeconds);
    if (value && durationStartDate) {
      setDurationEndDate(durationStartDate.plus({ seconds: durationSeconds }));
      setDurationEndDate(durationStartDate.plus({ seconds: durationSeconds }));
    }
  };

  // handles updates on placeholders
  useEffect(() => {
    const updateCurrentTime = () => {
      // const currentTime = dayjs.utc().tz(timeZoneNoOffset(timeZone)).local()
      const currentTime = DateTime.local().setZone(timeZoneNoOffset(timeZone));
      setStartDatePlaceholder(currentTime.toFormat('MM/dd/yyyy HH:mm'));
      setEndDatePlaceholder(
        currentTime
          .plus({ seconds: Number(selectedDuration) })
          .toFormat('MM/dd/yyyy HH:mm')
      );
      setEndDatePlaceholder(
        currentTime
          .plus({ seconds: Number(selectedDuration) })
          .toFormat('MM/dd/yyyy HH:mm')
      );
      timeoutRef.current = setTimeout(updateCurrentTime, 1000); // Update every 10 seconds
    };

    updateCurrentTime();
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [timeZone, selectedDuration]);

  return (
    <Stack
      direction='column'
      spacing={2}
      sx={{ paddingTop: '8px', paddingBottom: '8px' }}
    >
      <Stack direction='row' spacing={2} width='100%'>
        <Stack
          alignItems='center'
          direction='row'
          spacing={1}
          sx={{ width: chainedOrderView ? '100%' : '30%' }}
        >
          <AlgoNumberField
            disabled={disabled}
            InputProps={{
              step: 'any',
              endAdornment: (
                <>
                  {isCalculatingDuration && (
                    <InputAdornment position='end'>
                      <CircularProgress size={20} sx={{ color: 'info.main' }} />
                    </InputAdornment>
                  )}
                  {shouldWarnEvr && (
                    <InputAdornment position='end'>
                      <Tooltip title='Duration estimate based on historical data only'>
                        <ErrorOutlinedIcon color='warning' fontSize='small' />
                      </Tooltip>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
            label='Duration (minutes)'
            sx={{
              width: '100%',
              minWidth: 70,
            }}
            value={
              selectedDuration !== null
                ? Math.max(selectedDuration / 60, 1).toFixed(0)
                : ''
            }
            onChange={(e) => {
              const { value } = e.target;
              handleDurationChange(value);
            }}
          />
        </Stack>
        {!chainedOrderView && (
          <TimezoneAutoComplete sx={{ width: '70%', minWidth: 140 }} />
        )}
      </Stack>
      {!chainedOrderView && (
        <Stack direction='row' spacing={2} width='100%'>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DateTimePicker
              disablePast
              fullwidth
              ampm={false}
              label={`Time Start (${timeZoneNoOffset(timeZone)})`}
              slotProps={{
                textField: {
                  size: 'small',
                  inputProps: {
                    placeholder: startDatePlaceholder,
                    style: { textAlign: 'right', fontSize: '0.8rem' },
                  },
                  InputLabelProps: { shrink: true },
                },
                field: {
                  clearable: true,
                  onClear: () => setDurationStartDate(undefined),
                },
              }}
              sx={{ width: '50%' }}
              timezone={timeZoneNoOffset(timeZone)}
              value={durationStartDate || null}
              onChange={(value) => handleStartDateChange(value)}
            />
            <DateTimePicker
              disablePast
              fullwidth
              ampm={false}
              label={`Time End (${timeZoneNoOffset(timeZone)})`}
              slotProps={{
                textField: {
                  size: 'small',
                  inputProps: {
                    placeholder: endDatePlaceholder,
                    style: { textAlign: 'right', fontSize: '0.8rem' },
                  },
                  InputLabelProps: { shrink: true },
                },
                field: {
                  clearable: true,
                  onClear: () => setDurationEndDate(undefined),
                },
              }}
              sx={{ width: '50%' }}
              timezone={timeZoneNoOffset(timeZone)}
              value={durationEndDate || null}
              onChange={(value) => handleEndDateChange(value)}
            />
          </LocalizationProvider>
        </Stack>
      )}
    </Stack>
  );
}

export default DurationField;
