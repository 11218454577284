import React from 'react';

export const getValues = ({
  setSelectedAccounts,
  setSelectedSide,
  setSelectedPair,
  handleBaseChange,
  handleQuoteChange,
  setPosSide,
  setPovLimit,
  setPovTarget,
  setOrderCondition,
  setAlphaTilt,
  setDiscretion,
  setPassiveness,
  setSelectedStrategy,
  setTrajectory,
  setSelectedStrategyParams,
  setSelectedDuration,
  setUpdatePairLeverage,
  setTargetTime,
  setStopPrice,
  setLimitPrice,
  setIsOOLEnabled,
  setNotes,
  setLoading,
  setLimitPriceQuickSetting,
  setMaxOTCPercentage,
  setMaxClipSize,
}) => {
  return {
    accounts: setSelectedAccounts,
    pair: setSelectedPair,
    side: setSelectedSide,
    pos_side: setPosSide,
    buy_token_amount: handleBaseChange,
    sell_token_amount: handleQuoteChange,
    super_strategy: setSelectedStrategy,
    strategy: setTrajectory,
    duration: setSelectedDuration,
    engine_passiveness: setPassiveness,
    alpha_tilt: setAlphaTilt,
    schedule_discretion: setDiscretion,
    strategy_params: setSelectedStrategyParams,
    order_condition: setOrderCondition,
    notes: setNotes,
    limit_price: setLimitPrice,
    pov_limit: setPovLimit,
    pov_target: setPovTarget,
    target_time: setTargetTime,
    stop_price: setStopPrice,
    max_otc: setMaxOTCPercentage,
    is_ool_pause: setIsOOLEnabled,
    max_clip_size: setMaxClipSize,
  };
};

export const loadOrderTemplate = async (
  values,
  setOpen,
  setLoading,
  setters,
  tokenPairs,
  strategies,
  trajectories,
  user,
  accounts
) => {
  if (Object.keys(values).length < 1) {
    return;
  }
  setOpen(false);
  setLoading(true);

  const getValuesWithSetters = setters;

  const getValuesKeys = Object.keys(setters);

  if (Object.keys(values).includes('side')) {
    await getValuesWithSetters.side(values.side);
  }

  if (Object.keys(values).includes('accounts')) {
    const accountLookupById = {};
    Object.values(accounts).forEach((acc) => {
      accountLookupById[acc.id] = acc.name;
    });
    const scopedAccountNames = values.accounts.map(
      (id) => accountLookupById[id]
    );
    getValuesWithSetters.accounts(scopedAccountNames);
  }

  if (Object.keys(values).includes('pair')) {
    await getValuesWithSetters.pair(
      tokenPairs.find((pair) => pair.id === values.pair)
    );
  }

  await new Promise((r) => {
    setTimeout(r, 2000);
  })
    .then(() => {
      // Loop through all the values and set the corresponding setter if it exists
      Object.keys(values).forEach((rowKey) => {
        const setterKey = getValuesKeys.find((ele) => ele === rowKey);

        if (setterKey) {
          if (setterKey === 'side') {
            getValuesWithSetters.side(values.side);
          } else if (setterKey === 'accounts') {
            const accountLookupById = {};
            Object.values(accounts).forEach((acc) => {
              accountLookupById[acc.id] = acc.name;
            });
            const scopedAccountNames = values.accounts.map(
              (id) => accountLookupById[id]
            );
            getValuesWithSetters.accounts(scopedAccountNames);
          } else if (setterKey === 'pair') {
            getValuesWithSetters.pair(
              tokenPairs.find((pair) => pair.id === values.pair)
            );
          } else if (
            setterKey === 'buy_token_amount' ||
            setterKey === 'sell_token_amount'
          ) {
            /* empty */
          } else if (setterKey === 'super_strategy') {
            const strategy_id = Object.values({
              ...strategies,
              ...trajectories,
            }).find((strat) => strat.name === values.super_strategy);
            if (strategy_id) {
              getValuesWithSetters.super_strategy(strategy_id.id);
            }
          } else if (setterKey === 'strategy') {
            const strategy_id = Object.values(trajectories).find(
              (strat) => strat.name === values.strategy
            );
            if (strategy_id) {
              getValuesWithSetters.strategy(strategy_id.id);
            }
          } else if (setterKey === 'limit_price') {
            if (Object.keys(values).includes('limit_price_options')) {
              getValuesWithSetters.limit_price_options(
                values.limit_price_options
              );
            } else {
              getValuesWithSetters[setterKey](values[rowKey]);
            }
          } else if (setterKey === 'max_otc') {
            getValuesWithSetters.max_otc(values.max_otc * 100);
          } else if (setterKey === 'strategy_params') {
            getValuesWithSetters.strategy_params(values.strategy_params);

            if (Object.keys(values.strategy_params).length > 0) {
              if (values.strategy_params.ool_pause) {
                getValuesWithSetters.is_ool_pause(true);
              }
              if (values.strategy_params.max_clip_size) {
                getValuesWithSetters.max_clip_size(
                  values.strategy_params.max_clip_size
                );
              }
            }
          } else {
            getValuesWithSetters[setterKey](values[rowKey]);
          }
        }
        // but if it doesn't exist, then we need exceptional handling
        else if (
          rowKey === 'order_condition_normal' &&
          getValuesKeys.includes('order_condition')
        ) {
          // this is because there's a mismatch between
          // (a) the order_condition_normal (field name in row data)
          // and (b) order_condition (setter key)
          const valuesToSet = values.order_condition_normal;
          getValuesWithSetters.order_condition(valuesToSet);
        }
      });

      if (values.side === 'sell') {
        if (values.buy_token_amount) {
          getValuesWithSetters.sell_token_amount(values.buy_token_amount);
        } else {
          getValuesWithSetters.buy_token_amount(values.sell_token_amount);
        }
        // if side is buy
      } else if (values.buy_token_amount) {
        getValuesWithSetters.buy_token_amount(values.buy_token_amount);
      } else {
        getValuesWithSetters.sell_token_amount(values.sell_token_amount);
      }
    })
    .then(() => {
      setLoading(false);
    });
};
