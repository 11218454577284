import { useTheme } from '@emotion/react'
import { Link, Stack, TableHead, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import React from 'react'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { cancelMultiOrder, pauseMultiOrder, resumeMultiOrder } from '../../../apiServices'
import ProgressBar from '../../../shared/fields/ProgressBar/ProgressBar'
import { BasicModal } from '../../../shared/Modal'
import {
  StrategyParamsParsed,
  StyledBenchmarkTableCell,
  StyledIBMTypography,
  StyledSummaryTableCell
} from '../../../shared/orderTable/util'
import { BASEURL, smartRound, calculateDurationDisplay } from '../../../util'


function MultiSummaryRender({ orderSummaryData, accountNames}) {
  const theme = useTheme()

  if (
    orderSummaryData === undefined ||
    Object.keys(orderSummaryData).length === 0
  ) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center">
        <ScaleLoader color="text.offWhite" />
      </Box>
    )
  }

  const {
    id,
    backup_accounts,
    executed_notional,
    time_start,
    duration,
    strategy_params,
    strategy,
    notes,
    order_condition_normal,
    schedule_discretion,
    engine_passiveness,
    alpha_tilt,
    pov_limit,
    pov_target,
    limit_price_spread,
    exposure_tolerance,
    status,
  } = orderSummaryData

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledSummaryTableCell align='left' colSpan="2">
            <Typography color='primary' variant='h6'>
              {id}
            </Typography>
          </StyledSummaryTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <StyledSummaryTableCell><Typography>Accounts</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Typography>
              {accountNames !== undefined ? (
                accountNames.map((names, index) => {
                  if(index === accountNames.length-1 ) {
                    return `${names  }`
                  }
                  return `${names  } | `
                })
              ) : (
                <i>{backup_accounts}</i>
              )}
            </Typography>
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Trajectory</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Typography>{strategy}</Typography>
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Time Start</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell><StyledIBMTypography>{time_start}</StyledIBMTypography></StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Duration</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell><StyledIBMTypography>
            {calculateDurationDisplay(duration)}
          </StyledIBMTypography></StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Executed Notional</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <StyledIBMTypography>${executed_notional}</StyledIBMTypography>
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Strategy Configurations</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <StrategyParamsParsed
              useIBM
              alpha_tilt={alpha_tilt}
              engine_passiveness={engine_passiveness}
              exposure_tolerance={exposure_tolerance}
              pov_limit={pov_limit * 100}
              pov_target={pov_target * 100}
              schedule_discretion={schedule_discretion}
              strategy_params={strategy_params}
            />
          </StyledSummaryTableCell>
        </TableRow>
        {limit_price_spread && (
          <TableRow>
            <StyledSummaryTableCell><Typography>Dynamic Limit Spread</Typography></StyledSummaryTableCell>
            <StyledSummaryTableCell>
              <StyledIBMTypography>${smartRound(Number(limit_price_spread))}</StyledIBMTypography>
            </StyledSummaryTableCell>
          </TableRow>
        )}
        {notes && (
          <TableRow>
            <StyledSummaryTableCell><Typography>Notes</Typography></StyledSummaryTableCell>
            <StyledSummaryTableCell><Typography>{notes}</Typography></StyledSummaryTableCell>
          </TableRow>
        )}
        {order_condition_normal && (
          <TableRow>
            <StyledSummaryTableCell><Typography>Order Condition</Typography></StyledSummaryTableCell>
            <StyledSummaryTableCell><Typography>{order_condition_normal}</Typography></StyledSummaryTableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

function MultiOrderActions({ childOrders, orderSummaryData, showAlert, loadOrderData}) {

  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false)
  const [confirmModalText, setConfirmModalText] = React.useState('')
  const [handleConfirm, setHandleConfirm] = React.useState(() => {})

  if (
    orderSummaryData === undefined ||
    Object.keys(orderSummaryData).length === 0
  ) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center">
        <ScaleLoader color="text.offWhite" />
      </Box>
    )
  }

  const {
    id,
    status,
  } = orderSummaryData

  const isPaused = status === 'PAUSED';
  const isTerminated = childOrders.every(child => child.status === 'COMPLETE' || child.status === 'CANCELED');

  const handleCancel = async() => {
    setConfirmModalOpen(false);
    try {
      await cancelMultiOrder(id);
    } catch (error) {
      showAlert({ message: error.message, severity: 'error' })
    }

    loadOrderData(id);
  }

  const handlePause = async() => {
    setConfirmModalOpen(false);
    try {
      await pauseMultiOrder(id);
    } catch (error) {
      showAlert({ message: error.message, severity: 'error' })
    }

    loadOrderData(id);
  }

  const handleResume = async() => {
    setConfirmModalOpen(false);
    try {
      await resumeMultiOrder(id);
    } catch (error) {
      showAlert({ message: error.message, severity: 'error' })
    }

    loadOrderData(id);
  }

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Stack alignItems="center"
        direction="row"
        justifyContent="center"
        spacing={1}
        sx={{
          width: '100%',
          height: '100%',
          marginX: '8px',
        }}>
        <Button fullWidth
          aria-label="pause"
          color='secondary'
          disabled={isTerminated}
          size='small'
          variant="contained"
          onClick={(event) => {
            event.stopPropagation()
            setHandleConfirm(() => isPaused ? handleResume : handlePause)
            setConfirmModalText(`Are you sure you want to ${isPaused ? "resume" : "pause"} this order?`)
            setConfirmModalOpen(true)
          } }
        >
          {isPaused ? 'Resume' : 'Pause'}
        </Button>
        <Button fullWidth
          aria-label="cancel"
          color='error'
          disabled={isTerminated}
          size='small'
          variant="contained"
          onClick={(event) => {
            event.stopPropagation()
            setConfirmModalText('Are you sure you want to cancel this order?')
            setHandleConfirm(() => handleCancel)
            setConfirmModalOpen(true)
          } }
        >
          Cancel
        </Button>
      </Stack>
      <BasicModal
        confirmButtonText='Yes'
        handleConfirm={handleConfirm}
        message={confirmModalText}
        open={confirmModalOpen}
        setOpen={setConfirmModalOpen}
      />
    </Box>
  )
}

function ChildOrderSummary({childOrders, includeHeaders}) {
  const theme = useTheme()

  const createLink = (child) => {

    const executedQty = (qty) => {
      return qty ? Number(qty).toFixed(3) : 0
    }

    const amount = child.buy_token_amount !== null ?
      `${executedQty(child.executed_buy_qty)} / ${Number(child.buy_token_amount).toFixed(3)}` :
      `${executedQty(child.executed_qty)} / ${Number(child.sell_token_amount).toFixed(3)}`

    return(
      <Link color={theme.palette.primary.main} href={
        `${BASEURL
        }/order/${
          child.id}`
      } variant='body1'>
        {amount}
        <br />
        {child.pair}
      </Link>
    )
  }

  if (
    childOrders === undefined ||
    Object.keys(childOrders).length === 0
  ) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center">
        <ScaleLoader color="#FFFFFF" />
      </Box>
    )
  }

  return (
    <Table style={{overflow: 'auto'}}>
      {
        includeHeaders && (
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>Child Order</Typography>
              </TableCell>
              <TableCell>
                <Typography>Side</Typography>
              </TableCell>
              <TableCell>
                <Typography>Executed Price</Typography>
              </TableCell>
              <TableCell>
                <Typography>Progress</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        )
      }
      <TableBody style={{overflow: 'auto'}}>
        {childOrders.map((child) => (
          <TableRow
            key={child.id}
          >
            <TableCell>
              {createLink(child)}
            </TableCell>
            <TableCell>
              <Typography>
                {child.side.charAt(0).toUpperCase() + child.side.slice(1)}
              </Typography>
            </TableCell>
            <TableCell>
              <StyledIBMTypography>
                ${Number(child.executed_price).toFixed(3)}
              </StyledIBMTypography>
            </TableCell>
            <TableCell>
              <ProgressBar
                fullWidth
                isPov
                progress={Math.round(Number(child.pct_filled))}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

function MultiOrderBenchmark({ benchmarkData }) {
  const theme = useTheme()

  if (
    benchmarkData === undefined ||
    Object.keys(benchmarkData).length === 0
  ) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center">
        <ScaleLoader color="#FFFFFF" />
      </Box>
    )
  }
  const {
    arrival_cost,
    notional_exposure,
    vwap_cost,
    fee_notional,
  } = benchmarkData


  return (
    <Stack direction='column' spacing={2}>
      <Typography variant='h4'>Benchmarks</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <StyledBenchmarkTableCell><Typography>Notional Exposure</Typography></StyledBenchmarkTableCell>
            <StyledBenchmarkTableCell>
              <StyledIBMTypography style={{textAlign: 'right'}}>
              ${Number(notional_exposure).toFixed(6)}
              </StyledIBMTypography>
            </StyledBenchmarkTableCell>
          </TableRow>
          <TableRow>
            <StyledBenchmarkTableCell><Typography>Arrival (bps)</Typography></StyledBenchmarkTableCell>
            <StyledBenchmarkTableCell>
              <StyledIBMTypography color={arrival_cost > 0 ? theme.palette.charts.red : theme.palette.charts.green}
                style={{textAlign: 'right'}}>
                {Number(arrival_cost).toFixed(6)}
              </StyledIBMTypography>
            </StyledBenchmarkTableCell>
          </TableRow>
          <TableRow>
            <StyledBenchmarkTableCell><Typography>VWAP (bps)</Typography></StyledBenchmarkTableCell>
            <StyledBenchmarkTableCell>
              <StyledIBMTypography  color={vwap_cost > 0 ? theme.palette.charts.red : theme.palette.charts.green}
                style={{textAlign: 'right'}}
              >
                {Number(vwap_cost).toFixed(6)}
              </StyledIBMTypography>
            </StyledBenchmarkTableCell>
          </TableRow>
          <TableRow>
            <StyledBenchmarkTableCell><Typography>Exchange Fee</Typography></StyledBenchmarkTableCell>
            <StyledBenchmarkTableCell><StyledIBMTypography style={{textAlign: 'right'}}>
              ${Number(fee_notional).toFixed(6)}
            </StyledIBMTypography></StyledBenchmarkTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  )
}
export { ChildOrderSummary, MultiOrderActions, MultiOrderBenchmark, MultiSummaryRender }

