/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardContent, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { set } from 'lodash';
import { fetchOrderDetailData } from '../../../apiServices';
import { ErrorContext } from '../../../shared/context/ErrorProvider';
import { FillOrderTable } from './FillOrderTable';
import { OrderBenchmarks, FillPieCard, OrderSummary } from '../../../shared/orderDetail';
import PlacementsTable from './PlacementsTable';

function SimpleOrderDetailsPage() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [benchmarkState, setBenchmarkState] = useState({})
  const [orderSummaryState, setOrderSummaryState] = useState({})
  const [analytics, setAnalytics] = useState({})
  const [tableData, setTableData] = useState({ placements: [], fills: [] });

  const {setHasError, setErrorContent} = useContext(ErrorContext);

  const showAlert = ({severity, message}) => {
    setErrorContent({severity, message});
    setHasError(true);
  }

  const parseOrderData = (messageData) => {
    const{
      benchmark,
      fills,
      order_summary,
      order_analytics,
      placements,
    } = messageData

    if (!order_summary.is_simple) {
      navigate(`/order/${order_summary.id}`);
      return;
    }

    if (Object.keys(messageData).length > 0) {

      if (Object.keys(messageData).length > 0) {
        const { interval_volume, base_asset, pov } = benchmark
        setBenchmarkState((prevState) => ({
          ...benchmark,
          interval_volume:
              interval_volume !== undefined
                ? interval_volume
                : prevState.interval_volume,
          base_asset:
              base_asset !== undefined ? base_asset : prevState.base_asset,
          pov: pov !== undefined ? pov : prevState.pov,
        }))
      }
      if (Object.keys(order_summary).length > 0) {
        setOrderSummaryState(order_summary)
      }

      if (Object.keys(order_analytics).length > 0) {
        setAnalytics(order_analytics)
      }

      setTableData({placements, fills})
    }
  }

  const loadOrderData = async (order_id) => {
    let orderData;
    try {
      orderData = await fetchOrderDetailData(order_id);
    } catch (e) {
      showAlert({severity: 'error', message: `Failed to fetch order details: ${e.message}`});
      return false;
    }

    parseOrderData(orderData);
    return orderData;
  }

  useEffect(() => {
    let intervalId;

    const loadData = async () => {
      const order_id = uuid;
      const orderData = await loadOrderData(order_id);

      if (orderData.order_ended) {
        clearInterval(intervalId);
      }

      return true;
    }

    const success = loadData();

    if (success) {
      intervalId = setInterval(() => { loadData() }, 2000);
      return () => clearInterval(intervalId);
    }

    return null;
  }, [])

  return (
    <Grid container spacing={1} style={{ height: '100%' }}>
      <Grid style={{height: '60%'}} xs={4}>
        <Stack direction='column' spacing={1} sx={{
          height: '100%',
          width: '100%'
        }}>
          <Card style={{width: '100%', height: '100%', overflow: 'auto'}}>
            <CardContent>
              <OrderSummary
                isSimple orderId={uuid} OrderSummaryData={orderSummaryState} showAlert={showAlert}/>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
      <Grid style={{height: '60%'}} xs={8}>
        <Stack direction='column' spacing={1} style={{height: '100%'}}>
          <Card style={{width: '100%', height: '40%', padding: 0}}>
            <CardContent style={{ height: '100%', padding: 0}}>
              <PlacementsTable placements={tableData.placements}/>
            </CardContent>
          </Card>
          <Card style={{width: '100%', height: '60%', padding: 0, overflow: 'auto'}}>
            <CardContent style={{ height: '100%', padding: 0}}>
              <FillOrderTable
                fills={tableData.fills}
              />
            </CardContent>
          </Card>
        </Stack>
      </Grid>

      {/* Bottom row, left half */}
      <Grid style={{height: '40%', paddingBottom: 0}} xs={6}>
        <OrderBenchmarks benchmarkData={benchmarkState} key={benchmarkState} />
      </Grid>

      {/* Bottom row, right half */}
      <Grid style={{height: '40%', paddingBottom: 0}} xs={6}>
        <FillPieCard analytics={analytics} />
      </Grid>
    </Grid>

  )
}

export default SimpleOrderDetailsPage;

