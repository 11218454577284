/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai';
import {
  convertQty,
  fetchExchangePairs,
  getPreTradePrediction,
  getPairPrice,
} from '../../../../apiServices';

import { CoreFormHandlers } from '../CoreFormHandlers';
import { filterOutFalseyValues, smartRound } from '../../../../util';

export const useBaseForm = ({
  showAlert,
  FormAtoms,
  options=false,
}) => {
  const [selectedAccounts, setSelectedAccounts] = useAtom(FormAtoms.selectedAccountsAtom)
  const [selectedSide, setSelectedSide] = useAtom(FormAtoms.selectedSideAtom)
  const [selectedPair, setSelectedPair] = useAtom(FormAtoms.selectedPairAtom)
  const [selectedDuration] = useAtom(FormAtoms.selectedDurationAtom)
  const [povTarget] = useAtom(FormAtoms.povTargetAtom)
  const [limitPrice] = useAtom(FormAtoms.limitPriceAtom)
  const [relevantExchangePairs, setRelevantExchangePairs] = useAtom(FormAtoms.relevantExchangePairsAtom)

  const [baseQtyPlaceholder, setBaseQtyPlaceholder] = useAtom(FormAtoms.baseQtyPlaceholderAtom)
  const [quoteQtyPlaceholder, setQuoteQtyPlaceholder] = useAtom(FormAtoms.quoteQtyPlaceholderAtom)
  const [baseQty, setBaseQty] = useAtom(FormAtoms.baseQtyAtom)
  const [baseContractQty, setBaseContractQty] = useAtom(FormAtoms.baseContractQtyAtom)
  const [quoteQty, setQuoteQty] = useAtom(FormAtoms.quoteQtyAtom)
  const [basePercentage, setBasePercentage] = useAtom(FormAtoms.basePercentageAtom)
  const [quotePercentage, setQuotePercentage] = useAtom(FormAtoms.quotePercentageAtom)

  const [convertedQty, setConvertedQty] = useAtom(FormAtoms.convertedQtyAtom)
  const [balances, setBalances] = useAtom(FormAtoms.balancesAtom)
  const [convertedQtyLoading, setConvertedQtyLoading] = useAtom(FormAtoms.convertedQtyLoadingAtom)


  const [preTradeEstimationData, setPretradeEstimationData] = useAtom(FormAtoms.preTradeEstimationDataAtom);
  const [preTradeDataLoading, setPreTradeDataLoading] = useAtom(FormAtoms.preTradeDataLoadingAtom);
  const [preTradeDataError, setPreTradeDataError] = useAtom(FormAtoms.preTradeDataErrorAtom);

  const [ initialLoadValues ] = useAtom(FormAtoms.initialLoadValueAtom)

  const [selectedPairPrice, setSelectedPairPrice] = useAtom(FormAtoms.selectedPairPriceAtom);
  const [fetchPairAttempts, setFetchPairAttempts] = useState(0);

  const isFirstRender = useRef(true);
  const {
    accounts
  } = initialLoadValues

  const isBuySide = selectedSide === 'buy';

  const isReadyToPickQty = selectedAccounts.length > 0 && (selectedPair && Object.keys(selectedPair).length > 0);

  const fetchTradePrediction = async (duration = selectedDuration) => {
    if(options){
      return
    }
    const readyForAnalysis = selectedAccounts.length > 0 && selectedPair && (baseQty || quoteQty);
    if (!readyForAnalysis || Object.keys(preTradeEstimationData) > 0 || preTradeDataLoading || !duration) {
      return;
    }

    try {
      setPreTradeDataLoading(true);

      const accNames = selectedAccounts.map(a => accounts[a].exchangeName);

      const qty = baseQty || convertedQty;
      const data = await getPreTradePrediction(accNames, selectedPair.id, qty, duration, limitPrice, selectedSide);

      setPretradeEstimationData({
        pov: data.pov,
        volatility: data.volatility,
        marketVolume: data.market_volume,
      })
    } catch (error) {
      setPreTradeDataError(error.message);
    } finally {
      setPreTradeDataLoading(false);
    }
  };

  useEffect(() => {
    // don't trigger calculation pre-trade analytics here if povTarget is set
    // let duration re-calculation trigger it
    if (!povTarget) {
      fetchTradePrediction();
    }
  }, [convertedQty]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchTradePrediction()
    }, 1500);

    return () => {
      clearTimeout(handler);
    };
  }, [selectedDuration]);

  useEffect(() => {
    const getExchangePairs = async () => {
      const exchangeNames = filterOutFalseyValues(selectedAccounts.map(a => accounts[a].exchangeName));

      const pairName = options ? selectedPair.name : selectedPair.id;

      try {
        const pairs = await fetchExchangePairs(exchangeNames, pairName);
        setRelevantExchangePairs(pairs);
      } catch (error) {
        showAlert({
          message: `Could not find pair ${selectedPair.name} for exchanges: ${exchangeNames}`,
          severity: 'error',
        });
      }
    }

    if (selectedAccounts.length > 0 && selectedPair) {
      getExchangePairs();
    }
  }, [selectedAccounts, selectedPair])

  const calculateAssetBalance = (symbol) => {
    let totalAmount = 0;

    selectedAccounts.forEach(accountIteration => {
      if (!balances[accounts[accountIteration].id]) {
        return;
      }

      balances[accounts[accountIteration].id].assets.forEach(asset => {
        if(asset.symbol === symbol) {
          totalAmount += asset.amount
        }
      })
    })
    return totalAmount;
  }

  const resetForm = () => {
    setBasePercentage(0)
    setQuotePercentage(0);
    setBaseQty('');
    setQuoteQty('');
    setConvertedQty('');
    setBaseContractQty('');
    setPretradeEstimationData({});
    setSelectedPairPrice({pair: '', price: 0, timestamp: null});
    setFetchPairAttempts(0);
  }

  const totalBaseBalance = () => {
    const baseIdentifier = options ? selectedPair.name : selectedPair.id;
    const baseAsset = selectedPair.is_contract ? baseIdentifier : selectedPair.base;
    return calculateAssetBalance(baseAsset)
  }

  const fetchPairPrice = async () => {
    if (!selectedPair || fetchPairAttempts > 2) {
      return null;
    }

    let pairPrice = selectedPairPrice.price;
    const pairName = options ? selectedPair.name : selectedPair.id;

    // only fetch if pair has changed or if the last fetch was more than 5 seconds ago
    if (selectedPairPrice.pair !== pairName || (new Date() - selectedPairPrice.timestamp) > 5000) {
      try {
        const result = await getPairPrice(pairName, accounts[selectedAccounts[0]].exchangeName)
        pairPrice = result[pairName]
      } catch (e) {
        showAlert({
          severity: 'error',
          message: `Could not fetch price for pair ${pairName}`,
        });
        setFetchPairAttempts(fetchPairAttempts + 1);
        return null;
      }
      setFetchPairAttempts(0);
      setSelectedPairPrice({pair: pairName, price: pairPrice, timestamp: new Date()});
    }

    return pairPrice;
  }

  const calculateQuoteAssetBalance = (pairPrice) => {
    if (!selectedPair.is_inverse) {
      return calculateAssetBalance(selectedPair.quote);
    }

    return calculateAssetBalance(selectedPair.base) * pairPrice;
  }

  const handleTokenQtyBlur = async (value, isBase, preFetchedPrice) => {
    if (!value || !isReadyToPickQty || convertedQtyLoading) {
      return;
    }

    setConvertedQtyLoading(true);

    const selectedPairName = options ? selectedPair.name : selectedPair.id;
    const pairPrice = preFetchedPrice || await fetchPairPrice();
    const selectedAccountNames = selectedAccounts.map(acc => accounts[acc].name)
    const selectedExchanges = selectedAccounts.map(acc => accounts[acc].exchangeName)

    if (!pairPrice) {
      setConvertedQtyLoading(false);
      return;
    }

    try {
      const result = await convertQty(selectedAccountNames, selectedPairName, value, isBase, pairPrice);
      const qty = isBase ? result.quote_asset_qty : result.base_asset_qty;
      const baseAsset = selectedPair.is_contract ? selectedPairName : selectedPair.base;
      const token = isBase ? selectedPair.quote : baseAsset;
      if (isBase) {
        setQuoteQtyPlaceholder(`${qty} ${token}`);
      } else {
        setBaseQtyPlaceholder(`${smartRound(Number(qty))} ${selectedPair.base}`);

        if (selectedPair.is_contract && selectedExchanges.includes('Deribit') && selectedPair.is_inverse) {
          const convertToNumContracts = true;
          const contractConvertResult = await convertQty(selectedAccountNames, selectedPairName, value,
            isBase, pairPrice, convertToNumContracts);
          const numContracts = contractConvertResult.base_asset_qty;
          setBaseContractQty(numContracts);
        }
      }

      if (isBuySide) {
        const quoteAssetQty = isBase ? qty : value;
        const totalQuoteAsset = calculateQuoteAssetBalance(pairPrice);

        setQuotePercentage(Number((100 * quoteAssetQty / totalQuoteAsset).toFixed(2)));
      } else {
        const baseAssetQty = isBase ? value : qty;
        const totalBaseAsset = Math.abs(calculateAssetBalance(baseAsset));

        setBasePercentage(Number((100 * baseAssetQty / totalBaseAsset).toFixed(2)));
      }

      setConvertedQty(qty);
    } catch (e) {
      setPreTradeDataError('No price to convert quote to base quantity')
    } finally {
      setConvertedQtyLoading(false);
    }
  }

  useEffect(() => {
    if(!options){
      const handler = setTimeout(() => {
        handleTokenQtyBlur(baseQty || quoteQty, !!baseQty)
      }, 1500);

      return () => {
        clearTimeout(handler);
      };
    }
    return () => {}
  }, [baseQty, quoteQty]);

  const handleBaseQtyOnChange = (value) => {
    setBaseQty(value);
    if(value === '') {
      setQuoteQtyPlaceholder('Quote Asset Quantity');
    }
    setQuoteQty('');
    setPretradeEstimationData({});
  }

  const handleQuoteQtyOnChange = (value) => {
    setQuoteQty(value);
    setBaseQty('');
    if(value === '') {
      setBaseQtyPlaceholder('Base Asset Quantity');
    }
    setPretradeEstimationData({});
  }

  const onBasePercentageChangeCommit = (e, newValue) => {
    e.preventDefault()
    const baseIdentifier = options ? selectedPair.name : selectedPair.id;
    const baseAsset = selectedPair.is_contract ? baseIdentifier : selectedPair.base;
    const assetAmount = Math.abs(calculateAssetBalance(baseAsset));

    const val = smartRound(assetAmount * (newValue / 100));
    handleBaseQtyOnChange(val)
    handleTokenQtyBlur(val, true)
  }

  const onQuotePercentageChangeCommit = async (e, newValue) => {
    e.preventDefault()
    const pairPrice = selectedPair.is_inverse ? await fetchPairPrice() : null;
    const assetAmount = calculateQuoteAssetBalance(pairPrice);
    const val = smartRound(assetAmount * (newValue / 100));
    handleQuoteQtyOnChange(val);
    handleTokenQtyBlur(val, false, pairPrice);
  }

  const handleCoreFields = CoreFormHandlers({
    resetForm,
    setBaseQtyPlaceholder,
    setQuoteQtyPlaceholder,
    setSelectedAccounts,
    setSelectedPair,
    setSelectedSide,
    selectedAccounts,
    accounts,
  })

  const { handleSelectedPair } = handleCoreFields;
  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first render for order view resubmit orders
      isFirstRender.current = false;
      return;
    }
    handleSelectedPair(selectedPair);
  }, [selectedPair])

  const quoteBaseStates = {
    baseQty, quoteQty, baseQtyPlaceholder, quoteQtyPlaceholder, baseContractQty,
    basePercentage, quotePercentage, balances, setBalances, convertedQtyLoading,
    selectedAccounts, selectedPair, selectedSide, relevantExchangePairs, convertedQty,
    setBasePercentage, setQuotePercentage, setSelectedAccounts, setSelectedPair, setSelectedSide
  }

  const handleBaseQuoteFields = {
    handleBaseQtyOnChange, handleQuoteQtyOnChange,
    onBasePercentageChangeCommit, onQuotePercentageChangeCommit,
    handleTokenQtyBlur, fetchTradePrediction
  }

  const percentageSliderInfo = {
    totalQuoteAsset: () => {
      if (!selectedPairPrice.price) {
        fetchPairPrice();
      }
      return calculateQuoteAssetBalance(selectedPairPrice.price);
    },
    totalBaseAsset: () => totalBaseBalance()

  }

  return { handleCoreFields, quoteBaseStates, handleBaseQuoteFields, percentageSliderInfo, fetchPairPrice }

}