import { useTheme } from '@emotion/react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import React, { useLayoutEffect, useMemo, useRef } from 'react';
import chartWatermark from '../../../shared/chartWatermark';

require('highcharts/modules/accessibility')(Highcharts);

function VolumePredictionChart({
  volumeChartData,
  now,
  groupSize,
  emptyForecastedVolume,
}) {
  const theme = useTheme();
  const chartComponent = useRef(null);

  useLayoutEffect(() => {
    function updateSize() {
      chartComponent.current.chart.reflow();
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const sum = (values) => values.reduce((a, b) => a + b, 0);
  const normalizeSum = (values) => {
    const { length } = values;
    const avg = sum(values) / length;

    return avg * groupSize;
  };

  function interpolatedPredictedVolumeData() {
    const interpolatedData = [...volumeChartData.forecasted_volume];
    const realDataLength = volumeChartData.forecasted_volume.length;

    if (interpolatedData.length === 0) {
      return [];
    }
    if (groupSize > realDataLength) {
      const lastTimestamp = interpolatedData[realDataLength - 1][0];
      const numPointsNeeded = groupSize - realDataLength;

      const avgVolume =
        volumeChartData.forecasted_volume.reduce(
          (acc, [_, volume]) => acc + volume,
          0
        ) / realDataLength;

      for (let i = 0; i < numPointsNeeded; i += 1) {
        const newTimestamp = lastTimestamp + (i + 1) * 60 * 1000; // Assumes data is in minute intervals
        interpolatedData.push([newTimestamp, avgVolume]);
      }
    }

    return interpolatedData;
  }

  const predictedVolumeData = useMemo(
    () => interpolatedPredictedVolumeData(),
    [volumeChartData.forecasted_volume]
  );
  const options = {
    chart: {
      backgroundColor: 'transparent',
      zooming: {
        mouseWheel: false,
      },
      // line chart rendering has almost no horizontal padding
      // necessary hack to account for padding on price candlestick chart
      marginLeft: 70,
      marginRight: 10,
    },
    series: [
      {
        type: 'column',
        name: 'Volume',
        data: volumeChartData.volume,
        color: theme.palette.charts.blue,
        tooltip: {
          valueDecimals: 2,
        },
        dataGrouping: {
          firstAnchor: 'firstPoint',
          lastAnchor: 'firstPoint',
          units: [['minute', [groupSize]]],
          approximation: normalizeSum,
          forced: true,
        },
      },
    ],
    yAxis: {
      title: {
        text: 'Volume',
      },
      opposite: false,
      type: 'linear',
      gridLineColor: theme.palette.charts.gridLines,
      labels: {
        style: {
          color: theme.palette.text.offWhite,
        },
      },
      startOnTick: false,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          color: theme.palette.text.offWhite,
        },
      },
    },
    plotOptions: {
      series: {
        animation: false,
      },
      column: {
        stacking: 'normal',
        pointPadding: 0.1,
        groupPadding: 0.1,
        borderRadius: 0,
      },
    },
    tooltip: {
      outside: true,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  const watermarkedOptions = chartWatermark({
    options,
    position: 'top-right',
    marginBottom: 20,
    marginRight: 20,
  });

  return (
    <HighchartsReact
      constructorType='stockChart'
      containerProps={{ style: { height: '100%', width: '100%' } }}
      highcharts={Highcharts}
      options={watermarkedOptions}
      ref={chartComponent}
    />
  );
}

export { VolumePredictionChart };
