import HelpOutlineOutlined from '@mui/icons-material/HelpOutlineOutlined';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { theme } from '../theme/theme';

import { StyledIBMTypography } from './orderTable/util';

function PreTradeAnalyticsComponent({ data, loading, dataError }) {
  const generatePredictedPov = () => {
    if (data.pov === null || data.pov === undefined) {
      return <Typography style={{ display: 'inline' }}>N/A</Typography>;
    }
    let color;

    if (data.pov < 0.5) {
      color = theme.palette.success.main;
    } else if (data.pov < 1) {
      color = theme.palette.warning.main;
    } else {
      color = theme.palette.error.main;
    }

    return (
      <StyledIBMTypography color={color} style={{ display: 'inline' }}>
        {data.pov !== null ? Number(data.pov).toFixed(4) : null}%
      </StyledIBMTypography>
    );
  };

  // TODO: remove unused pov code
  const generateGuideline = () => {
    if (data.pov === null || data.pov === undefined) {
      return 'N/A';
    }

    if (data.pov < 0.5) {
      return 'Minimum impact expected.';
    }
    if (data.pov < 1) {
      return 'Moderate impact expected, consider increasing duration or adding more venues.';
    }

    return 'High impact expected, increasing duration and adding more venues is recommended.';
  };

  const marketVolatilityTooltip =
    'Expected price movement during order based on realized volatility.';

  const marketVolumeComponent = () => {
    let message = '';
    let color = '';
    let tag = '';

    if (data.marketVolume === null || data.marketVolume === undefined) {
      return (
        <Box display='flex' justifyContent='space-between'>
          <Typography>Market Volume:</Typography>
          <Box display='flex' flexDirection='row' justifyContent='center'>
            <Typography style={{ display: 'inline' }}>N/A</Typography>
            <Tooltip
              placement='right'
              title='Market volume data not available.'
            >
              <HelpOutlineOutlined
                style={{
                  fontSize: '1.2rem',
                  marginBottom: '3px',
                  marginLeft: '4px',
                }}
              />
            </Tooltip>
          </Box>
        </Box>
      );
    }

    if (data.marketVolume < 0.5) {
      message =
        'Market volume is much lower than expected, trades may experience higher impact.';
      color = theme.palette.error.main;
      tag = 'Low';
    } else if (data.marketVolume < 0.75) {
      message = 'Market volume is lower than expected.';
      color = theme.palette.warning.main;
      tag = 'Below Average';
    } else if (data.marketVolume < 1) {
      message = 'Market volume is as expected.';
      color = theme.palette.text.primary;
      tag = 'Normal';
    } else if (data.marketVolume < 1.5) {
      message = 'Market volume is elevated, favorable market conditions.';
      color = theme.palette.success.main;
      tag = 'Above Average';
    } else {
      message =
        'Market volume is very elevated, favorable market conditions to trade quickly, ' +
        'but be mindful of volatility.';
      color = theme.palette.success.main;
      tag = 'High';
    }

    return (
      <Box display='flex' justifyContent='space-between'>
        <Typography>Market Volume:</Typography>
        <Box display='flex' flexDirection='row' justifyContent='center'>
          <StyledIBMTypography color={color} style={{ display: 'inline' }}>
            {tag}{' '}
            {data.marketVolume !== null
              ? Number(data.marketVolume).toFixed(2)
              : null}
            x
          </StyledIBMTypography>
          <Tooltip placement='right' title={message}>
            <HelpOutlineOutlined
              style={{
                fontSize: '1.2rem',
                marginBottom: '3px',
                marginLeft: '4px',
              }}
            />
          </Tooltip>
        </Box>
      </Box>
    );
  };

  const isDataAvailable = Object.keys(data).length > 0;

  const renderContent = () => {
    if (loading) {
      return (
        <Box
          alignItems='center'
          display='flex'
          flexDirection='column'
          minHeight='110px'
        >
          <Typography my={1} textAlign='left' variant='h6' width='100%'>
            Pre-Trade Analytics
          </Typography>
          <Box
            alignItems='center'
            display='flex'
            flexGrow={1}
            height='100%'
            justifyContent='center'
            sx={{ marginTop: '12px' }}
          >
            <CircularProgress />
          </Box>
        </Box>
      );
    }

    const dataUnavailableMessage = () => {
      if (dataError) {
        return 'Market data unavailable for selected exchange and pair.';
      }

      return 'Please select account(s), trading pair and quantity.';
    };

    const preTradeErrorMessage =
      'Selected exchange and trading pair not supported.';

    if (!isDataAvailable) {
      return (
        <Stack
          flexDirection='column'
          justifyContent='space-between'
          minHeight='110px'
          spacing={0.5}
        >
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h6'>Pre-Trade Analytics</Typography>
            <Box
              sx={{
                marginTop: '12px',
                visibility: dataError ? 'visible' : 'hidden',
              }}
            >
              <Typography
                color={theme.palette.error.main}
                sx={{ display: 'inline' }}
              >
                Unavailable
              </Typography>
              <Tooltip
                placement='right'
                sx={{ marginLeft: '5px' }}
                title={preTradeErrorMessage}
              >
                <HelpOutlineOutlined
                  style={{
                    fontSize: '1.2rem',
                    color: theme.palette.error.main,
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
          >
            <Typography>Participation Rate:</Typography>
            <Box alignItems='center' display='flex' flexDirection='row'>
              <Typography style={{ display: 'inline' }}>N/A</Typography>
              <Tooltip placement='right' title={dataUnavailableMessage()}>
                <HelpOutlineOutlined
                  style={{
                    fontSize: '1.2rem',
                    marginBottom: '3px',
                    marginLeft: '4px',
                  }}
                />
              </Tooltip>
            </Box>
          </Box>

          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
          >
            <Typography>Market Volatility:</Typography>
            <Box alignItems='center' display='flex' flexDirection='row'>
              <Typography style={{ display: 'inline' }}>N/A</Typography>
              <Tooltip placement='right' title={dataUnavailableMessage()}>
                <HelpOutlineOutlined
                  style={{
                    fontSize: '1.2rem',
                    marginBottom: '3px',
                    marginLeft: '4px',
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
          >
            <Typography>Market Volume:</Typography>
            <Box display='flex' flexDirection='row' justifyContent='center'>
              <Typography style={{ display: 'inline' }}>N/A</Typography>
              <Tooltip placement='right' title={dataUnavailableMessage()}>
                <HelpOutlineOutlined
                  style={{
                    fontSize: '1.2rem',
                    marginBottom: '3px',
                    marginLeft: '4px',
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
        </Stack>
      );
    }

    return (
      <Stack
        flexDirection='column'
        justifyContent='space-between'
        minHeight='110px'
        spacing={0.5}
      >
        <Typography variant='h6'>Pre-Trade Analytics</Typography>
        <Box
          alignItems='center'
          display='flex'
          justifyContent='space-between'
          sx={{ marginTop: '12px' }}
        >
          <Typography>Participation Rate:</Typography>
          <Box display='flex' flexDirection='row' justifyContent='center'>
            {generatePredictedPov()}
            <Tooltip placement='right' title={generateGuideline()}>
              <HelpOutlineOutlined
                style={{
                  fontSize: '1.2rem',
                  marginBottom: '3px',
                  marginLeft: '4px',
                }}
              />
            </Tooltip>
          </Box>
        </Box>

        <Box alignItems='center' display='flex' justifyContent='space-between'>
          <Typography>Order Volatility:</Typography>
          <Box display='flex' flexDirection='row' justifyContent='center'>
            <StyledIBMTypography style={{ display: 'inline' }}>
              &plusmn;
              {data.volatility !== null && data.volatility !== undefined
                ? Number(data.volatility).toFixed(4)
                : 'N/A'}
              %
            </StyledIBMTypography>
            <Tooltip placement='right' title={marketVolatilityTooltip}>
              <HelpOutlineOutlined
                style={{
                  fontSize: '1.2rem',
                  marginBottom: '3px',
                  marginLeft: '4px',
                }}
              />
            </Tooltip>
          </Box>
        </Box>
        {marketVolumeComponent()}
      </Stack>
    );
  };

  return renderContent();
}

export default PreTradeAnalyticsComponent;
