const defaultStrategySettings = {
  duration: 300,
  passiveness: 0.02,
  discretion: 0.06,
  alphaTilt: 0,
  otcPercentage: 0,
  povTarget: '',
  passive_only: false,
  reduce_only: false,
  dicy: false,
  ool_pause: false,
  activeLimit: false,
  strict_duration: false,
  orderSlices: 1,
  isReverseLimitPrice: false,
};

export default defaultStrategySettings;
