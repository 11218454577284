import { useTheme } from '@emotion/react';
import moment, { now } from 'moment';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import React, { useContext, useEffect, useState } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import {
  fetchAccountData,
  fetchOrderEntryFormData,
  getUserFavouritePairs,
} from '../../apiServices';
import { ErrorContext } from '../../shared/context/ErrorProvider';
import AccountAssetsTable from './AccountAssetTable';
import AccountSummaryTable from './AccountSummaryTable';
import AssetAreaChart from './AssetAreaChart';
import AssetDoughnut from './AssetDoughnut';
import CloseBalanceButton from './CloseBalanceButton';
import AccountRebalance from './AccountRebalance';
import { useUserMetadata } from '../../shared/context/UserMetadataProvider';

Chart.register(
  ArcElement,
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

Chart.defaults.color = 'rgb(230, 230, 230)';
Chart.defaults.font.family = 'IBM PLEX MONO';

const assetsColumns = [
  { id: 'symbol', label: 'Name', minWidth: 100, align: 'left' },
  { id: 'wallet_type', label: 'Type', minWidth: 30, align: 'left' },
  {
    id: 'unrealized_profit',
    label: 'ROI%',
    minWidth: 50,
    align: 'right',
    number: true,
  },
  { id: 'amount', label: 'Amount', minWidth: 70, align: 'right', number: true },
  {
    id: 'notional',
    label: 'Notional',
    minWidth: 70,
    align: 'right',
    number: true,
  },
];

const calculateTotalValue = (balance) => {
  let total = 0;

  if (balance.assets && balance.assets.length > 0) {
    balance.assets.forEach((a) => {
      if (a.asset_type === 'position') {
        total += a.unrealized_profit;
      } else {
        total += Math.abs(a.notional);
      }
    });
  }

  return total;
};

// Creates the net balance account
const intializeNetBalance = (balances) => {
  const netBalance = balances.reduce(
    (next, balanceIteration) => {
      const allBalance = next;
      if (balanceIteration.assets.length > 0) {
        const { assets } = balanceIteration;

        allBalance.timestamp_millis = balanceIteration.timestamp_millis;
        assets.forEach((asset) => {
          if (!allBalance.symbol_list.includes(asset.symbol)) {
            allBalance.symbol_list.push(asset.symbol);
            allBalance.assets.push({ ...asset });
          } else {
            allBalance.assets = allBalance.assets.map((netAsset) => {
              const newNetAsset = netAsset;
              if (newNetAsset.symbol === asset.symbol) {
                newNetAsset.amount += asset.amount;
                newNetAsset.notional += asset.notional;
                newNetAsset.margin_balance += asset.margin_balance;
              }
              return newNetAsset;
            });
          }
        });
      }
      return allBalance;
    },
    {
      account_name: 'All Accounts',
      assets: [],
      symbol_list: [],
      exchange_name: '',
      account_id: 'All Accounts',
      timestamp_millis: now(),
      user_id: -1,
    }
  );

  return netBalance;
};

function AccountBalancePage() {
  const [accountBalances, setAccountBalances] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(['', '', '']);
  const [pastSnapshots, setPastSnapshots] = useState({});
  const [balanceHistory, setBalanceHistory] = useState([]);
  const [assetOrdering, setAssetOrdering] = useState({});
  const [loading, setLoading] = useState(true);
  const { setHasError, setErrorContent } = useContext(ErrorContext);
  const { isDev } = useUserMetadata();

  const showAlert = ({ severity, message }) => {
    setErrorContent({ severity, message });
    setHasError(true);
  };

  const getAccounts = async (initial = true) => {
    const startTime = moment.utc().subtract(30, 'days');
    const endTime = moment.utc();
    try {
      const response = await fetchAccountData({
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
      });
      response.account_balances.sort((a, b) =>
        a.account_name.localeCompare(b.account_name)
      );

      setBalanceHistory(response.balance_history);
      const parsedAccountBalances = [
        intializeNetBalance(response.account_balances),
        ...response.account_balances,
      ];
      const parsedAccountBalancesWithTotal = parsedAccountBalances.map(
        (balance) => {
          const newBalance = balance;
          newBalance.totalValue = calculateTotalValue(balance);
          return newBalance;
        }
      );

      setAccountBalances(parsedAccountBalancesWithTotal);
      setPastSnapshots(response.past_snapshots);

      if (response.account_balances.length > 0 && initial) {
        setSelectedAccount(
          [
            parsedAccountBalances[0].account_id,
            parsedAccountBalances[0].account_name,
          ],
          parsedAccountBalances[0].account_name
        );
      }

      const orderingByAccount = {};

      parsedAccountBalances.forEach((balance) => {
        balance.assets.sort(
          (a, b) => Math.abs(b.notional) - Math.abs(a.notional)
        );
        orderingByAccount[balance.account_name] = balance.assets.map(
          (a) => a.symbol
        );
      });

      setAssetOrdering(orderingByAccount);
      setLoading(false);
    } catch (error) {
      showAlert({ severity: 'error', message: error.message });
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const selectedBalance = selectedAccount[0]
    ? accountBalances.find((a) => a.account_id === selectedAccount[0])
    : {};
  const selectedAssets = selectedAccount[0]
    ? accountBalances.find((a) => a.account_id === selectedAccount[0]).assets
    : [];
  const selectedAccountFull = selectedAccount[0]
    ? accountBalances.find((a) => a.account_id === selectedAccount[0])
    : [];
  const selectedAssetsWithAccountId = accountBalances.reduce((acc, account) => {
    acc[account.account_id] = account.assets.reduce((assetMap, asset) => {
      const updatedAssetMap = { ...assetMap };
      updatedAssetMap[asset.symbol] = updatedAssetMap[asset.symbol] || [];
      updatedAssetMap[asset.symbol].push(asset);
      return updatedAssetMap;
    }, {});
    return { ...acc };
  }, {});

  return (
    <Box sx={{ height: '100vh', overflowY: 'auto' }}>
      <Grid
        container
        alignItems='stretch'
        spacing={1}
        style={{ height: '100%' }}
      >
        <Grid style={{ height: '40%' }} xs={4}>
          <Card>
            {loading ? (
              <Box
                alignItems='center'
                display='flex'
                height='100%'
                justifyContent='center'
              >
                <ScaleLoader color='#FFFFFF' />
              </Box>
            ) : (
              <CardContent>
                <Typography sx={{ marginBottom: 1 }} variant='h6'>
                  Accounts
                </Typography>
                <AccountSummaryTable
                  balances={accountBalances}
                  calculateTotalValue={calculateTotalValue}
                  getAccounts={getAccounts}
                  pastSnapshots={pastSnapshots}
                  selectedAccount={selectedAccount}
                  setSelectedAccount={setSelectedAccount}
                  showAlert={showAlert}
                />
              </CardContent>
            )}
          </Card>
        </Grid>
        <Grid style={{ height: '40%' }} xs={8}>
          <Card>
            <CardContent>
              {loading ? (
                <Box
                  alignItems='center'
                  display='flex'
                  height='100%'
                  justifyContent='center'
                >
                  <ScaleLoader color='#FFFFFF' />
                </Box>
              ) : (
                <Stack direction='row' height='100%' spacing={1}>
                  <Box style={{ height: '100%', width: 'auto' }}>
                    <Typography variant='h6'>Assets</Typography>
                    <Box
                      style={{
                        display: 'flex',
                        marginLeft: '1em',
                        position: 'relative',
                        height: 'calc(100% - 26px)',
                      }}
                    >
                      <AssetDoughnut assets={selectedAssets} />
                    </Box>
                  </Box>
                  <Box style={{ width: '70%', height: '100%' }}>
                    <AccountAssetsTable
                      assets={selectedAssets}
                      columns={assetsColumns}
                    />
                    <CloseBalanceButton
                      selectedAccount={selectedAccount}
                      selectedBalance={selectedBalance}
                      showAlert={showAlert}
                    />
                  </Box>
                </Stack>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid style={{ height: '60%' }} xs={12}>
          <Card>
            <CardContent>
              <Box style={{ height: '100%' }}>
                {loading ? (
                  <Box
                    alignItems='center'
                    display='flex'
                    height='100%'
                    justifyContent='center'
                  >
                    <ScaleLoader color='#FFFFFF' />
                  </Box>
                ) : (
                  <AssetAreaChart
                    assetOrdering={assetOrdering}
                    balanceData={balanceHistory}
                    currentAssets={selectedAssets}
                    selectedAccount={selectedAccount}
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {isDev && (
          <Grid style={{ height: '110%' }} xs={12}>
            <Card>
              <CardContent>
                <Box style={{ height: '100%' }}>
                  <AccountRebalance
                    assetOrdering={assetOrdering}
                    balanceData={selectedAssetsWithAccountId}
                    currentAssets={selectedAssets}
                    selectedAccount={selectedAccount}
                    selectedAccountFull={selectedAccountFull}
                    showAlert={showAlert}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default AccountBalancePage;
