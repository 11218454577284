import React from 'react';
import { Box } from '@mui/material';
import ExplorerTradesTable from './ExplorerTradesTable';
import { AccountsProvider } from '../../shared/context/AccountsProvider';

function ExplorerTradesPageContent() {
  return (
    <Box
      spacing={2}
      sx={{
        height: '850px',
        width: '80%',
        margin: '0 auto',
      }}
    >
      <ExplorerTradesTable />
    </Box>
  );
}

export default function ExplorerTradesPage() {
  return (
    <AccountsProvider>
      <ExplorerTradesPageContent />
    </AccountsProvider>
  );
}
