import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  Stack,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { StyledBenchmarkTableCell, formatDateTime } from '../orderTable/util';

function EmptyMessages() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Typography>No messages</Typography>
    </Box>
  );
}

function LoadingMessages() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <ScaleLoader color='#FFFFFF' />
    </Box>
  );
}

function SidePairHeader({ side, pair, theme }) {
  return (
    <Stack direction='row' spacing={1}>
      <Typography
        sx={{
          color:
            side === 'Buy'
              ? theme.palette.charts.green
              : theme.palette.charts.red,
          fontWeight: '500',
        }}
        variant='body1'
      >
        {side}
      </Typography>
      <Typography variant='body1'>{pair}</Typography>
    </Stack>
  );
}

function TableMessages({ orderMessages }) {
  const theme = useTheme();

  return (
    <TableContainer style={{ height: 'calc(100% - 26px)' }}>
      <Table style={{ tableLayout: 'fixed' }}>
        <TableBody>
          {orderMessages.map((message) => (
            <TableRow key={`${message.order}-${message.id}`}>
              <StyledBenchmarkTableCell
                align='left'
                sx={{
                  color:
                    message.message_type === 'ERROR'
                      ? theme.palette.message.error
                      : theme.palette.message.info,
                }}
              >
                <Stack direction='column'>
                  <Stack direction='row' justifyContent='space-between'>
                    {formatDateTime(message.created_at)}
                    {message.side && message.pair && (
                      <SidePairHeader
                        pair={message.pair}
                        side={message.side}
                        theme={theme}
                      />
                    )}
                  </Stack>
                  {message.message}
                </Stack>
              </StyledBenchmarkTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function OrderMessages({ orderMessages }) {
  let content;
  let styles = {};
  if (orderMessages === null || orderMessages === undefined) {
    content = <LoadingMessages />;
  } else if (orderMessages.length === 0) {
    content = <EmptyMessages />;
  } else {
    content = <TableMessages orderMessages={orderMessages} />;
    styles = { overflow: 'auto' };
  }
  return (
    <Card style={{ height: '100%', p: 0, margin: 0, ...styles }}>
      <CardContent>
        <Typography variant='h4'>Messages</Typography>
        {content}
      </CardContent>
    </Card>
  );
}

function useMultiOrderMessages(multiOrderStats) {
  const [orderMessages, setOrderMessages] = useState(null);

  useEffect(() => {
    if (multiOrderStats?.child_orders) {
      const messages = multiOrderStats.child_orders
        .reduce((acc, childOrder) => {
          const { id, side, pair } = childOrder;

          if (multiOrderStats[id]?.order_messages) {
            const childMessages = multiOrderStats[id].order_messages.map(
              (message) => ({
                ...message,
                side: side === 'buy' ? 'Buy' : 'Sell',
                pair,
              })
            );
            return [...acc, ...childMessages];
          }
          return acc;
        }, [])
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      setOrderMessages(messages);
    }
  }, [multiOrderStats]);

  return orderMessages;
}

export { OrderMessages, useMultiOrderMessages };
