/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-await-in-loop */
import { Card, CardContent, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from '@/shared/context/TitleProvider';
import { fetchOrderDetailData } from '../../../apiServices';
import { ErrorContext } from '../../../shared/context/ErrorProvider';
import { FillOrderTable } from './FillOrderTable';
import {
  OrderBenchmarks,
  FillPieCard,
  OrderSummary,
  OrderMessages,
} from '../../../shared/orderDetail';
import PlacementsTable from '../../../shared/orderDetail/PlacementsTable';

function SimpleOrderDetailsPage() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [benchmarkState, setBenchmarkState] = useState({});
  const [orderSummaryState, setOrderSummaryState] = useState({});
  const [analytics, setAnalytics] = useState({});
  const [tableData, setTableData] = useState({ placements: [], fills: [] });
  const [orderMessages, setOrderMessages] = useState(null);

  const { setHasError, setErrorContent } = useContext(ErrorContext);

  const showAlert = ({ severity, message }) => {
    setErrorContent({ severity, message });
    setHasError(true);
  };

  const { status, pct_filled, side, pair } = orderSummaryState;
  const { setTitle } = useTitle();
  useEffect(() => {
    if (status && pct_filled >= 0) {
      setTitle(
        `${status} (${Math.round(Number(pct_filled))}%) - ${side} ${pair}`
      );
    }
  }, [status, pct_filled, side, pair]);

  const parseOrderData = (messageData) => {
    const {
      benchmark,
      fills,
      order_summary,
      order_analytics,
      placements,
      messages,
    } = messageData;

    if (!order_summary.is_simple) {
      navigate(`/order/${order_summary.id}`);
      return;
    }

    if (Object.keys(messageData).length > 0) {
      if (Object.keys(messageData).length > 0) {
        const { interval_volume, base_asset, pov } = benchmark;
        setBenchmarkState((prevState) => ({
          ...benchmark,
          interval_volume:
            interval_volume !== undefined
              ? interval_volume
              : prevState.interval_volume,
          base_asset:
            base_asset !== undefined ? base_asset : prevState.base_asset,
          pov: pov !== undefined ? pov : prevState.pov,
          points_earned: order_summary?.points_earned,
        }));
      }
      if (Object.keys(order_summary).length > 0) {
        setOrderSummaryState(order_summary);
      }

      if (Object.keys(order_analytics).length > 0) {
        setAnalytics(order_analytics);
      }

      setOrderMessages(messages && messages.length > 0 ? messages : []);
      setTableData({ placements, fills });
    }
  };

  const loadOrderData = async (order_id) => {
    let orderData;
    try {
      orderData = await fetchOrderDetailData(order_id);
    } catch (e) {
      showAlert({
        severity: 'error',
        message: `Failed to fetch order details: ${e.message}`,
      });
      return false;
    }

    parseOrderData(orderData);
    return orderData;
  };

  useEffect(() => {
    let intervalId;

    const loadData = async () => {
      const order_id = uuid;
      const orderData = await loadOrderData(order_id);

      if (orderData.order_ended) {
        clearInterval(intervalId);
      }

      return true;
    };

    let isMounted = true;
    let success = true;

    const pollData = async () => {
      while (isMounted && success) {
        success = await loadData();
        await new Promise((resolve) => {
          setTimeout(resolve, 2000);
        });
      }
    };

    pollData();

    return () => {
      // Stop polling when the component unmounts or success changes
      isMounted = false;
    };
  }, []);

  return (
    <Grid container spacing={1} style={{ height: '100%' }}>
      <Grid style={{ height: '60%' }} xs={4}>
        <Stack
          direction='column'
          spacing={1}
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          <Card style={{ width: '100%', height: '100%', overflow: 'auto' }}>
            <CardContent>
              <OrderSummary
                isSimple
                orderId={uuid}
                OrderSummaryData={orderSummaryState}
                showAlert={showAlert}
              />
            </CardContent>
          </Card>
        </Stack>
      </Grid>
      <Grid style={{ height: '60%' }} xs={8}>
        <Stack direction='column' spacing={1} style={{ height: '100%' }}>
          <Card style={{ width: '100%', height: '40%', padding: 0 }}>
            <CardContent style={{ height: '100%', padding: 0 }}>
              <PlacementsTable
                isSimpleOrderView
                orderId={uuid}
                showAlert={showAlert}
              />
            </CardContent>
          </Card>
          <Card
            style={{
              width: '100%',
              height: '60%',
              padding: 0,
              overflow: 'auto',
            }}
          >
            <CardContent style={{ height: '100%', padding: 0 }}>
              <FillOrderTable fills={tableData.fills} />
            </CardContent>
          </Card>
        </Stack>
      </Grid>

      {/* Bottom row, first third */}
      <Grid style={{ height: '40%', paddingBottom: 0 }} xs={4}>
        <OrderBenchmarks benchmarkData={benchmarkState} key={benchmarkState} />
      </Grid>

      {/* Bottom row, middle third */}
      <Grid style={{ height: '40%', paddingBottom: 0 }} xs={4}>
        <FillPieCard analytics={analytics} />
      </Grid>

      {/* Bottom row, last third */}
      <Grid style={{ height: '40%', paddingBottom: 0 }} xs={4}>
        <OrderMessages orderMessages={orderMessages} />
      </Grid>
    </Grid>
  );
}

export default SimpleOrderDetailsPage;
