/* eslint-disable react/no-danger */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Modal, Typography, Box, Button } from '@mui/material';

function VersionUpdateModal({ open, onClose, version, versionContent, onReadMore }) {
  const theme = useTheme();
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40.00rem',
    bgcolor: '#191919',
    boxShadow:
      '0px 9px 46px 8px rgba(0, 0, 0, 0.12),' +
      '0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    p: 4,
    overflow: 'auto',
    fontFamily: "'IBM Plex Mono', monospace",
    color: '#fff',
  };

  const buttonStyle = {
    fontSize: '0.9rem',
    backgroundColor: 'transparent',
    color: '#FFB56A',
    border: 'none',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: '#ffc88f',
    },
    '&:focus': {
      outline: 'none',
    },
  };

  const replaceVersionText = (content, v) => {
    return content.replaceAll(/{{TAAS_VERSION}}/g, v);
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <div dangerouslySetInnerHTML={{ __html: replaceVersionText(versionContent, version) }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button size='small' sx={buttonStyle} onClick={onClose}>
            Close
          </Button>
          <Button size='small' variant='contained' onClick={onReadMore}>
            <Typography color='inherit' variant='h6'>
              Read More
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default VersionUpdateModal;
