import {
  Divider,
  Stack,
  Table,
  TableBody,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { Loader } from '../../shared/Loader';
import { StyledSummaryTableCell } from '../../shared/orderTable/util';

function TransactionSummaryRender({ TransactionSummaryData }) {
  if (
    TransactionSummaryData === undefined ||
    Object.keys(TransactionSummaryData).length === 0
  ) {
    return <Loader />;
  }

  return (
    <Stack direction='column'>
      <Typography sx={{ marginBottom: '10px' }} variant='h6'>
        Summary
      </Typography>
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <StyledSummaryTableCell>Number of orders</StyledSummaryTableCell>
            <StyledSummaryTableCell>
              {TransactionSummaryData.num_orders}
            </StyledSummaryTableCell>
          </TableRow>
          <TableRow>
            <StyledSummaryTableCell>Number of pairs</StyledSummaryTableCell>
            <StyledSummaryTableCell>
              {TransactionSummaryData.num_pairs}
            </StyledSummaryTableCell>
          </TableRow>
          <TableRow>
            <StyledSummaryTableCell>Value of orders</StyledSummaryTableCell>
            <StyledSummaryTableCell>
              {TransactionSummaryData.orders_value}
            </StyledSummaryTableCell>
          </TableRow>
          <TableRow>
            <StyledSummaryTableCell>Average duration</StyledSummaryTableCell>
            <StyledSummaryTableCell>
              {TransactionSummaryData.avg_duration.toFixed(0)}s
            </StyledSummaryTableCell>
          </TableRow>
          <TableRow>
            <StyledSummaryTableCell>Participation Rate</StyledSummaryTableCell>
            <StyledSummaryTableCell>
              {TransactionSummaryData.pov}%
            </StyledSummaryTableCell>
          </TableRow>
          <TableRow>
            <StyledSummaryTableCell>Interval Return</StyledSummaryTableCell>
            <StyledSummaryTableCell>
              {TransactionSummaryData.interval_returns.toFixed(2)} bps
            </StyledSummaryTableCell>
          </TableRow>
          <TableRow>
            <StyledSummaryTableCell>Spread</StyledSummaryTableCell>
            <StyledSummaryTableCell>N/A bps</StyledSummaryTableCell>
          </TableRow>
          <TableRow>
            <StyledSummaryTableCell>Exchange Fee</StyledSummaryTableCell>
            <StyledSummaryTableCell>
              {TransactionSummaryData.exch_fee}
            </StyledSummaryTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
}

export { TransactionSummaryRender };
