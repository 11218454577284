/* eslint-disable no-await-in-loop */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, CardContent, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchMultiOrderBenchmarkData, fetchMultiOrderDetailData } from '../../../apiServices';
import { Loader } from '../../../shared/Loader';
import { ErrorContext } from '../../../shared/context/ErrorProvider';
import { ChildOrderSummary, MultiOrderActions, MultiOrderBenchmark, MultiSummaryRender } from './MultiSummary';
import { BuySellSpreadChart } from './charts/BuySellSpreadChart';
import { PriceDifferenceChart } from './charts/PriceDifferenceChart';
import { PriceSpreadChart } from './charts/PriceSpreadChart';


function MultiOrderDetailsPage() {
  const { uuid } = useParams();
  const [orderBenchmark, setOrderBenchmark] = useState({})
  const [orderSummaryState, setOrderSummaryState] = useState({})
  const [childOrders, setChildOrders] = useState([])
  const [multiOrderStats, setMultiOrderStats] = useState({})
  const [isLoading, setIsLoading] = useState(false);

  const {setHasError, setErrorContent} = useContext(ErrorContext);

  const showAlert = ({severity, message}) => {
    setErrorContent({severity, message});
    setHasError(true);
  }

  const loadOrderData = async (order_id) => {
    let orderData;
    try {
      orderData = await fetchMultiOrderDetailData(order_id);
    } catch (e) {
      showAlert({severity: 'error', message: `Failed to fetch order details: ${e.message}`});
      return null;
    }

    setOrderSummaryState(orderData.order)
    setChildOrders(orderData.child_orders)
    setMultiOrderStats(orderData)
    return orderData;
  }

  useEffect(() => {
    let intervalId;
    const order_id = uuid;

    setIsLoading(true);
    const loadData = async () => {
      let benchmarkData;
      try {
        benchmarkData = await fetchMultiOrderBenchmarkData(order_id);
      } catch (e) {
        showAlert({severity: 'error', message: `Failed to fetch order details: ${e.message}`});
        return false;
      }

      setOrderBenchmark(benchmarkData)

      const multiData = await loadOrderData(order_id);
      const isTerminated = multiData.child_orders.every(
        child => child.status === 'COMPLETE' || child.status === 'CANCELED');

      if (!multiData || isTerminated) {
        clearInterval(intervalId);
      }

      setIsLoading(false);

      return true;
    }

    let isMounted = true;
    let success = true;

    const pollData = async () => {
      while (isMounted && success) {
        success = await loadData();
        await new Promise(resolve => {setTimeout(resolve, 2000)});
      }
    };

    pollData();

    return () => {
      // Stop polling when the component unmounts or success changes
      isMounted = false;
    };
  }, [])


  let timeStart
  let timeEnd
  let origTimeEnd
  if (childOrders[0]){
    timeStart = childOrders[0].time_start
    timeEnd = childOrders[0].time_end
    origTimeEnd = childOrders[0].orig_time_end
  }

  if (isLoading) {
    return (
      <Box height='100%'>
        <Card>
          <CardContent>
            <Loader />
          </CardContent>
        </Card>
      </Box>
    )
  }

  const renderLayout = (isSpread) => {
    if (isSpread) {
      return (
        <Stack direction='row' height='100%' spacing={1} width='100%'>
          <Stack style={{height: '100%', width: '30%'}} xs={4}>
            <Stack direction='column' spacing={1} style={{height: '100%'}} >
              <Card style={{height: '45%'}}>
                <CardContent style={{height: '100%'}}>
                  <MultiSummaryRender
                    accountNames={multiOrderStats.order_venues}
                    orderSummaryData={orderSummaryState}
                  />
                </CardContent>
              </Card>
              <Card style={{height: '5%'}}>
                <CardContent style={{height: '100%', padding: 0}}>
                  <MultiOrderActions
                    childOrders={childOrders}
                    loadOrderData={loadOrderData}
                    orderSummaryData={orderSummaryState}
                    showAlert={showAlert}
                  />
                </CardContent>
              </Card>
              <Card style={{height: '20%', padding: 0}}>
                <CardContent style={{height: '100%', overflow: 'auto', padding: 8}}>
                  <ChildOrderSummary childOrders={childOrders} includeHeaders={false}/>
                </CardContent>
              </Card>
              <Card style={{height: '30%', padding: 8}}>
                <CardContent style={{height: '100%', overflow: 'auto', padding: 8}}>
                  <MultiOrderBenchmark benchmarkData={orderBenchmark}/>
                </CardContent>
              </Card>
            </Stack>
          </Stack>
          <Stack style={{height: '100%', width: '70%'}} xs={8}>
            <Stack direction='column' spacing={1} style={{height: '100%'}}>
              <Card style={{height: '50%', padding: 0}}>
                <CardContent style={{height: '100%', padding: 0}}>
                  <PriceDifferenceChart
                    data={multiOrderStats}
                    origTimeEnd={origTimeEnd}
                    timeStart={timeStart}
                  />
                </CardContent>
              </Card>
              <Card style={{height: '50%', padding: 0}}>
                <CardContent style={{height: '100%', padding: 0}}>
                  <PriceSpreadChart
                    data={multiOrderStats}
                    origTimeEnd={origTimeEnd}
                    timeEnd={timeEnd}
                    timeStart={timeStart}
                  />
                  <BuySellSpreadChart
                    data={multiOrderStats}
                    origTimeEnd={origTimeEnd}
                    timeEnd={timeEnd}
                    timeStart={timeStart}
                  />
                </CardContent>
              </Card>
            </Stack>
          </Stack>
        </Stack>
      )
    }
    return (
      <Stack direction='row' height='100%' spacing={1} width='100%'>
        <Stack style={{height: '100%', width: '30%'}} xs={4}>
          <Stack direction='column' spacing={1} style={{height: '100%'}} >
            <Card style={{height: '45%'}}>
              <CardContent style={{height: '100%'}}>
                <MultiSummaryRender
                  accountNames={multiOrderStats.order_venues}
                  orderSummaryData={orderSummaryState}
                />
              </CardContent>
            </Card>
            <Card style={{height: '5%'}}>
              <CardContent style={{height: '100%', padding: 0}}>
                <MultiOrderActions
                  childOrders={childOrders}
                  loadOrderData={loadOrderData}
                  orderSummaryData={orderSummaryState}
                  showAlert={showAlert}
                />
              </CardContent>
            </Card>
            <Card style={{height: '50%', padding: 8}}>
              <CardContent style={{height: '100%', overflow: 'auto', padding: 8}}>
                <MultiOrderBenchmark benchmarkData={orderBenchmark}/>
              </CardContent>
            </Card>
          </Stack>
        </Stack>
        <Stack style={{height: '100%', width: '70%'}} xs={8}>
          <Stack direction='column' spacing={1} style={{height: '100%'}}>
            <Card style={{height: '50%', padding: 0}}>
              <CardContent style={{height: '100%', padding: 0}}>
                <PriceDifferenceChart
                  data={multiOrderStats}
                  origTimeEnd={origTimeEnd}
                  timeStart={timeStart}
                />
              </CardContent>
            </Card>
            <Card style={{height: '50%', padding: 0}}>
              <CardContent style={{height: '100%', overflow: 'auto', padding: 8}}>
                <ChildOrderSummary includeHeaders childOrders={childOrders} />
              </CardContent>
            </Card>
          </Stack>
        </Stack>
      </Stack>
    )

  }


  return renderLayout(multiOrderStats.is_spread)
}

export default MultiOrderDetailsPage;

