import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useState } from 'react';

import { Loader } from '../../shared/Loader';
import { PricePredictionChart } from './charts/PricePredictionChart';
import { VolumePredictionChart } from './charts/VolumePredictionChart';
import { useMarketDataContext } from './orderEntry/MarketDataContext';

function PredictionCharts() {
  const {
    priceChartData,
    volumeChartData,
    futurePriceVolatility,
    orderOverlayData,
    noData,
    now,
    reloading,
    setReloading,
    selectedPastHours,
    setSelectedPastHours,
  } = useMarketDataContext();

  const [showOrderOverlay, setShowOrderOverlay] = useState(true);

  const emptyForecastedVolume =
    volumeChartData && volumeChartData.forecasted_volume.length === 0;

  const groupSize = () => {
    if (selectedPastHours === 2.4) {
      return 1;
    }
    if (selectedPastHours === 12) {
      return 5;
    }
    if (selectedPastHours === 24) {
      return 10;
    }
    if (selectedPastHours === 168) {
      return 60;
    }

    return 30;
  };

  const timeWindowChoices = [
    [2.4, '1m'],
    [12, '5m'],
    [24, '10m'],
    [168, '1h'],
  ];

  const timeWindowChoiceOnClick = (hours) => {
    if (hours === selectedPastHours) {
      return;
    }
    setReloading(true);
    setSelectedPastHours(hours);
  };

  const dataDisplay = () => {
    return (
      <Box height='100%'>
        <Box style={{ height: '65%', width: '100%', position: 'relative' }}>
          <PricePredictionChart
            emptyForecastedVolume={emptyForecastedVolume}
            futurePriceVolatility={futurePriceVolatility}
            groupSize={groupSize()}
            now={now}
            orderOverlayData={showOrderOverlay ? orderOverlayData : null}
            priceChartData={priceChartData}
          />
        </Box>
        <Box style={{ height: '35%', width: '100%', position: 'relative' }}>
          <VolumePredictionChart
            emptyForecastedVolume={emptyForecastedVolume}
            groupSize={groupSize()}
            now={now}
            volumeChartData={volumeChartData}
          />
        </Box>
      </Box>
    );
  };

  if (reloading) {
    return (
      <Box style={{ height: '100%' }}>
        <Loader />
      </Box>
    );
  }

  if (noData) {
    return (
      <Box
        alignItems='center'
        display='flex'
        height='100%'
        justifyContent='center'
      >
        <Typography color='grey.main' variant='h6'>
          Data Unavailable
        </Typography>
      </Box>
    );
  }

  return (
    <Box style={{ height: '100%' }}>
      <Box display='flex' flexDirection='column' height='100%' width='100%'>
        <Box
          display='flex'
          flexDirection='row'
          gap={1}
          justifyContent='right'
          width='100%'
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={showOrderOverlay}
                color='info'
                sx={{ padding: 0, paddingRight: '5px' }}
                onChange={(event) => setShowOrderOverlay(event.target.checked)}
              />
            }
            label={
              <Typography color='grey.main' variant='caption'>
                Overlay Orders
              </Typography>
            }
          />
          {timeWindowChoices.map(([hours, displayText]) => (
            <Button
              color='info'
              key={hours}
              size='small'
              sx={{
                minWidth: '2.5rem',
                border:
                  hours === selectedPastHours
                    ? '1px solid'
                    : '1px solid transparent',
              }}
              variant='text'
              onClick={() => timeWindowChoiceOnClick(hours)}
            >
              <Typography color='grey.main' variant='caption'>
                {displayText}
              </Typography>
            </Button>
          ))}
        </Box>
        {dataDisplay()}
      </Box>
    </Box>
  );
}

export default PredictionCharts;
