import React from 'react';
import { Box } from '@mui/material';
import ExplorerProofsTable from './ExplorerProofsTable';
import { AccountsProvider } from '../../shared/context/AccountsProvider';

function ExplorerProofsPageContent({ children }) {
  return (
    <Box
      spacing={2}
      sx={{
        height: '850px',
        width: '80%',
        margin: '0 auto',
      }}
    >
      <ExplorerProofsTable />
    </Box>
  );
}

export default function ExplorerProofsPage() {
  return (
    <AccountsProvider>
      <ExplorerProofsPageContent />
    </AccountsProvider>
  );
}
